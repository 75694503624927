import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ExcelJS from "exceljs";

import { Loader } from "../../components/Loader";
import toast from "react-hot-toast";

import {
  useGetAcceptedUserDataQuery,
  useGetPlacesVisitedMutation,
  useGetFileForBackendMutation,
  useGetSellerPlacesVisitedMutation,
} from "../../store/api";

import "./style.scss";
const CSV = () => {
  const location = useLocation();
  const { user } = location?.state;

  const bidRef = user?.bidRef;

  console.log("User: ", user?.dataItem?.specifiedValues);
  const hasTherapyKeyword = user?.bidRef?.dataItem?.selectedValues.some(value =>
    value.toLowerCase().includes('therapies')
  );

  const therapyDetails = hasTherapyKeyword ? user?.sellerRef?.clinical.therapy : null;



  const [loadingMessage, setLoadingMessage] = useState(null);
  const selRef = user?.sellerRef?.id;
  const [
    getFilesBackend,
    {
      data: dataFilesBackend,
      isSuccess: isSuccessFilesBackend,
      isError: isErrorFilesBackend,
      isLoading: isLoadingFilesBackend,
      error: errorFilesBackend,
    },
  ] = useGetFileForBackendMutation();
  const [
    getPlaces,
    {
      data: dataPlaces,
      isSuccess: isSuccessPlaces,
      isError: isErrorPlaces,
      isLoading: isLoadingPlaces,
      error: errorPlaces,
    },
  ] = useGetPlacesVisitedMutation();

  const [
    getSellerPlacesVisited,
    {
      data: dataSellerPlacesVisited,
      isSuccess: isSuccessSellerPlacesVisited,
      isError: isErrorSellerPlacesVisited,
      isLoading: isLoadingSellerPlacesVisited,
      error: errorSellerPlacesVisited,
    },
  ] = useGetSellerPlacesVisitedMutation();
  //Need SellerRef and AssignBidId


  console.log("Data Here: ", dataSellerPlacesVisited);

  const { data, isSuccess, isError, isLoading, error } =
    useGetAcceptedUserDataQuery([bidRef.id, selRef]);

  console.log("Data Here: ", data);
  // const handlePlaces = useCallback(async () => {
  //   setLoadingMessage("Getting Places data ...");
  //   const dtata = {
  //     assignedBidId: user?.id,
  //     bidOwnerRef: user?.bidOwnerRef,
  //   };
  //   await getFilesBackend(dtata);
  //   setLoadingMessage("It might take some time! be patient ...");
  //   const vts = {
  //     startDate: user?.placesVisited?.startDate,
  //     endDate: user?.placesVisited?.endDate,
  //   };
  //   setTimeout(() => {
  //     setLoadingMessage("Almost there! 😊");
  //     getPlaces(vts);
  //   }, 5000);
  // }, []);

  // console.log("user: ", user?.sellerRef?.id);

  const handleSellerPlacesVisited = useCallback(async () => {
    setLoadingMessage("Getting Places data ...");
    const data = {
      sellerRef: user?.sellerRef?.id,
      assignedBidId: user?.id,
    };

    console.log("Sending Data: ", data);
    const response = await getSellerPlacesVisited(data);
    console.log("Response: ", response);
  }, []);

  const calculateDaysOnTherapy = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = endDate === true ? new Date() : new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return diffDays;
  };
  useEffect(() => {
    if (isSuccessPlaces) {
      // console.log("OUTPUT--->",dataPlaces)
      setLoadingMessage(null);
    } else if (isErrorPlaces) {
      console.log("ERRRRRRORR-->", errorPlaces);
      setLoadingMessage(null);
    }
  }, [isSuccessPlaces, isErrorPlaces, errorPlaces]);
  useEffect(() => {
    if (isSuccess) {
      // console.log('data>>', data);
    } else if (isError) {
      console.log("Error receiving data", error);
    }
  }, [isSuccess, isError]);

  const handleExportToExcel = (type) => () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");

    // Add header for CSV data
    sheet.addRow(["Data Measure", "Date", "Total Value"]);

    // Add data rows for both steps and flights climbed data
    data.forEach((item) => {
      if (item.dataMeasure === "Applications_Health_Apple_Steps" || item.dataMeasure === "Applications_Health_Apple_FlightsClimbed") {
        Object.keys(item.daysData).forEach((date) => {
          sheet.addRow([
            item.dataMeasure === "Applications_Health_Apple_Steps" ? "Steps" : "Flights Climbed",
            date,
            Math.ceil(item.daysData[date])
          ]);
        });
      }
    });

    // Create a buffer and download the file as CSV
    workbook.csv.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "text/csv;charset=utf-8;"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${data[0]?.sellerUsername}_${type}_Data.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };
  const handleExportPlacesToExcel = (type) => () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");

    // Add header for CSV data
    sheet.addRow(["Date", "Places visited"]);

    // {dataPlaces?.data?.placesVisitedArray?.map(
    //   (day, dayIndex) => (
    //     <React.Fragment key={dayIndex}>
    //       <tr>
    //         <td>{dayIndex + 1}.</td>
    //         <td>{day?.date}</td>
    //         <td>{day?.count}</td>
    //       </tr>
    //     </React.Fragment>
    //   )
    // )}
    // Add data rows for both steps and flights climbed data
    dataPlaces?.data?.placesVisitedArray?.map((date) => {
      sheet.addRow([
        date.date,
        date.count,
      ]);
    });


    // Create a buffer and download the file as CSV
    workbook.csv.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "text/csv;charset=utf-8;"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${user?.sellerRef?.username}_${type}_Data.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  const handleExportTherapyToExcel = (type) => () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");

    // Add header for CSV data
    sheet.addRow(["Therapy name", "Start date", "End date"]);

    // Add data rows for both steps and flights climbed data

    therapyDetails.map((therapy) => {
      sheet.addRow([
        therapy?.displayName,
        therapy?.startDate ? new Date(therapy.startDate).toLocaleDateString() : '-',
        therapy?.endDate ? new Date(therapy.endDate).toLocaleDateString() : '-',
      ]);
    });




    // Create a buffer and download the file as CSV
    workbook.csv.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "text/csv;charset=utf-8;"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${user?.sellerRef?.username}_Therapy_Data.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  };

  // const handleExportToExcel = (type) => () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("Data");

  //   // Common function to add rows for both types of data
  //   const addRows = (rowData) => {
  //     rowData.forEach((item) => {
  //       sheet.addRow(item);
  //     });
  //   };

  //   if (type === "date") {
  //     // Add header for date data
  //     sheet.addRow(["Data Measure", "Start Date", "End Date", "Value"]);

  //     // Add data rows for date data with an empty row between each set of data
  //     data.forEach((item, index) => {
  //       sheet.addRow([
  //         item.dataMeasure,
  //         new Date(item.dateData.startDate).toLocaleTimeString([], {
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           second: "2-digit",
  //         }),
  //         new Date(item.dateData.endDate).toLocaleTimeString([], {
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           second: "2-digit",
  //         }),
  //         item.dateData.totalValue,
  //       ]);

  //       // Add an empty row if it's not the last item
  //       if (index < data.length - 1) {
  //         sheet.addRow([]);
  //       }
  //     });
  //   } else if (type === "days") {
  //     // Add header for days data
  //     sheet.addRow([
  //       "Data Measure",
  //       "Total Value",
  //       "Date",
  //       "Value",
  //       "Start Time",
  //       "End Time",
  //     ]);

  //     // Add data rows for days data with an empty row between each set of data
  //     data.forEach((item) => {
  //       item.dayData.forEach((day, dayIndex) => {
  //         day.records.forEach((record, recordIndex) => {
  //           // Add Total Value only once against one date in the center
  //           const totalValueCell = recordIndex === 0 ? day.totalValue : "";
  //           sheet.addRow([
  //             item.dataMeasure,
  //             totalValueCell,
  //             day.date,
  //             record.value,
  //             new Date(record.startDate).toLocaleTimeString([], {
  //               hour: "2-digit",
  //               minute: "2-digit",
  //               second: "2-digit",
  //             }),
  //             new Date(record.endDate).toLocaleTimeString([], {
  //               hour: "2-digit",
  //               minute: "2-digit",
  //               second: "2-digit",
  //             }),
  //           ]);
  //         });
  //         // Add an empty row after each day's data except for the last day
  //         if (dayIndex < item.dayData.length - 1) {
  //           sheet.addRow([]);
  //         }
  //       });
  //     });
  //   }

  //   // Create a buffer and download the file
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${data[0]?.sellerUsername}_${type}_Data.xlsx`;
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  //   });
  // };
  const matchedData = useCallback(() => {
    let specifiedValue;
    let specifiedTherapy;

    let matchedStartDate;
    let therapyStartDate;

    // Step 1: Extract the value from user?.bidRef?.dataItem?.specifiedValues
    const specifiedItem = user?.bidRef?.dataItem?.specifiedValues?.find(i => i?.timeToMax?.count);
    if (specifiedItem) {
      specifiedValue = specifiedItem?.value;
      specifiedTherapy=specifiedItem?.displayName;
    }

    // Step 2: Find matching diagnosis in user?.sellerRef?.clinical?.diagnosis
    const matchedDiagnosis = user?.sellerRef?.clinical?.diagnosis?.find(i =>
      user?.bidRef?.diagnosis?.includes(i?.bidValue)
    );
    if (matchedDiagnosis) {
      matchedStartDate = matchedDiagnosis?.startDate;
    }

    // Step 3: Compare the saved specifiedValue with user?.sellerRef?.clinical?.therapy
    if (specifiedValue) {
      const matchedTherapy = user?.sellerRef?.clinical?.therapy?.find(i => i?.status === specifiedValue);
      if (matchedTherapy) {
        therapyStartDate = matchedTherapy?.startDate;
      }
    }


    return specifiedItem?(<div>
      <p className='timeToTherapyStyle font-italic'>Time to therapy from Diagnosis to {specifiedTherapy} is {calculateDaysOnTherapy(matchedStartDate, therapyStartDate)} days.</p>
    </div>):null;
  }, [user]);

  return (
    <>
      <div className="data">
        {/* <div className="csv-container">
          <h2>Date Wise Data</h2>
          {isLoading ? (
            <Loader style={{ padding: '50px' }} />
          ) : data && data.length > 0 ? (
            <>
              <table className="csv-table">
                <thead>
                  <tr>
                    <th>Data Measure</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.dataMeasure}</td>
                      <td>{item?.dateData?.startDate}</td>
                      <td>{item?.dateData?.endDate}</td>
                      <td>{item?.dateData?.totalValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="button">
                <button
                  className="csv-button"
                  onClick={handleExportToExcel('date')}
                >
                  Download Data Date Wise
                </button>
              </div>
            </>
          ) : (
            <p>No data found against this seller</p>
          )}
        </div> */}

        <div className="receipt">
          <h2>Bid Information</h2>
          <div style={{ padding: 13 }}>
            <span style={{ fontWeight: "bold" }}>Sharer name: </span>
            {user?.sellerRef?.username}
            <br />
            {/* <span style={{ fontWeight: "bold" }}>Information: </span> */}
            {user?.information}

            {hasTherapyKeyword && therapyDetails && (
              <div>
                <span style={{ textAlign: 'center', fontWeight: 'bold' }}>Therapy Information</span>
                {therapyDetails.map((therapy, index) => (
                  <div key={index} className='mt-2'>
                    <span style={{ fontWeight: "bold" }}>Therapy Name: </span>
                    {therapy.displayName}
                    <br />
                    <span style={{ fontWeight: "bold" }}>Start Date: </span>
                    {new Date(therapy.startDate).toLocaleDateString()}
                    <br />
                    <span style={{ fontWeight: "bold" }}>End Date: </span>
                    {therapy.endDate ===true? 'Ongoing':new Date(therapy.endDate).toLocaleDateString() }
                    <br />
                    <span style={{ fontWeight: "bold" }}>Time on therapy: </span>
                    {calculateDaysOnTherapy(therapy.startDate, therapy.endDate)} days
                    <br />
                  </div>
                ))}
                <div className="button">
                  <button
                    className="csv-button"
                    onClick={handleExportTherapyToExcel("days")}
                  >
                    Download (Therapies)
                  </button>
                </div>
              
{matchedData()}
              </div>
            )}



          </div>
          {/* <table className="bid-table">
            <thead>
              <tr>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data[0]?.assignBid?.dataItem?.specifiedValues?.map(
                  (item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.displayName}</td>
                      </tr>
                    );
                  }
                )}
              {/* <tr>
                        <td>Mavenclad (Current - 1st Started)</td>
                        <td> Apple HealthKit: Steps</td>
                        <td>Questionnaires : Quality SF-36</td>
                        <td>Analysis Genetic HLA-DRB1*15:01</td>
                      </tr> */}
          {/* </tbody> */}
          {/* </table> */}
        </div>
        {data && data[0] ? (
          <>
            {data?.map((item, index) => (
              <div className="csv-container" key={index}>
                <h2>
                  {item?.dataMeasure === "Applications_Health_Apple_Steps"
                    ? "Steps"
                    : item?.dataMeasure ===
                      "Applications_Health_Apple_FlightsClimbed"
                      ? "Flights Climbed"
                      : item?.dataMeasure}
                </h2>
                {isLoading ? (
                  <Loader style={{ padding: "50px" }} />
                ) : data && data.length > 0 ? (
                  <table className="csv-table">
                    <thead>
                      <tr>
                        <th>Data Measure</th>
                        <th>Date</th>
                        <th>Total Value</th>
                        {/* <th>Hourly Data</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.dataMeasure ===
                        "Applications_Health_Apple_Steps" &&
                        Object.keys(item.daysData).map((date) => (
                          <tr key={date}>
                            <td>Steps</td>
                            <td>{date}</td>
                            <td>{Math.ceil(item.daysData[date])}</td>
                          </tr>
                        ))}
                      {item?.dataMeasure ===
                        "Applications_Health_Apple_FlightsClimbed" &&
                        Object.keys(item.daysData).map((date) => (
                          <tr key={date}>
                            <td>Flights Climbed</td>
                            <td>{date}</td>
                            <td>{Math.ceil(item.daysData[date])}</td>
                          </tr>
                        ))}

                    </tbody>
                  </table>
                ) : (
                  <p>No data found against this seller</p>
                )}
              </div>
            ))}

            <div className="button">
              <button
                className="csv-button"
                onClick={handleExportToExcel("days")}
              >
                Download
              </button>
            </div>
          </>
        ) : null}

        {user?.noOfPlacesVisited?.startDate && !dataSellerPlacesVisited ? (
          <div className="button">
            {/* {isLoadingPlaces || isLoadingFilesBackend? <Loader style={{ padding: '50px' }} />: */}
            <button
              className="csv-button"
              onClick={
                isLoadingFilesBackend || isLoadingPlaces || loadingMessage
                  ? null
                  : () => {
                    handleSellerPlacesVisited();
                  }
              }
            >
              {isLoadingFilesBackend || isLoadingPlaces || loadingMessage
                ? loadingMessage
                : "Check Places Visited"}
            </button>
          </div>
        ) : null}
        {dataSellerPlacesVisited?.placesVisitedArray || dataSellerPlacesVisited?.placesCountArray ? (
          <div className="csv-container">
            <h2>Places Visited</h2>
            <>
              <table className="csv-table">
                <thead>
                  <tr>
                    <th>Sr #</th>
                    <th>Date</th>
                    <th>Places Visited</th>
                    {dataSellerPlacesVisited?.placesVisitedArray?.[0]?.places && (
                      <th>List of Names of Places</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {(dataSellerPlacesVisited?.placesVisitedArray || dataSellerPlacesVisited?.placesCountArray)?.map(
                    (day, dayIndex) => (
                      <React.Fragment key={dayIndex}>
                        <tr>
                          <td>{dayIndex + 1}.</td>
                          <td>{day?.date || dataSellerPlacesVisited?.placesCountArray?.[dayIndex]?.date}</td>
                          <td>{day?.places?.length || dataSellerPlacesVisited?.placesCountArray?.[dayIndex]?.count}</td>
                          {day?.places && (
                            <td>{day?.places?.join(', ')}</td>
                          )}
                        </tr>
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </>
            <div className="button">
              <button
                className="csv-button"
                onClick={handleExportPlacesToExcel("places")}
              >
                Download (Places)
              </button>
            </div>
          </div>
        ) : null}






      </div>
    </>
  );
};

export default CSV;