import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";

import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
import "./style.scss";
import { Images } from "../../assets/images";

import { useGetNotificationQuery } from "../../store/api";

import { useSelector } from "react-redux";

import io from 'socket.io-client';

// import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {

  

  







  const navigate = useNavigate();

  const handleAboutUs = () => {
    navigate(RouteNames.About.route);
    window.scrollTo(0, 0);
  };

  const handleTeam = () => {
    navigate(RouteNames.Team.route);
    window.scrollTo(0, 0);
  };

  function handlePrivacyStatementClick() {
    navigate(RouteNames.PrivacyPolicy.route);
    window.scrollTo(0, 0);
  }
  function handleTermsAndConditionsClick() {
    navigate(RouteNames.TermsAndConditions.route);
    window.scrollTo(0, 0);
  }
  function handleMobileApp() {
    navigate(RouteNames.MobileApp.route);
    window.scrollTo(0, 0);
  }
  function handleFAQs() {
    navigate(RouteNames.FAQs.route);
    window.scrollTo(0, 0);
  }
  function handleHowItWorks() {
    navigate(RouteNames.HowItWorks.route);
    window.scrollTo(0, 0);
  }

  // {notifications.map((notification) => (
  //   <div key={notification.id}>
  //     <p>{notification?.message}HEllo</p>
  //     {/* Add more notification details here */}
  //   </div>
  // ))}

  return (
    <footer className="footer">
      <Container>
        <Row className="row">

    
          <Col md={3}>
            <h5>Liberdat</h5>

         

            <div className="team">
              <span style={{ cursor: "pointer" }} onClick={handleAboutUs}>
                About
              </span>
              <span href="" style={{ cursor: "pointer" }} onClick={handleTeam}>
                Team
              </span>
            </div>
          </Col>

          <Col md={4}>
            <h5>Discover</h5>

            <p style={{ cursor: "pointer" }} onClick={handleHowItWorks}>
              How it Works
            </p>
            <p style={{ cursor: "pointer" }} onClick={handleMobileApp}>
              Mobile Apps
            </p>
          </Col>
          <Col md={3}>
            <h5>Support</h5>
            <p style={{ cursor: "pointer" }} onClick={handleFAQs}>
              FAQ's
            </p>

            <p>
              <a
                href="mailto:info@liberdat.io"
                style={{ textDecoration: "none", color: "#5b99a6" }}
              >
                info@liberdat.io
              </a>
            </p>
          </Col>
        </Row>

        <hr />
        <div className="icons">
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61557570200873">
              <AiOutlineFacebook
                style={{ color: "gray", fontSize: "44px", marginLeft: "9px" }}
              />
            </a>
            <a href="https://linkedin.com/company/liberdat-bv">
              <AiOutlineLinkedin
                style={{ color: "gray", fontSize: "44px", marginLeft: "9px" }}
              />
            </a>
            <a href="#">
              <AiOutlineInstagram
                style={{ color: "gray", fontSize: "44px", marginLeft: "9px" }}
              />
            </a>
          </div>
          <div className="playstore-icons">
            <a href="https://play.google.com/store/apps/details?id=com.liberdat.liberdatbeta&pli=1">
              <img src={Images.playstore} alt="playstore" width={152} />
            </a>
            <a href="https://apps.apple.com/nl/app/liberdat/id6463052903">
              <img src={Images.appstore} alt="appstore" width={135} />
            </a>
          </div>
        </div>

        <hr />
        <Row className="row2" style={{ marginBottom: "47px" }}>
          <Col md={3} style={{ fontSize: "12px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={handlePrivacyStatementClick}
            >
              Privacy Statement
            </div>
          </Col>

          <Col md={4} style={{ fontSize: "12px" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={handleTermsAndConditionsClick}
            >
              Terms & Conditions
            </div>
          </Col>

          <Col md={3} style={{ fontSize: "12px" }}>
            <div style={{ cursor: "pointer" }} className="allrights">
              © 2024 LiberDat B.V. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default React.memo(Footer);
