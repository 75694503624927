import React, { useState, useEffect } from "react";
import { Form, Badge } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import toast from "react-hot-toast";
import CustomButton from "../../custombutton/CustomButton";

const MultiCascaderForGeneralForm = ({
  options,
  onSelect,
  initialSelectedData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedData?.selectedOptions || []
  );
  const [expandedParents, setExpandedParents] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allClicks, setAllClicks] = useState([]);

  const handleOptionClick = (option) => {
    if (!option?.children) {
      const index = selectedOptions.findIndex((i) => i.value === option?.value);
      if (index !== -1) {
        const updatedItems = [
          ...selectedOptions.slice(0, index),
          ...selectedOptions.slice(index + 1),
        ];
        setSelectedOptions(updatedItems);
      } else {
        handleInput(option);
      }
    }

    if (allClicks?.includes(option?.value)) {
      setAllClicks(allClicks.filter((allClick) => allClick !== option?.value));
    } else {
      setAllClicks([...allClicks, option.value]);
    }
  };

  const handleInput = (option) => {
    let newArray = [...selectedOptions];

    const eachObject = {
      value: option?.value,
      displayName: option?.displayName,
    };
    newArray.push(eachObject);

    setSelectedOptions(newArray);
  };

  useEffect(() => {
    if (initialSelectedData?.selectedOptions?.length > 0) {
      setSelectedOptions(initialSelectedData?.selectedOptions);
    }
  }, [initialSelectedData]);

  const handleParentToggle = (value) => {
    if (expandedParents.includes(value)) {
      setExpandedParents(expandedParents.filter((parent) => parent !== value));
    } else {
      setExpandedParents([...expandedParents, value]);
    }
  };
  const renderOptions = (options, level = 0) => {
    return options.map((option) => (
      <div key={option.value}>
        {option.children && option.children.length > 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <div
              onClick={() => {
                handleParentToggle(option.value);
                handleOptionClick(option);
              }}
            >
              {expandedParents.includes(option.value) ? (
                <FaAngleDown />
              ) : (
                <FaAngleRight />
              )}
            </div>
            <div style={{ marginLeft: `${level * 20}px` }}>{option.label}</div>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Check
              className="custom-checkbox"
              type="checkbox"
              id={option.value}
              label={option.label}
              checked={selectedOptions.some(
                (selected) => selected.value === option.value
              )}
              onChange={() => handleOptionClick(option)}
              style={{ marginLeft: `${level * 20}px` }}
            />
          </div>
        )}
        {option.children &&
          option.children.length > 0 &&
          expandedParents.includes(option.value) && (
            <div style={{ marginLeft: "20px" }}>
              {renderOptions(option.children, level + 1)}
            </div>
          )}
      </div>
    ));
  };

  const handleToggleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleApply = () => {
    onSelect(selectedOptions);
    setDropdownOpen(false);
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          border: "1px solid #ced4da",
          borderRadius: ".25rem",
          padding: ".375rem .75rem",
          cursor: "pointer",
        }}
        onClick={handleToggleClick}
      >
        {selectedOptions?.length === 0 ? (
          "Select Options"
        ) : (
          <span style={{ color: "black" }}>
            {selectedOptions?.map((option) => option.displayName).join(", ")}
          </span>
        )}
        <Badge className="custom-badge">{selectedOptions?.length}</Badge>
      </div>
      {dropdownOpen && (
        <div className="inside-cascader">
          <Form>{renderOptions(options)}</Form>
          {selectedOptions?.length > 0 && (
            <CustomButton
              label={"Apply"}
              color={"#333333"}
              textcolor={"white"}
              backgroundColor={"#333333"}
              height={"38px"}
              width="100%"
              onClick={handleApply}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MultiCascaderForGeneralForm;
