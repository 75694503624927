import React, { useCallback, useEffect, useState } from "react";

export default function AuthSuccess() {

  const [errorMessage, setErrorMessage] = useState("");
  // const testURL =
  //   "https://liberdat.io/auth-success?code=4%2F0AdLIrYfTOwfmF6FN7o7NnhzNDbBMs4tROI1HrG_NsuALCY3vNwxbN3Of3iQKSu-iyNkLgg&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive#";

  useEffect(() => {
    const queryString = window.location.search;

    // Use a regular expression to extract the 'code' parameter without decoding it
    const match = queryString.match(/code=([^&]*)/);
    const extractedCode = match ? match[1] : null;

    // setCode(extractedCode);
    console.log("extractedCode", extractedCode);
    handleQuery(extractedCode);
  }, []);

  const handleQuery = useCallback(async (extractedCode) => {
    try {
      const response = await fetch(
        // http://localhost:3000/v1/
        // `http://192.168.1.15:3000/v1/auth/auth/google/callback?code=${extractedCode}`,

        `https://api.liberdat.io/v1/auth/auth/google/callback?code=${extractedCode}`,
        // `http://192.168.18.10:3000/v1/auth/auth/googlee/callback?code=${extractedCode}`,
        // `http://localhost:3000/v1/auth/auth/google/callback?code=${extractedCode}`,


        { method: "GET" }
      );
      console.log("RES", response)
      if (response.status === 200) {
        const data = await response.json();
        setErrorMessage("API is successful")
      }
      else if (!response.ok) {
        setErrorMessage("There is some issue in Authentication")

      }
      // console.log("data :", data);
    } catch (error) {
      console.log("error :", error.toString());
    } finally {
      // Redirect to the custom URL scheme after sending the code
      window.location.href = "liberdatApp://main";
    }
  }, []);


  return (
    <div className="back">
      <div className="containeremail">
        <>
          <h2 style={{ color: errorMessage === 'There is some issue in Authentication' ? 'red' : null }}>{errorMessage === 'There is some issue in Authentication' ? errorMessage : 'Authentication Successfull'}</h2>
          {/* <p>
            <b>{errorMessage}</b>
          </p> */}
        </>
      </div>
    </div>
  );
}
