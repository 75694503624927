import React from 'react'
import './style.scss';
import { Images } from '../../assets/images';

const MobileApp = () => {
  return (
    <div className="contain">
     
     <div className="first">
        <div className="pocket">

       <h2> Liberdat in your pocket </h2>
       <p>

       
Join the community by signing up 
via your mobile phone. Share or
request data from anywhere in the world.
</p>
<div className="playstore-icons">
<a href="https://play.google.com/store/apps/details?id=com.liberdat.liberdatbeta&pli=1">
              <img src={Images.playstore} alt="playstore" width={135} />
            </a>
            <a href="https://apps.apple.com/nl/app/liberdat/id6463052903">
              <img src={Images.appstore} alt="appstore" width={105} />
            </a>
          </div>
        </div>
        <div className="pocketimage">

        <img className="mobileimage" src={Images.mb2} alt="sadsa" />


        </div>
     </div>
              
    </div>
  )
}

export default MobileApp