import React from "react";
import "./style.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacypolicy">
      <h1 id="top" style={{ textAlign: "center", margin: "45px" }}>
        Privacy Policy
      </h1>

      <hr />

      <p className="paras">
      This privacy policy explains how LIBERDAT B.V. and its employees comply
with{" "}
        <a
          href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&qid=1691404126036"
          target="_blank"
          rel="noopener noreferrer"
        >
          Regulation (EU) 2016/679 Of The European Parliament And Of The Council
        </a>{" "}
        (“GDPR”) regarding the processing of the following personal data:.
      </p>

      

      <p>Identification Data</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>Username and password</li>
        <li>
        Institution name, department name, and corresponding institutional 
e-mail address (Requesters only)
        </li>
        <li>IP address (Requesters)</li>
        <p>Optional</p>
        <li>
        Bank account details (processed separately by the licensed third-party Stripe, Inc.)
        </li>
      </ul>

      <p>User Data</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>Data requests (Requesters)</li>
        <li>
        Profile data: general, clinical, and data from connected applications 
such as Health from Apple and Health Connect from Android 
(Sharers)
        </li>
        </ul>
        <p>Exchanged Data</p>
        <ul style={{ listStyleType: "disc" }}>
        <li>Profile data</li>
        <li>Questionnaire data</li>

      </ul>

      {/* <p className="paras">Transacted Data</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
        Profile data
        </li>
        <li>Questionnaire data</li>
      </ul> */}

      <hr />

      <p className="paras">
      LIBERDAT processes personal data such as identification data, user data,
and exchanged data in order to a) set up a license agreement for users; b)
provide   users   with   the   LIBERDAT   services;   c)   improve   the   LIBERDAT
services; d) inform users about updates.
      </p>
      <p className="paras">
      The processing of personal data happens exclusively after a user has read,
understood, and accepted this privacy statement.
      </p>
      <p className="paras">
      LIBERDAT does not sell the personal data of its users to third parties, nor
does it allow subcontractors to do so.
      </p>
      <p className="paras">
      Users’ personal data will remain in the Netherlands and will not be sent to
anyone without first informing users or obtaining explicit permission.
      </p>

      <p className="paras">
      LIBERDAT   keeps   personal   data   confidential   and   takes   all   reasonable
measures to keep it safe. LIBERDAT applies organizational measures such
as access management through which only certain employees have access
to personal data. Furthermore, LIBERDAT implements technical measures,
such   as   anonymization,   pseudonymization,   and   encryption,   to   secure
personal data.
      </p>

      <p className="paras">
      Identification data is stored for as long as users use the LIBERDAT services.
User data is stored for as long as the user decides to keep it in their personal
profile. LIBERDAT stores transacted data items in a database, provided by
MongoDB Inc., for 1 month, after which it is permanently removed.
      </p>

      <p className="paras">
      Users have access rights to their personal data and can correct where
necessary or add missing information.
      </p>

      <p className="paras">
      Users have the right to demand the stop of processing of their personal
data. Users can exercise this right by deleting their profile which can be
done manually by navigating to “Profile -> Settings -> Delete account” or
an email can be sent to{" "}
        <a href="mailto:info@liberdat.io">info@liberdat.io</a> with this request.
      </p>

      <p className="paras">
      Under certain conditions, users may demand the sole use of their personal
data for specific cases.
      </p>

      <p className="paras">
      Where technically feasible, users have the right to have their personal data
transmitted to other parties.
      </p>

      <p className="paras">
      Users have the right to rectification or deletion of their personal data.
      </p>

      <p className="paras">
      Questions or complaints can be sent to {" "}
        <a href="mailto:info@liberdat.io" className="paras">
          info@liberdat.io
        </a>.
        Users can also file
a complaint with the competent data protection authority.
      </p>

      <p className="paras">
      LIBERDAT informs about upcoming changes to the privacy statement via
the platform.
      </p>

      <h2 className="paras">Subcontractors</h2>
      <hr />

      <p>LIBERDAT contracts with the following subcontractors:</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
        Firebase Cloud messaging (terms & conditions)   and Apple Push 
Notification Service (terms & conditions): to send notifications
        </li>
        <li>Stripe, Inc. (terms & conditions): to facilitate monetary transactions 
between users</li>
        <li>Amazon Web Services (terms & conditions): to process the 
LIBERDAT services</li>
        <li>MongoDB Atlas (terms & conditions): to store data</li>
        <li>
        Google Play Store (terms & conditions): to download the application 
on Android devices
        </li>
        <li>Apple App Store (terms & conditions): to download the application 
on iOS devices</li>
      </ul>
    </div>
  );
};

export default React.memo(PrivacyPolicy);
