// components/body/index.js
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import { Images } from "../../assets/images";

const Body = ({ categories_heading, activeIndex, handleCategoriesClick }) => {
  return (
    <>
      <section className="category_sec">
        <div className="categories_list">
          {categories_heading.map((data, index) => (
            <div
              className={`col1 ${index === activeIndex ? "active" : ""}`}
              key={index}
              onClick={() => handleCategoriesClick(index)}
            >
              {/* <img src={data.image} className="img-fluid" alt="" /> */}
              <span>{data.title}</span>
            </div>
          ))}
          {/* <Link style={{ textDecoration: "none" }} to="/about">
            <div className="col1">
              <span>About</span>
            </div>
          </Link> */}
        </div>
      </section>
    </>
  );
};

export default React.memo(Body);
