
import React, { useState } from "react";


export const Messages = React.memo(() => {
 
  return <div>
    Messages
    
    </div>;
});
