import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import RouteNames from '../../routes/RouteNames';
import CustomTextField from '../../components/customtextfield/CustomTextField';
import CustomButton from '../../components/custombutton/CustomButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSignupMutation, usesendEmailMutation } from '../../store/api';
import toast from 'react-hot-toast';
import PrivacyModal from './PrivacyModal';
import PrivacyStatementModal from './PrivacyStatementModal';
import { InfinitySpin } from 'react-loader-spinner';
import { Loader } from '../../components/Loader';

import './style.scss';
import { setUserIdForTracking, trackEvent } from '../../utils/amplitude';
import { Events } from '../../utils/events';

export default React.memo(function SignupComponent({ isSeller, isBuyer }) {
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handlePrivacyChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const [OpenModal, setOpenModal] = useState(false);
  const [OpenPrivacyModal, setOpenPrivacyModal] = useState(false);

  const handleModal = () => {
    setOpenModal(true);
  };

  const handlePrivacyModal = () => {
    setOpenPrivacyModal(true);
  };

  const [signupMutation, { isLoading, isSuccess, isError, error,data }] =
    useSignupMutation();



  useEffect(() => {
    if (isSuccess && isBuyer) {
      setUserIdForTracking(data?.user?.username)
      setTimeout(() => {
        trackEvent(Events.USER_LOGIN,{
          username: data?.user?.username,
          role:data?.user?.role,
          deviceType:data?.user?.deviceType,
        })
      }, 500);
      toast.success('A verification email has been sent to your Email Address');
      setTimeout(() => {
        navigate(RouteNames.Login.route);
      }, 1000);
    } else if (isSuccess && isSeller) {
      setUserIdForTracking(data?.user?.username)
      setTimeout(() => {
        trackEvent(Events.USER_LOGIN,{
          username: data?.user?.username,
          role:data?.user?.role,
          deviceType:data?.user?.deviceType,
        })
      }, 500);
      toast.success('Sign Up Successful');
      setTimeout(() => {
        navigate(RouteNames.Login.route);
      }, 1000);
    } else if (isLoading) {
      <InfinitySpin width="200" color="#4fa94d" />;
    } else if (isError) {
      trackEvent(Events.USER_SIGNUP_ERROR,{
        error: error?.data?.message
       })
      toast.error(error?.data?.message);


    }
  }, [isSuccess, isError, error, isLoading]);

  const navigate = useNavigate();
  // const handleSignUp = () => {
  //   navigate(RouteNames.Login.route);
  // };

  const sellerValidation = {
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  };

  const buyerValidation = {
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required'),
    department: yup.string().required('Department is required'),
    institution: yup.string().required('Institution is required'),
  };

  const validationSchema = yup.object(
    isBuyer ? buyerValidation : sellerValidation
  );

  const handleSubmit = async (values, { resetForm }) => {
    if (privacyChecked) {
      let valuesToSend = {};

      if (isSeller) {
        valuesToSend = {
          username: values.username,
          password: values.password,
          role: 'seller',
          deviceType: 'Android',
        };
      } else if (isBuyer) {
        valuesToSend = {
          username: values.username,
          password: values.password,
          role: 'buyer',
          deviceType: 'Android',
          email: values.email,
          institution: {
            name: values.institution,
            department: values.department,
          },
        };
      }

      // console.log('Form is Valid:', valuesToSend);
      await signupMutation(valuesToSend);
      resetForm();

      try {
      } catch (error) {
        toast.error('Unable to Sign up');
      }
    } else {
      toast.error(
        'Please agree to the Privacy Statement and Terms & Conditions.'
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      institution: '',
      department: '',
      email: '',
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div>
        {isSeller && (
          <>
            <CustomTextField
              label="Username"
              type="text"
              placeholder="Enter your username"
              name="username"
              formik={formik}
            />
            <div className="mt-2" />
            <CustomTextField
              label="Password"
              type="password"
              placeholder="Enter your password"
              name="password"
              formik={formik}
            />
            <label style={{ marginTop: '11px', fontSize:'15px' }}>
              <input
                type="checkbox"
                id="privacyCheckbox"
                name="privacyCheckbox"
                checked={privacyChecked}
                onChange={handlePrivacyChange}
              />{' '}
              I have read, understood, and accept the{' '}
              <a
                style={{ cursor: 'pointer', color: '#5B99A6' }}
                onClick={handleModal}
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                style={{ cursor: 'pointer', color: '#5B99A6' }}
                onClick={handlePrivacyModal}
              >
                Privacy Statement
              </a>
            </label>
            <PrivacyModal
              isOpen={OpenModal}
              onRequestClose={() => setOpenModal(false)}
            />
            <PrivacyStatementModal
              isOpen={OpenPrivacyModal}
              onRequestClose={() => setOpenPrivacyModal(false)}
            />
          </>
        )}

        {isBuyer && (
          <>
            <CustomTextField
              label="Username"
              type="text"
              placeholder="Enter your username"
              name="username"
              formik={formik}
            />
            <div className="mt-2" />
            <CustomTextField
              label="Password"
              type="password"
              placeholder="Enter your password"
              name="password"
              formik={formik}
            />
            <div className='mt-2'>
            <Form.Group className="form-group mb-3">
              <Form.Label style={{fontWeight: 'bold',fontSize: '18px',fontWeight: 'bold'}}>Institution</Form.Label>
              <Form.Select
              style={{backgroundColor: '#EEEEEE'}}
                name="institution"
                id="institution"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.institution}
              >
                <option value="" selected disabled> Select your institution </option>
                <option value="UCL Hospital">UCL Hospital</option>
                <option value="Isala Zwolle">Isala Zwolle</option>
              </Form.Select>
              {formik.touched.institution && formik.errors.institution ? (
                <div className="error">{formik.errors.institution}</div>
              ) : null}
            </Form.Group>

            {/* <CustomTextField
              label="Institution"
              type="text"
              placeholder="Enter name of your Institute"
              name="institution"
              style={{ marginTop: '2px' }}
              formik={formik}
            /> */}
            </div>
            <div className='mt-2'>
            <Form.Group className="form-group mb-3">
              <Form.Label style={{fontWeight: 'bold',fontSize: '18px',fontWeight: 'bold'}}>Department</Form.Label>
              <Form.Select
              style={{backgroundColor: '#EEEEEE'}}
                name="department"
                id="department"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.department}
              >
                <option value="" selected disabled> Select your department </option>
                <option value="Neurology">Neurology</option>
                <option value="IT">IT</option>
              </Form.Select>
              {formik.touched.department && formik.errors.department ? (
                <div className="error">{formik.errors.department}</div>
              ) : null}
            </Form.Group>
            {/* <CustomTextField
              label="Department"
              type="text"
              placeholder="Enter name of your Department"
              name="department"
              style={{ marginTop: '2px' }}
              formik={formik}
            /> */}
            </div>
            <div className='mt-2'>
            <CustomTextField
              label="E-mail address"
              type="email"
              placeholder="Enter Your E-mail address"
              name="email"
              style={{ marginTop: '2px' }}
              formik={formik}
            />
</div>
            <label style={{ marginTop: '11px' }}>
              <input
                type="checkbox"
                id="privacyCheckbox"
                name="privacyCheckbox"
                checked={privacyChecked}
                onChange={handlePrivacyChange}
              />{' '}
              I have read, understood, and accept the{' '}
              <a
                style={{ cursor: 'pointer', color: '#5B99A6' }}
                onClick={handleModal}
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                style={{ cursor: 'pointer', color: '#5B99A6' }}
                onClick={handlePrivacyModal}
              >
                Privacy Statement
              </a>
            </label>
            <PrivacyModal
              isOpen={OpenModal}
              onRequestClose={() => setOpenModal(false)}
            />
            <PrivacyStatementModal
              isOpen={OpenPrivacyModal}
              onRequestClose={() => setOpenPrivacyModal(false)}
            />
          </>
        )}
        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <CustomButton
            className="Btn1"
            label={'Sign Up'}
            color={'#333333'}
            textcolor={'white'}
            width={'100%'}
            backgroundColor={'#333333'}
            disabled={isLoading}

            // type="submit"
            // onClick={handleSubmit}
          />
        )}
      </div>
    </Form>
  );
});
