import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { track } from '@amplitude/analytics-browser';

// Fallback component for displaying error info
const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" style={{ textAlign: 'center', padding: '20px' }}>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

// Error boundary with tracking using Amplitude
const ErrorBoundaryComponent = ({ children }) => {
  const logCrashEvent = async (error) => {
    try {
      // Track the crash event with Amplitude
       track('app_crash', {
        errorMessage: error.message,
        errorStack: error.stack,
      });
      console.log('Crash event tracked with Amplitude');
    } catch (trackError) {
      console.error('Failed to log crash event', trackError);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={logCrashEvent} // Logs the error to Amplitude
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryComponent;
