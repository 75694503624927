import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../store/reducers/authSlice';
import './ThresholdModal.scss';

const ThresholdModal = ({ visible, handleClose, value, setValue, onSubmit }) => {

    if (!visible) return null; // Don't render if modal is not visible

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <span className="modal-close" onClick={() => handleClose(false)}>X</span>

                <h2>Set Threshold</h2>
                <input className='modal-input' type="text" placeholder="Enter threshold" value={value} onChange={(e) => setValue(e.target.value)} />
                <button className="modal-button" onClick={onSubmit}>Submit</button>
            </div>
        </div>
    );
};

export default ThresholdModal;
