import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDeleteUserbyEmailMutation, useDeleteUserByIdMutation } from "../../store/api";
import toast from "react-hot-toast";
import RouteNames from "../../routes/RouteNames";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../../store/reducers/authSlice";
import "./EmailStyle.scss";

const DeleteByEmail = () => {
    const user = useSelector((state) => state.auth?.user?.user);
    const [email, setEmail] = useState("");
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Logged-in mutation for deleting by user ID
    const [deleteUserById, { isSuccess: isSuccessId, isError: isErrorId, error: errorId, isLoading: isLoadingId }] = useDeleteUserByIdMutation();

    // Logged-out mutation for deleting by email
    const [deleteUserbyEmail, { isSuccess: isSuccessEmail, isError: isErrorEmail, error: errorEmail, isLoading: isLoadingEmail }] = useDeleteUserbyEmailMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!hasAcceptedTerms) {
            toast.error("You must accept the terms and conditions before deleting.");
            return;
        }

        if (user) {
            handleDeleteAccount();
        } else {
            handleDeleteByEmail();
        }
    };

    const handleDeleteAccount = async () => {
        try {
            await deleteUserById(user?.id);
            dispatch(clearUser());
            navigate(RouteNames.Default.route);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteByEmail = async () => {
        try {
            await deleteUserbyEmail({ email });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (isSuccessId) {
            toast.success("Account Deleted Successfully");
        } else if (isErrorId) {
            toast.error(errorId?.data?.message || "Error deleting account");
        }

        if (isSuccessEmail) {
            toast.success("Account Deleted Successfully");
        } else if (isErrorEmail) {
            toast.error(errorEmail?.data?.message || "Error deleting account");
        }
    }, [isSuccessId, isErrorId, errorId, isSuccessEmail, isErrorEmail, errorEmail]);

    return (
        <div className="custom-form-container">
            <form className="custom-form" onSubmit={handleSubmit}>
                <h1 className="custom-form-heading">Delete Account</h1>
                {user && (
                    <div className="para-details">
                        <p>
                            You <b> ({user?.username}) </b> have read, understand, and accepted
                            the Terms & Conditions and the Privacy Statement on{" "}

                        </p>
                    </div>
                )}
                {!user && (
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={!user}
                        className="custom-input"
                    />
                )}
                <div className="custom-terms-section">
                    <input
                        type="checkbox"
                        checked={hasAcceptedTerms}
                        onChange={(e) => setHasAcceptedTerms(e.target.checked)}
                        className="custom-checkbox"
                    />
                    <label className="custom-terms-label">
                        I have read and accept the <a href="#">terms and conditions</a>.
                    </label>
                </div>
                <button
                    type="submit"
                    className="custom-delete-button"
                    disabled={(isLoadingId || isLoadingEmail) || !hasAcceptedTerms}
                >
                    {(isLoadingId || isLoadingEmail) ? "Deleting..." : "Delete Account"}
                </button>
            </form>
        </div>
    );
};

export default DeleteByEmail;
