import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup"; // Import Yup
import CustomTextField from "../../components/customtextfield/CustomTextField";
import CustomButton from "../../components/custombutton/CustomButton";
import RouteNames from "../../routes/RouteNames";
import { Images } from "../../assets/images";
import "./style.scss";

import { Loader } from "../../components/Loader";
import {
  useCreateBidMutation,
  useGetMyBidsQuery,
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} from "../../store/api";
import { setUser } from "../../store/reducers/authSlice";

import { Formik, useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

export default function ResetPassword() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handleSubmit = (values) => {
    console.log("values", values);
  };
  const handlePrivacyChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const [
    ResetPasswordMutation,
    { isError, isSuccess, isLoading, error, data },
  ] = useResetPasswordMutation();

  const dispatch = useDispatch();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password Required"),
    confirmPassword: Yup.string()
      .required("Password Required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await ResetPasswordMutation(values);
    } catch (error) {
      toast.error(error);
    }

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login_body">
      <div className="logo">
        <div className="backarrow" onClick={handleClick}>
          <IoMdArrowRoundBack
            size={20}
            style={{ color: "#5B99A6", cursor: "pointer" }}
          />
        </div>

        <img src={Images.logo_} alt="LiberDat" className="logoimage" />
      </div>

      <Form
        className="container1"
        style={{ paddingTop: "40px" }}
        onSubmit={formik.handleSubmit}
      >
        <div style={{ width: "80%" }}>
          <CustomTextField
            name="newPassword"
            label="New Password"
            type="password"
            placeholder="Enter your new password"
            formik={formik}
          />
          <div className="mt-2" />
          <CustomTextField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            placeholder="Re-enter your password"
            formik={formik}
          />
          <div className="login-link">
            <br />
          </div>
        </div>

        {isLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <CustomButton
            style={{ paddingBottom: "15px" }}
            label={"Reset Password"}
            color={"#333333"}
            textcolor={"white"}
            backgroundColor={"#333333"}
            type="submit"
            width="80%"
          />
        )}
      </Form>
      <p>
        Create a new Account?&nbsp;
        <span
          onClick={() => navigate(RouteNames.Signup.route)}
          style={{ color: "#5B99A6", cursor: "pointer" }}
        >
          Sign up
        </span>
      </p>
      <p>
        <img src={Images.liberdaticon} alt="LiberDat" className="lowerimage" />
      </p>
    </div>
  );
}
