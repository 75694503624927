import React, { useState } from 'react';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import './style.scss';

const CustomTabs = ({ tabItems, activeColor, inactiveColor, color }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  // console.log(tabItems);
  
  
  return !(tabItems || []).length ? null: ( 

    <ReactTabs className='reacttabs'>
      <TabList className='tablist'>
        {tabItems.map((item, index) => (
          <Tab 
            className="tab"
            key={index}
            style={{
              color: selectedTabIndex === index ? 'white' : inactiveColor,
              backgroundColor: `${selectedTabIndex === index ? activeColor : 'transparent'}`,
              borderBottom: `3px solid ${selectedTabIndex === index ? activeColor : 'transparent'}`,
              fontWeight:`bold`
            }}
            onClick={() => handleTabSelect(index)}
          >
            {item.label}
          </Tab>
        ))}
      </TabList>
      {tabItems.map((item, index) => (
        <div className='tabpanel' key={index}>
          <TabPanel>{item.content}</TabPanel>
          
        </div>
      ))}
    </ReactTabs>
  );
};

export default React.memo(CustomTabs);
