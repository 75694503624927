import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import "./style.scss";
import { Link } from "react-router-dom";
import { useDeleteUserByIdMutation } from "../../store/api";
import toast from "react-hot-toast";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const user = useSelector((state) => state.auth?.user?.user);

  const [deleteUserById, { data, isSuccess, isError, error, isLoading }] =
    useDeleteUserByIdMutation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success("Account Deleted Successfully");
        navigate(RouteNames.Login.route);
      }, 500);
    } else if (isError) {
      toast.error(error?.data?.message);
    }
  }, [isError, isLoading, isSuccess, error, navigate]);

  const handleDeleteAccount = async () => {
    try {
      await deleteUserById(user?.id);
    } catch (error) {
      console.error(error);
    }
    handleClose();
  };

  const formatTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <div>
      <div className="container-delete">
        <h1 className="heading">Settings</h1>
        <div className="para-details">
          <p>
            You <b> ({user?.username}) </b> have read, understand, and accepted
            the Terms & Conditions and the Privacy Statement on{" "}
            <b>{formatTime(user?.createdAt)}</b>
          </p>
        </div>
        <div className="form">
          <button type="submit" className="delete-button" onClick={handleShow}>
            Delete Account
          </button>
          <Modal className="confirmationModal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete account permanently?
            </Modal.Body>
            <Modal.Footer>
              <button className="cancel-button" onClick={handleClose}>
                No, cancel
              </button>
              <button className="delete-button" onClick={handleDeleteAccount}>
                Yes, I'm sure
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
