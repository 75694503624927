import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import toast from "react-hot-toast";

const MultiCascader = ({ options, onSelect, initialSelectedData, selectedDiagnosis }) => {
  // console.log("Handle Select in Multi", onSelect)
  console.log("initialSelectedData", initialSelectedData);
  console.log("selectedDiagnosis:", selectedDiagnosis)

  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedData?.selectedOptions || []
  );
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [itemClicks, setItemClicks] = useState([]);
  // const [allClicks, setAllClicks] = useState([]);
  const [expandedParents, setExpandedParents] = useState([]);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [allClicks, setAllClicks] = useState([]);
  // const [noTherapies, setNoTherapies] = useState(false);
  // const [noOtherTherapies, setNoOtherTherapies] = useState(false);

  // console.log("options Data: ", options);

  const handleOptionClick = (item, parentLabel) => {
    //  console.log("OPTIONSS",item)
    if (item?.children?.length === 0) {
      const selectedItemIndex = selectedOptions.findIndex(
        (selectedItem) =>
          selectedItem.label === item.label &&
          selectedItem?.value === item?.value &&
          selectedItem.multiSelect === item.multiSelect &&
          selectedItem.parent === item.parent
      );
      if (selectedItemIndex !== -1) {
        const newselectedOptions = [...selectedOptions];
        newselectedOptions.splice(selectedItemIndex, 1);
        setSelectedOptions(newselectedOptions);
      } else {
        if (
          selectedOptions.some((i) => i.value == "Therapies_No therapies") &&
          item?.value?.includes("Therapies")
        ) {
          toast.error(
            "Please unselect 'No therapies' from the top section to add up therapies."
          );
        } else {
          if (
            selectedOptions.some(
              (selectedItem) =>
                (selectedItem?.displayName?.includes("1st therapy) Main") &&
                  item?.displayName?.includes("1st therapy) Main")) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Simultaneously"
                ) &&
                  item?.displayName?.includes("1st therapy) Simultaneously")) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Additionally 1st"
                ) &&
                  item?.displayName?.includes(
                    "1st therapy) Additionally 1st"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Additionally 2nd"
                ) &&
                  item?.displayName?.includes(
                    "1st therapy) Additionally 2nd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Additionally 3rd"
                ) &&
                  item?.displayName?.includes(
                    "1st therapy) Additionally 3rd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Additionally Unspecified"
                ) &&
                  item?.displayName?.includes(
                    "1st therapy) Additionally Unspecified"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "1st therapy) Unspecified"
                ) &&
                  item?.displayName?.includes("1st therapy) Unspecified")) ||
                (selectedItem?.displayName?.includes("2nd therapy) Main") &&
                  item?.displayName?.includes("2nd therapy) Main")) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Simultaneously"
                ) &&
                  item?.displayName?.includes("2nd therapy) Simultaneously")) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Additionally 1st"
                ) &&
                  item?.displayName?.includes(
                    "2nd therapy) Additionally 1st"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Additionally 2nd"
                ) &&
                  item?.displayName?.includes(
                    "2nd therapy) Additionally 2nd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Additionally 3rd"
                ) &&
                  item?.displayName?.includes(
                    "2nd therapy) Additionally 3rd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Additionally Unspecified"
                ) &&
                  item?.displayName?.includes(
                    "2nd therapy) Additionally Unspecified"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "2nd therapy) Unspecified"
                ) &&
                  item?.displayName?.includes("2nd therapy) Unspecified")) ||
                (selectedItem?.displayName?.includes("3rd therapy) Main") &&
                  item?.displayName?.includes("3rd therapy) Main")) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Simultaneously"
                ) &&
                  item?.displayName?.includes("3rd therapy) Simultaneously")) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Additionally 1st"
                ) &&
                  item?.displayName?.includes(
                    "3rd therapy) Additionally 1st"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Additionally 2nd"
                ) &&
                  item?.displayName?.includes(
                    "3rd therapy) Additionally 2nd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Additionally 3rd"
                ) &&
                  item?.displayName?.includes(
                    "3rd therapy) Additionally 3rd"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Additionally Unspecified"
                ) &&
                  item?.displayName?.includes(
                    "3rd therapy) Additionally Unspecified"
                  )) ||
                (selectedItem?.displayName?.includes(
                  "3rd therapy) Unspecified"
                ) &&
                  item?.displayName?.includes("3rd therapy) Unspecified"))
            )
          ) {
            toast.error(
              "You have already selected this therapy value somewhere please unselect that to select this one."
            );
          } else if (
            selectedOptions.some(
              (selectedItem) =>
                selectedItem?.displayName?.includes(
                  "(Current - 1st therapy)"
                ) &&
                (item?.displayName?.includes("2nd therapy)") ||
                  item?.displayName?.includes("3rd therapy)"))
            )
          ) {
            toast.error(
              "You cannot select 2nd/3rd therapy if you have Current 1st therapy."
            );
          } else if (
            selectedOptions.some(
              (selectedItem) =>
                selectedItem?.displayName?.includes(
                  "(Current - 2nd therapy)"
                ) && item?.displayName?.includes("3rd therapy)")
            )
          ) {
            toast.error(
              "You cannot select 3rd therapy if you have Current 2nd therapy."
            );
          } else if (
            selectedOptions.some(
              (selectedItem) =>
                (selectedItem?.displayName?.includes(
                  "(Previous - 2nd therapy)"
                ) ||
                  selectedItem?.displayName?.includes(
                    "(Previous - 3rd therapy)"
                  )) &&
                item?.displayName?.includes("(Current - 1st therapy)")
            )
          ) {
            toast.error(
              "You cannot select Current 1st therapy if you have previous 2nd/3rd therapy."
            );
          } else if (
            selectedOptions.some(
              (selectedItem) =>
                selectedItem?.displayName?.includes(
                  "(Previous - 3rd therapy)"
                ) &&
                (item?.displayName?.includes("(Current - 1st therapy)") ||
                  item?.displayName?.includes("(Current - 2nd therapy)"))
            )
          ) {
            toast.error(
              "You cannot select Current 1st/2nd therapy if you have previous 3rd therapy."
            );
          } else {
            const itemWithSameParentIndex = selectedOptions.findIndex(
              (selectedItem) =>
                selectedItem.parent === item.parent ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Mavenclad"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Mavenclad"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Tecfidera"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Tecfidera"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Vumerity"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Vumerity"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Gilenya"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Gilenya"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Tascenso"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Tascenso"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Bafiertam"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Bafiertam"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Zeposia"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Zeposia"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Ponvory"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Ponvory"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Mayzent"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Mayzent"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Aubagio"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Aubagio"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Tablet_Unspecified"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Tablet_Unspecified"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_corticotropin"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_corticotropin"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Copaxone"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Copaxone"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Avonex"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Avonex"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Rebif"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Rebif"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Betaseron"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Betaseron"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Extavia"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Extavia"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Kesimpta"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Kesimpta"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Injection_Plegridy"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Injection_Plegridy"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Infusion_Lemtrada"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Infusion_Lemtrada"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Infusion_mitoxantrone"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Infusion_mitoxantrone"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Infusion_Tysabri"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Infusion_Tysabri"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Infusion_Ocrevus"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Infusion_Ocrevus"
                  )) ||
                (selectedItem.value.includes(
                  "Current_Pharmaceutical_Infusion_Briumvi"
                ) &&
                  item?.value?.includes(
                    "Current_Pharmaceutical_Infusion_Briumvi"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Mavenclad"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Mavenclad"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Tecfidera"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Tecfidera"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Vumerity"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Vumerity"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Gilenya"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Gilenya"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Tascenso"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Tascenso"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Bafiertam"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Bafiertam"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Zeposia"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Zeposia"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Ponvory"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Ponvory"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Mayzent"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Mayzent"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Aubagio"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Aubagio"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Tablet_Unspecified"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Tablet_Unspecified"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_corticotropin"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_corticotropin"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Copaxone"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Copaxone"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Avonex"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Avonex"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Rebif"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Rebif"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Betaseron"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Betaseron"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Extavia"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Extavia"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Kesimpta"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Kesimpta"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Injection_Plegridy"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Injection_Plegridy"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Infusion_Lemtrada"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Infusion_Lemtrada"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Infusion_mitoxantrone"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Infusion_mitoxantrone"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Infusion_Tysabri"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Infusion_Tysabri"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Infusion_Ocrevus"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Infusion_Ocrevus"
                  )) ||
                (selectedItem.value.includes(
                  "Previous_Pharmaceutical_Infusion_Briumvi"
                ) &&
                  item?.value?.includes(
                    "Previous_Pharmaceutical_Infusion_Briumvi"
                  ))
            );
            if (itemWithSameParentIndex !== -1 && !item?.multiSelect) {
              const newselectedOptions = [...selectedOptions];
              newselectedOptions[itemWithSameParentIndex] = item;
              setSelectedOptions(newselectedOptions);
            } else {
              if (
                item?.parent === "Applications_Health_Google" ||
                item?.parent === "Applications_Health_Apple"
              ) {
                const isApple = selectedOptions.some(
                  (selectedItem) =>
                    selectedItem.parent === "Applications_Health_Apple"
                );
                const isGoogle = selectedOptions.some(
                  (selectedItem) =>
                    selectedItem.parent === "Applications_Health_Google"
                );

                if (item?.parent === "Applications_Health_Apple" && isGoogle) {
                  toast.error("Please remove values from Google Fit");
                } else if (
                  item?.parent === "Applications_Health_Google" &&
                  isApple
                ) {
                  toast.error("Please remove values from Apple Health");
                } else {
                  setSelectedOptions([...selectedOptions, item]);
                }
              } else {
                setSelectedOptions([...selectedOptions, item]);
              }
            }
          }
        }
      }
    }
    if (itemClicks.includes(item?.value)) {
      setItemClicks(
        itemClicks.filter((itemClick) => itemClick !== item?.value)
      );
    } else {
      handleItemClick(item);
    }
    if (allClicks?.includes(item?.value)) {
      setAllClicks(allClicks.filter((allClick) => allClick !== item?.value));
    } else {
      setAllClicks([...allClicks, item.value]);
    }
    // Check for Apple Health and Google Fit conflicts
    // const isAppleHealthSelected = selectedOptions.some(
    //   (selected) => selected.parent === "Applications_Health_Apple"
    // );
    // const isGoogleFitSelected = selectedOptions.some(
    //   (selected) => selected.parent === "Applications_Health_Google"
    // );

    // if (
    //   isAppleHealthSelected &&
    //   option.parent === "Applications_Health_Google"
    // ) {
    //   alert(
    //     "Please unselect all options in Apple Health before selecting in Google Fit."
    //   );
    //   return;
    // }

    // if (isGoogleFitSelected && option.parent === "Applications_Health_Apple") {
    //   alert(
    //     "Please unselect all options in Google Fit before selecting in Apple Health."
    //   );
    //   return;
    // }

    // // Handle "No therapies" selection
    // if (option.label === "No therapies") {
    //   if (
    //     selectedOptions.some(
    //       (item) =>
    //         item.value.includes("Therapies") &&
    //         item.value !== "Therapies_No therapies"
    //     )
    //   ) {
    //     setSelectedOptions([]);
    //     setAllClicks([]);
    //   }
    //   setNoTherapies((prev) => !prev);
    //   if (!noTherapies) {
    //     setSelectedOptions([option]);
    //     setAllClicks([option.value]);
    //   } else {
    //     setSelectedOptions([]);
    //     setAllClicks([]);
    //   }
    //   return;
    // }

    // // Handle "No other therapies" selection
    // if (option.label === "No other therapies") {
    //   if (noTherapies) {
    //     alert(
    //       "Please unselect 'No therapies' before selecting 'No other therapies'."
    //     );
    //     return;
    //   }
    //   setNoOtherTherapies((prev) => !prev);
    //   if (!noOtherTherapies) {
    //     setSelectedOptions([...selectedOptions, option]);
    //     setAllClicks([...allClicks, option.value]);
    //   } else {
    //     setSelectedOptions(
    //       selectedOptions.filter(
    //         (selected) => selected.label !== "No other therapies"
    //       )
    //     );
    //     setAllClicks(allClicks.filter((value) => value !== option.value));
    //   }
    //   return;
    // }

    // // Handle medicine selections
    // // Handle medicine selections
    // const medicineIdentifier = option.parent.split("_").slice(0, 5).join("_");
    // const therapyLevel = option.parent.includes("First therapy")
    //   ? "First"
    //   : option.parent.includes("Second therapy")
    //   ? "Second"
    //   : option.parent.includes("Third therapy")
    //   ? "Third"
    //   : null;

    // if (option.label === "Main therapy") {
    //   const currentMedicineMainTherapies = selectedOptions.filter(
    //     (selected) =>
    //       selected.parent.startsWith(medicineIdentifier) &&
    //       selected.label === "Main therapy"
    //   );

    //   const otherMedicinesMainTherapies = selectedOptions.filter(
    //     (selected) =>
    //       !selected.parent.startsWith(medicineIdentifier) &&
    //       selected.label === "Main therapy"
    //   );

    //   if (currentMedicineMainTherapies.length > 0) {
    //     alert("Only one Main therapy can be selected for this medicine.");
    //     return;
    //   }

    //   if (therapyLevel === "First") {
    //     const hasOtherSecondOrThirdMain = otherMedicinesMainTherapies.some(
    //       (therapy) =>
    //         therapy.parent.includes("Second therapy") ||
    //         therapy.parent.includes("Third therapy")
    //     );
    //     if (
    //       !hasOtherSecondOrThirdMain &&
    //       otherMedicinesMainTherapies.length > 0
    //     ) {
    //       alert(
    //         "Cannot select First Main therapy when no Second or Third Main therapy is selected for other medicines."
    //       );
    //       return;
    //     }
    //   }

    //   // Allow selection of Second or Third Main therapy without restrictions
    // }

    // // Handle selections within a medicine
    // const isSelected = selectedOptions.some(
    //   (selected) => selected.value === option.value
    // );
    // let newSelectedOptions = selectedOptions.filter(
    //   (selected) => !selected.parent.startsWith(medicineIdentifier)
    // );

    // if (!isSelected) {
    //   if (option.parent.includes("Additionally")) {
    //     // If "Additionally Started" is selected, only uncheck options outside of "Additionally Started"
    //     newSelectedOptions = [
    //       ...newSelectedOptions,
    //       ...selectedOptions.filter(
    //         (selected) =>
    //           selected.parent.startsWith(medicineIdentifier) &&
    //           selected.parent.includes("Additionally")
    //       ),
    //       option,
    //     ];
    //   } else {
    //     // If option outside "Additionally Started" is selected, uncheck all options within the medicine
    //     newSelectedOptions = [...newSelectedOptions, option];
    //   }
    // }

    // setSelectedOptions(newSelectedOptions);
    // setAllClicks(newSelectedOptions.map((option) => option.value));

    // // Additional checks for therapy selections
    // if (noTherapies && parentLabel === "Therapies") {
    //   alert("Cannot select therapies when 'No therapies' is selected.");
    //   return;
    // }

    // // Update expandedParents state if necessary
    // if (option.children && option.children.length > 0) {
    //   setExpandedParents((prevExpanded) =>
    //     prevExpanded.includes(option.value)
    //       ? prevExpanded.filter((value) => value !== option.value)
    //       : [...prevExpanded, option.value]
    //   );
    // }
  };
  const handleItemClick = (item) => {
    let updatedClicks = [];
    console.log("Updated Click: ", updatedClicks);
    if (item.value === "Therapies") {
      updatedClicks = itemClicks.filter(
        (click) =>
          click !== "Applications" &&
          click !== "Questionnaires" &&
          click !== "Analysis"
      );
      updatedClicks.push("Therapies");
    } else if (item.value === "Applications") {
      updatedClicks = ["Applications"];
    } else if (item.value === "Questionnaires") {
      updatedClicks = ["Questionnaires"];
    } else if (item.value === "Analysis") {
      updatedClicks = ["Analysis"];
    } else {
      updatedClicks = [...itemClicks, item.value];
    }
    setItemClicks(updatedClicks);
    onSelect(updatedClicks);
  };
  // const shouldShowAlert = (option, parentLabel) => {
  //   if (["First", "Second", "Third"].includes(option.label)) {
  //     if (
  //       selectedOptions.some(
  //         (selected) =>
  //           selected.label === option.label &&
  //           selected.parent.startsWith("Therapies_Current")
  //       )
  //     ) {
  //       alert(`Cannot select multiple ${option.label} therapies.`);
  //       return true;
  //     }
  //   }

  //   if (option.label === "Simultaneously Started") {
  //     if (
  //       selectedOptions.some(
  //         (selected) =>
  //           selected.label === "Simultaneously Started" &&
  //           selected.parent.startsWith("Therapies_Current")
  //       )
  //     ) {
  //       alert("Cannot select multiple Simultaneously Started therapies.");
  //       return true;
  //     }
  //   }

  //   if (parentLabel.includes("Therapies_Current")) {
  //     const selectedTherapies = selectedOptions.filter((selected) =>
  //       ["First therapy", "Second therapy", "Third therapy"].includes(
  //         selected.parent
  //       )
  //     );

  //     if (
  //       selectedTherapies.some((selected) => selected.parent !== option.parent)
  //     ) {
  //       alert("Cannot select therapies from different groups.");
  //       return true;
  //     }
  //   }

  //   if (option.label === "Main therapy") {
  //     if (
  //       selectedOptions.some((selected) => selected.label === "Main therapy")
  //     ) {
  //       alert("Cannot select multiple Main therapies.");
  //       return true;
  //     }
  //   }

  //   if (
  //     hasCurrentFirstTherapy() &&
  //     ["2nd therapy", "3rd therapy"].includes(option.label)
  //   ) {
  //     alert(
  //       "Cannot select 2nd or 3rd therapy when Current 1st therapy is selected."
  //     );
  //     return true;
  //   }

  //   return false;
  // };

  const renderOptions = (
    options,
    parentLabel = "",
    level = 0,
    isFirstParent = true
  ) => {
    return options.map((option, index) => {
      const isTopLevelParent = level === 0;
      const shouldAddHr = isFirstParent && isTopLevelParent;
      if (
        (!selectedOptions?.some((item) => item?.value?.includes("Therapies")) &&
          option?.value === "Therapies_No_Other_Therapies") ||
        (selectedOptions?.some(
          (item) => item?.value === "Therapies_No therapies"
        ) &&
          option?.value === "Therapies_No_Other_Therapies") ||
        (selectedOptions?.some(
          (item) => item?.value === "Therapies_All therapies"
        ) &&
          option?.value === "Therapies_No_Other_Therapies")
      ) {
        return null;
      } else if (
        (selectedOptions?.some(
          (it) =>
            it?.value?.includes("Therapies_Previous") ||
            it?.value?.includes("Therapies_Current")
        ) &&
          option?.value === "Therapies_No therapies") ||
        (selectedOptions?.filter(
          (it) =>
            it?.value?.includes("Therapies") &&
            !it?.value == "Therapies_No therapies"
        ).length > 0 &&
          option?.value === "Therapies_No therapies") ||
        (selectedOptions?.some(
          (item) => item?.value === "Therapies_No_Other_Therapies"
        ) &&
          option?.value === "Therapies_No therapies")
      ) {
        return null;
      } else if (!option?.belongsTo || selectedDiagnosis?.some(i => i.includes(option?.belongsTo))) {
        return (
          <div key={option.value}>
            {option.children && option.children.length > 0 ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={() => handleParentToggle(option.value)}
                  style={{ marginLeft: `${level * 20}px` }}
                >
                  {expandedParents.includes(option.value) ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </div>
                <div
                  style={{
                    marginLeft: "10px",
                    fontWeight: shouldAddHr ? "bold" : "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => handleParentToggle(option.value)}
                >
                  {option.label}
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* {console.log("SELECTEDDD",selectedOptions)} */}
                <Form.Check
                  type="checkbox"
                  id={option.value}
                  label={option.label}
                  checked={selectedOptions.some(
                    (selected) => selected.value === option.value
                  )}
                  // checked={true}
                  onChange={() => handleOptionClick(option, parentLabel)}
                  style={{ marginLeft: `${level * 20}px` }}
                />
              </div>
            )}
            {option.children &&
              option.children.length > 0 &&
              expandedParents.includes(option.value) && (
                <div style={{ marginLeft: "20px" }}>
                  {renderOptions(
                    option.children,
                    parentLabel || option.label,
                    level + 1,
                    false
                  )}
                </div>
              )}
            {shouldAddHr && <hr />}
          </div>
        );
      }
    });
  };

  useEffect(() => {
    handleApply();
  }, [selectedOptions, allClicks]);

  useEffect(() => {
    if (initialSelectedData?.selectedOptions?.length > 0) {
      setSelectedOptions(initialSelectedData?.selectedOptions);
    }
  }, [initialSelectedData]);

  const handleParentToggle = (value) => {
    if (expandedParents.includes(value)) {
      setExpandedParents(expandedParents.filter((parent) => parent !== value));
    } else {
      setExpandedParents([...expandedParents, value]);
    }
  };

  // const hasCurrentFirstTherapy = () => {
  //   return selectedOptions.some((selected) =>
  //     selected.label.includes("Current - 1st therapy")
  //   );
  // };

  // const isOptionDisabled = (option, parentLabel) => {
  //   if (noTherapies && parentLabel === "Therapies") return true;

  //   if (["First", "Second", "Third"].includes(option.label)) {
  //     return selectedOptions.some(
  //       (selected) =>
  //         selected.label === option.label &&
  //         selected.parent.startsWith("Therapies_Current")
  //     );
  //   }

  //   if (option.label === "Simultaneously Started") {
  //     return selectedOptions.some(
  //       (selected) =>
  //         selected.label === "Simultaneously Started" &&
  //         selected.parent.startsWith("Therapies_Current")
  //     );
  //   }

  // Disable options within the same medicine if a therapy is selected
  // if (parentLabel.includes("Therapies_Current")) {
  //   const selectedTherapies = selectedOptions.filter((selected) =>
  //     ["First therapy", "Second therapy", "Third therapy"].includes(
  //       selected.parent
  //     )
  //   );

  //   if (
  //     selectedTherapies.some((selected) => selected.parent !== option.parent)
  //   ) {
  //     return true;
  //   }
  // }

  // Disable all other "Main therapy" options if one is selected
  // if (option.label === "Main therapy") {
  //   return selectedOptions.some(
  //     (selected) => selected.label === "Main therapy"
  //   );
  // }

  // // Disable 2nd/3rd therapy if Current 1st therapy is selected
  // if (
  //   hasCurrentFirstTherapy() &&
  //   ["2nd therapy", "3rd therapy"].includes(option.label)
  // ) {
  //   return true;
  // }

  // return false;
  // };

  // const handleToggleClick = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const handleApply = () => {
    const newSelectedOptions = allClicks;

    const vals = {
      selectedOptions: selectedOptions,
      newSelectedOptions: newSelectedOptions,
    };

    onSelect(vals);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <Form>{renderOptions(options)}</Form>
      </div>
    </>
  );
};

export default MultiCascader;
