import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditGeneralModal = ({ show, handleClose, generalData, setGeneralData, handleSubmit, hasChanges, handleInputChange }) => {


  // Render the modal with fields prefilled from `generalData`
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header style={{ borderBottom: 'none' }} closeButton>
        <Modal.Title>Edit General Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 'bold' }}>Name</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={generalData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              placeholder="Enter name"
              style={{ padding: '10px', borderRadius: '5px' }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 'bold' }}>Gender</Form.Label>
            <Form.Control
              as="select"
              value={generalData.gender}
              onChange={(e) => handleInputChange('gender', e.target.value)}

              style={{ padding: '10px', borderRadius: '5px' }}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non Binary">Non Binary</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 'bold' }}>Smoking</Form.Label>
            <Form.Control
              as="select"
              value={generalData.smoking}
              // onChange={(e) => setGeneralData((prev) => ({ ...prev, smoking: e.target.value }))}
              onChange={(e) => handleInputChange('smoking', e.target.value)}
              style={{ padding: '10px', borderRadius: '5px' }}
            >
              <option value="">Select Status</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Form.Control>

          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label style={{ fontWeight: 'bold' }}>Birth Year</Form.Label>
            <Form.Control
              type="number"
              value={generalData.birthYear}
              onChange={(e) => handleInputChange('birthYear', e.target.value)}
              placeholder="Enter birth year"
              style={{ padding: '10px', borderRadius: '5px' }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 'none' }}>
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{ padding: '10px 20px', borderRadius: '5px' }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!hasChanges} // Disable button unless there are changes
          style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#5b99a6', border: 'none' }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGeneralModal;
