import React, { useEffect, useState } from "react";
import "./BuyerInfo.scss";
import { Formik, useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Loader } from "../../Loader";

import { useSelector } from "react-redux";

import * as Yup from "yup";
import { Container, Col, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";

import {
  useSubmitBuyerInfoFormsMutation,
  //   useSubmitGeneralInfoFormsMutation,
  //   useGetSellerDataQuery,
  useGetBuyerDataQuery,
} from "../../../store/api";
// import Modal from "react-modal";

const BuyerInfo = () => {
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const value = location?.state?.value;
  const [userHere, setUserHere] = useState(true);

  const userRole = useSelector((state) => state.auth?.user?.user?.role);
  const user = useSelector((state) => state.auth?.user?.user);

  useEffect(() => {
    setUserHere(userRole);
  }, [userRole]);

  const [
    SubmitBuyerInfo,
    {
      data: buyerInfoData,
      isSuccess: buyerInfoIsSuccess,
      isError: buyerInfoIsError,
      error: buyerInfoError,
    },
  ] = useSubmitBuyerInfoFormsMutation();

  const {
    data: secondformData,
    refetch: secondformrefetch,
    isSuccess: secondformsuccess,
    isError: secondformerror,
    isLoading: secondformloading,
  } = useGetBuyerDataQuery();

  const [secondform, setSecondForm] = useState({
    email: "",
    institutionName: "",
    department: "",
  });

  useEffect(() => {
    if (buyerInfoIsSuccess) {
      toast.success("Form Submitted successfully");
      secondformrefetch();
    } else if (buyerInfoIsError) {
      toast.error(buyerInfoIsError?.buyerInfoData?.message);
    }
  }, [buyerInfoIsSuccess, buyerInfoIsError]);

  useEffect(() => {
    if (secondformsuccess) {
      FormikGeneral.setValues(() => ({
        email: secondformData?.email || "",
        institutionName: secondformData?.institution.name || "",
        department: secondformData?.institution?.department || "",
      }));
    }
  }, [secondformsuccess, secondformData]);

  const buyerValidationSchema = {
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    institutionName: yup.string().required("Institution is required"),
    department: yup.string().required("Department is required"),
  };

  const initialValues = {
    email: secondformData?.email || "",
    institutionName: secondformData?.institution?.name || "",
    department: secondformData?.institution?.department || "",
  };

  const onSubmitBuyerInfo = async (values, { setSubmitting, setValues }) => {
    setSubmitting(false);
    try {
      await SubmitBuyerInfo(values);
    } catch (buyerInfoError) {
      console.buyerInfoError("Buyer error occurred:", buyerInfoError);
    }
  };

  const FormikGeneral = useFormik({
    initialValues,
    buyerValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onSubmitBuyerInfo(values, { setSubmitting });
    },
  });

  return (
    <>
      <div className="buyerInfoForm">
        <Form
          className="buyerForm"
          style={{ paddingTop: "40px" }}
          onSubmit={FormikGeneral.handleSubmit}
        >
          <div className="buyerinfo">
            <h2 className="userName">
              {user?.username ? user?.username : user}
            </h2>
            <div className="form2">
              {secondformloading ? (
                <Loader style={{ padding: "150px" }} />
              ) : (
                <>
                  <Col xs={12}>
                    <Form.Group className="form-group mb-3 formFields">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Enter Your Email"
                        value={FormikGeneral.values?.email}
                        onChange={FormikGeneral.handleChange}
                        onBlur={FormikGeneral.handleBlur}
                      />

                      {FormikGeneral.touched.email &&
                      FormikGeneral.errors.email ? (
                        <div className="error">
                          {FormikGeneral.errors.email}
                        </div>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                  <Form.Group className="form-group mb-3 formFields">
                                <Form.Label>Institution</Form.Label>
                                <Form.Select
                                  id="institutionName"
                                  name="institutionName"
                                  value={FormikGeneral.values?.institutionName}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                >
                                  <option value="" disabled>Select institution name</option>

                                  <option value="Isala Zwolle">
                                  Isala Zwolle
                                  </option>
                                  <option value="UCL Hospital">
                                    UCL Hospital
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.institutionName &&
                      FormikGeneral.errors.institutionName ? (
                        <div className="error">
                          {FormikGeneral.errors.institutionName}
                        </div>
                      ) : null}
                              </Form.Group>
                  </Col>
                  <Col xs={12}>
                  <Form.Group className="form-group mb-3 formFields">
                                <Form.Label>Department</Form.Label>
                                <Form.Select
                                  id="department"
                                  name="department"
                                  value={FormikGeneral.values?.department}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                >
                                  <option value="" disabled>Select department</option>

                                  <option value="Neurology">
                                    Neurology
                                  </option>
                                  <option value="IT">
                                    IT
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.department &&
                      FormikGeneral.errors.department ? (
                        <div className="error">
                          {FormikGeneral.errors.department}
                        </div>
                      ) : null}
                  </Form.Group>
                   
                  </Col>
                  <div className="submitBuyerInfo">
                    <button className="buyerSubmit" type={"submit"}>
                      Submit
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default BuyerInfo;
