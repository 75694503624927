import React, { useMemo } from "react";
import { Image } from "react-bootstrap";
import "./style.scss";
import { IoMdArrowRoundBack } from "react-icons/io";
import CustomTabs from "../../components/customtab/CustomTab";

import { useNavigate } from "react-router-dom";

import SignupComponent from "./SingupComponent";
import { Images } from '../../assets/images';


export default React.memo(function Signup(props) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Navigate to the previous page
  };


  const sellersTab = <SignupComponent isSeller={true} isBuyer={false} />;
  const buyersTab = <SignupComponent isBuyer={true} isSeller={false} />;

  const tabs = useMemo(
    () => [
      { label: "SHARE", content: sellersTab },
      { label: "REQUEST", content: buyersTab },
    ],
    []
  );

  return (
    <>
      <div className="login_body">


        <div className="backarrow-signup" style={{ marginBottom: '2rem' }} >
          <IoMdArrowRoundBack className="bckArrow" size={20} onClick={handleClick} />
          < img src={Images.logo_} alt="LiberDat" className="logoimage" />
        </div>




        <div className="container1">
          <CustomTabs
            color={"black"}
            activeColor={"#5B99A6"}
            inactiveColor={"black"}
            tabItems={tabs}
          />
        </div>

        <p>
          <img
            src={Images.liberdaticon}

            alt="LiberDat"
            className="lowerimage"
          />
        </p>

        {/* </Form> */}

      </div>

    </>
  );
});
