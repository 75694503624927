import React, { useEffect, useState, useRef } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import "./generalinfo.scss";
import { useFormik } from "formik";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../../routes/RouteNames";
import * as yup from "yup";
import { Loader } from "../../Loader";
import dataItemsforGeneralInfo from "./dataItemsforGeneralInfo";
import MultiCascaderForGeneralForm from "./MultiCascaderForGeneralForm";
import Modal from "react-modal";

import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Badge,
  FormCheck,
} from "react-bootstrap";
import toast from "react-hot-toast";

import {
  useCreateBidMutation,
  useSubmitBuyerInfoFormsMutation,
  useSubmitGeneralInfoFormsMutation,
  useGetSellerDataQuery,
  useGetBuyerDataQuery,
  useAddTherapyMutation,
  useUpdateTherapyMutation,
} from "../../../store/api";

import CustomButton from "../../custombutton/CustomButton";

const GeneralInfo = () => {
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [generalOpen, setGeneralOpen] = useState(false);
  const [clinicalOpen, setClinicalOpen] = useState(false);
  const [healthOpen, setHealthOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const value = location?.state?.value;

  const [
    SubmitGeneralInfo,
    {
      data: generalInfoData,
      isSuccess: generalInfoIsSuccess,
      isError: generalInfoIsError,
      error: generalInfoError,
    },
  ] = useSubmitGeneralInfoFormsMutation();
  const [
    AddTherapy,
    {
      isSuccess: AddTherapySuccess,
      data: AddTherapyData,
      isError: AddTherapyError,
      error: AddTherapyerror,
    },
  ] = useAddTherapyMutation();

  const handleAddTherapyData = () => {
    const formattedData = {
      therapy: selectedTherapyData,
    };
    AddTherapy(formattedData);

    setIsModal2Open(false);
  };

  useEffect(() => {
    if (AddTherapySuccess) {
      toast.success("Therapy data added successfully");
      firstformrefetch();
    } else if (AddTherapyError) {
      toast.error("Therapy data was not added");
    }
  }, [AddTherapySuccess, AddTherapyError]);

  const handleUpdateTherapy = () => {
    SetIsUpdateTherapyOpen(!isUpdateTherapyOpen);
  };

  const handleUpdateTherapyData = () => {
    // formikForEndDate?.values?.enddateForTherapiesCurrent
    const updatedData = updatedTherapyData.map((therapy) => {
      return {
        ...therapy,
        endDate: therapy.endDate,
      };
    });

    const formattedData2 = {
      therapy: updatedData,
    };

    UpdateTherapy(formattedData2);
    SetIsUpdateTherapyOpen(false);
  };

  const [
    UpdateTherapy,
    {
      isSuccess: UpdateTherapySuccess,

      isError: UpdateTherapyError,
      error: updateTherapyerror,
    },
  ] = useUpdateTherapyMutation();

  useEffect(() => {
    if (UpdateTherapySuccess) {
      toast.success("Therapy data updated successfully");
      // firstformrefetch();
    } else if (UpdateTherapyError) {
      toast.error("Therapy data was not updated", updateTherapyerror);
    }
  }, [UpdateTherapySuccess, UpdateTherapyError]);

  const {
    data: firstformdata,
    refetch: firstformrefetch,
    isSuccess: firstformsuccess,
    isError: firstformerror,
    isLoading: firstformloading,
  } = useGetSellerDataQuery();

  const [
    SubmitBuyerInfo,
    {
      data: buyerInfoData,
      isSuccess: buyerInfoIsSuccess,
      isError: buyerInfoIsError,
      error: buyerInfoError,
    },
  ] = useSubmitBuyerInfoFormsMutation();

  const {
    data: secondformData,
    refetch: secondformrefetch,
    isSuccess: secondformsuccess,
    isError: secondformerror,
    isLoading: secondformloading,
  } = useGetBuyerDataQuery();

  const toggleGeneral = () => {
    setGeneralOpen(!generalOpen);
    setButtonClicked(true);
    if (clinicalOpen || healthOpen) {
      setClinicalOpen(false);
      setHealthOpen(false);
    }
  };

  const toggleClinical = () => {
    setClinicalOpen(!clinicalOpen);
    setButtonClicked(true);
    if (generalOpen || healthOpen) {
      setGeneralOpen(false);
      setHealthOpen(false);
    }
  };

  const toggleHealth = () => {
    setHealthOpen(!healthOpen);
    setButtonClicked(true);
    if (generalOpen || clinicalOpen) {
      setGeneralOpen(false);
      setClinicalOpen(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isUpdateTherapyOpen, SetIsUpdateTherapyOpen] = useState(false);

  const handleAddTherapy = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [Healthy, setHealthy] = useState(firstformdata?.Healthy || false);

  const [isCheck, setIsCheck] = useState(true);

  const handleCheckboxChange = (e) => {
    setHealthy(e.target.checked);
  };
  const [userHere, setUserHere] = useState(true);

  const userRole = useSelector((state) => state.auth?.user?.user?.role);
  const user = useSelector((state) => state.auth?.user?.user);

  // const [secondform, setSecondForm] = useState({
  //   buyerEmail: "",
  //   buyerInstitution: "",
  //   department: "",
  // });
  // const [validateForm, setValidateForm] = useState({
  //   email: "",
  //   identifier: "",
  // });

  useEffect(() => {
    setUserHere(userRole);
  }, [userRole]);

  //-----------------------------------------sellerform
  useEffect(() => {
    if (generalInfoIsSuccess) {
      toast.success("Form Submitted successfully");
    } else if (generalInfoIsError) {
      toast.error(generalInfoIsError?.generalInfoData?.message);
    }
  }, [generalInfoIsSuccess, generalInfoIsError]);

  //---------------------------------------buyerForm
  // useEffect(() => {
  //   if (buyerInfoIsSuccess) {
  //     toast.success("Form Submitted successfully");
  //     secondformrefetch();
  //   } else if (buyerInfoIsError) {
  //     toast.error(buyerInfoIsError?.buyerInfoData?.message);
  //   }
  // }, [buyerInfoIsSuccess, buyerInfoIsError]);

  //-----------------------------useeffect for seller
  useEffect(() => {
    const rawDate = firstformdata?.clinical?.dateOfDiagnosis || "";
    const rawDateFordateOfAnalysis =
      firstformdata?.clinical?.dateOfAnalysis || "";

    const dateObject = new Date(rawDate);
    const dateObjectFordateOfAnalysis = new Date(rawDateFordateOfAnalysis);

    const formattedDate = `${dateObject.getFullYear()}-${(
      dateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObject.getDate().toString().padStart(2, "0")}`;

    const formattedDateFordateOfAnalysis = `${dateObjectFordateOfAnalysis.getFullYear()}-${(
      dateObjectFordateOfAnalysis.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObjectFordateOfAnalysis
        .getDate()
        .toString()
        .padStart(2, "0")}`;

    if (firstformsuccess) {
      FormikGeneral.setValues({
        // isUpdate: firstformdata?.general.isUpdate || "",
        email: firstformdata?.general?.email || "",
        identifier: firstformdata?.general?.identifier || "",
        birthYear: firstformdata?.general?.birthYear || "",
        gender: firstformdata?.general?.gender || "",
        postalCode: firstformdata?.general?.postalCode || "",
        country: firstformdata?.general?.country || "",
        race: firstformdata?.general?.race || "",
        livingSituation: firstformdata?.general?.livingSituation || "",
        annualHouseHoldIncome:
          firstformdata?.general?.annualHouseHoldIncome || "",
        educationLevel: firstformdata?.general?.educationLevel || "",
        healthInsurance: firstformdata?.general?.healthInsurance || "",
        employmentStatus: firstformdata?.general?.employmentStatus || "",
        diagnosis: firstformdata?.clinical?.diagnosis || "",
        dateOfDiagnosis: formattedDate || "",

        institution: firstformdata?.clinical?.institution || "",
        analysis: firstformdata?.clinical?.analysis || [],
        dateOfAnalysis: formattedDateFordateOfAnalysis || "",
        height: firstformdata?.healthBehavior?.height || "",
        weight: firstformdata?.healthBehavior?.weight || "",
        smoking: firstformdata?.healthBehavior?.smoking === true ? true : false,
        alcoholAbuse:
          firstformdata?.healthBehavior?.alcoholAbuse === true ? true : false,
      });

      if (firstformdata?.Healthy !== undefined) {
        setHealthy(firstformdata.Healthy);
      }
    }
  }, [firstformsuccess, firstformdata]);

  //-----------------------------useeffect for buyer
  // useEffect(() => {
  //   if (secondformsuccess) {
  //     FormikGeneral.setValues(() => ({
  //       buyerEmail: secondformData?.buyerEmail || "",
  //       buyerInstitution: secondformData?.institution || "",
  //       department: secondformData?.department || "",
  //     }));
  //   }
  // }, [secondformsuccess, secondformData]);
  //----------------------------useeffect for MODAL
  useEffect(() => {
    if (secondformsuccess) {
      FormikGeneral.setValues(() => ({
        email: secondformData?.email || "",
        buyerInstitution: secondformData?.institution || "",
        department: secondformData?.department || "",
      }));
    }
  }, [secondformsuccess, secondformData]);

  const startYear = 1958;
  const endYear = 2007;

  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const validationSchema = Yup.object().shape({
    // birthYear: Yup.number().required('Birth Year is required'),
    // gender: Yup.string().required('Gender is required'),
    postalcode: Yup.string().max(4, "Postal Code must be exactly 4 characters"),
  });
  const modalValidationSchema = {
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    identifier: yup.string().required("Identifier is required"),
  };
  const initialValues = {
    // isUpdate: value
    //   ? value?.general?.isUpdate
    //   : firstformdata?.general?.isUpdate || "",
    email: value ? value?.general?.email : firstformdata?.general?.email || "",
    identifier: value
      ? value?.general?.identifier
      : firstformdata?.general?.identifier || "",
    //General
    birthYear: value
      ? value?.general?.birthYear
      : firstformdata?.general?.birthYear || "",
    gender: value
      ? value?.general?.gender
      : firstformdata?.general?.gender || "",
    country: value
      ? value?.general?.country
      : firstformdata?.general?.country || "",
    postalCode: value
      ? value?.general?.postalCode
      : firstformdata?.general?.postalCode || "",
    race: value ? value?.general?.race : firstformdata?.general?.race || "",
    livingSituation: value
      ? value?.general?.livingSituation
      : firstformdata?.general?.livingSituation || "",
    annualHouseHoldIncome: value
      ? value?.general?.annualHouseHoldIncome
      : firstformdata?.general?.annualHouseHoldIncome || "",
    educationLevel: value
      ? value?.general?.educationLevel
      : firstformdata?.general?.educationLevel || "",
    healthInsurance: value
      ? value?.general?.healthInsurance
      : firstformdata?.general?.healthInsurance || "",
    employmentStatus: value
      ? value?.general?.employmentStatus
      : firstformdata?.general?.employmentStatus || "",
    //Clinical
    diagnosis: value
      ? value?.clinical?.diagnosis
      : firstformdata?.clinical?.diagnosis || "",
    dateOfDiagnosis: value
      ? value?.clinical?.dateOfDiagnosis
      : firstformdata?.clinical?.dateOfDiagnosis || "",
    institution: value
      ? value?.clinical?.institution
      : firstformdata?.clinical?.institution || "",
    analysis: value
      ? value?.clinical?.analysis
      : firstformdata?.clinical?.analysis || [],
    dateOfAnalysis: value
      ? value?.clinical?.dateOfDiagnosis
      : firstformdata?.clinical?.dateOfAnalysis || "",
    //Therapy
    startdateForTherapiesPreviousLowerLimit: "",
    startdateForTherapiesPreviousUpperLimit: "",
    enddateForTherapiesPreviousLowerLimit: "",
    enddateForTherapiesPreviousUpperLimit: "",

    intervalCurrentTherapyAtLeast: "",
    numberOfCurrentTherapyAtLeast: "",

    intervalCurrentTherapyMaximum: "",
    numberOfCurrentTherapyMaximum: "",

    intervalPreviousTherapyAtLeast: "",
    numberOfPreviousTherapyAtLeast: "",
    intervalForPreviousTherapyMaximum: "",
    numberOfPreviousTherapyMaximum: "",

    startdateForTherapiesCurrent: "",
    enddateForTherapiesCurrent: "",
    //Health Behaviour
    height: value
      ? value?.healthBehavior?.height
      : firstformdata?.healthBehavior?.height || "",
    weight: value
      ? value?.healthBehavior?.weight
      : firstformdata?.healthBehavior?.weight || "",
    smoking: value
      ? value?.healthBehavior?.smoking
      : firstformdata?.healthBehavior?.smoking === true
        ? true
        : false,
    alcoholAbuse: value
      ? value?.healthBehavior?.alcoholAbuse
      : firstformdata?.healthBehavior?.alcoholAbuse === true
        ? true
        : false,
    // buyerEmail: secondformData?.buyer?.email || "",
    // buyerInstitution: secondformData?.buyer?.institution || "",
    // department: secondformData?.buyer?.department || "",
  };

  const options = [
    {
      label: "Genetic",
      options: [{ value: "HLA-DRB1*15:01", label: "HLA-DRB1*15:01" }],
    },
    {
      label: "Radiology",
      options: [{ value: "Radiology opt1", label: "Radiology opt1" }],
    },

    { label: "Lab", options: [{ value: "Lab opt1", label: "Lab opt1" }] },
  ];
  //------------------------------------------------onSubmitBuyerInfo
  // const onSubmitBuyerInfo = async (values, { setSubmitting, setValues }) => {
  //   setSubmitting(false);
  //   try {
  //     await SubmitBuyerInfo(values);
  //   } catch (buyerInfoError) {
  //     console.buyerInfoError("Buyer error occurred:", buyerInfoError);
  //   }
  // };
  //------------------------------------------------onSubmitSociodemographic
  const onSubmitSociodemographic = async (
    values,
    { setSubmitting, setValues }
  ) => {
    setSubmitting(false);

    try {
      await SubmitGeneralInfo(values);
    } catch (generalInfoError) {
      console.generalInfoError("An error occurred:", generalInfoError);
      // Handle the error, show a toast, or perform other actions
    }
  };
  //----------------------------------------------onSubmitHealthBehaviour
  const onSubmitHealthBehaviour = async (
    values,
    { setSubmitting, setValues }
  ) => {
    setSubmitting(false);

    try {
      await SubmitGeneralInfo(values);
    } catch (generalInfoError) {
      // Handle the error, show a toast, or perform other actions
    }
  };
  //-------------------------------------------onSubmitClinicalCharacteristics

  const onSubmitClinicalCharacteristics = async (values, { setSubmitting }) => {
    setSubmitting(false);

    try {
      if (Healthy) {
        const values = {
          Healthy: false,
        };

        await SubmitGeneralInfo(values);
        firstformrefetch();
      } else {
        await SubmitGeneralInfo(values);
        firstformrefetch();
      }
    } catch (generalInfoError) {
      // Handle errors appropriately
    }
  };

  const getValidationSchema = (Healthy) => {
    if (Healthy) {
      // If the user is healthy, return an empty schema (no validation)
      return Yup.object().shape({});
    } else {
      // If the user is not healthy, return the original validation for dateOfDiagnosis
      return Yup.object().shape({
        dateOfDiagnosis: Yup.date()
          .required("diagnosis Date is required")
          .typeError("diagnosis Date is required"),
      });
    }
  };

  const FormikGeneral = useFormik({
    initialValues,
    validationSchema,
    modalValidationSchema,
    // buyerValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onSubmitSociodemographic(values, { setSubmitting });
    },
  });
  // const [general, setGeneral] = useState(false);
  // const [clinical, setClinical] = useState(false);
  // const [health, setHealth] = useState(false);

  // const openGeneral = () => {
  //   setGeneral(!general);
  //   setClinical(false);
  //   setHealth(false);
  //   setDropDownGeneral(!dropDownGeneral);
  // };
  // const openClinical = () => {
  //   setClinical(!clinical);
  //   setGeneral(false);
  //   setHealth(false);
  //   setDropDownClinical(!dropDownClinical);
  // };
  // const openHealth = () => {
  //   setHealth(!health);
  //   setGeneral(false);
  //   setClinical(false);
  //   setDropDownHealth(!dropDownHealth);
  // };

  const [selectedOption, setSelectedOption] = useState("");
  // const [dropDownGeneral, setDropDownGeneral] = useState(false);
  // const [dropDownClinical, setDropDownClinical] = useState(false);
  // const [dropDownHealth, setDropDownHealth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // console.log("ISUPDATE", FormikGeneral.isUpdate);
  // const [emailModal, setEmailModal] = useState("");
  // const [identifierModal, setIdentifierModal] = useState("");

  // const handleEmailChange = (e) => {
  //   setEmailModal(e.target.value);
  // };
  // const handleIdentifierChange = (e) => {
  //   setIdentifierModal(e.target.value);
  // };
  const [selectedOptionPreviousTherapy, setSelectedOptionPreviousTherapy] =
    useState(null);

  const [selectedOptionCurrentTherapy, setSelectedOptionCurrentTherapy] =
    useState(null);

  const handleCheckbox = (event) => {
    const { id } = event.target;

    setSelectedOptionPreviousTherapy(null);

    setSelectedOptionPreviousTherapy(id);
  };
  const handleCheckboxCurrentTherapy = (event) => {
    const { id } = event.target;

    setSelectedOptionCurrentTherapy(null);

    setSelectedOptionCurrentTherapy(id);
  };
  const [
    createBidMutation,
    { isSuccess: createBidSuccess, isError: bidCreateError },
  ] = useCreateBidMutation();
  const onSubmit = async (values) => {
    console.log("values>>>>>>>>", values);
    if (!user && user?.role === "seller") {
      toast.error("You are not a Buyer");
    } else {
      const valuesToSend = {
        dataItem: {
          specifiedValues: selectedValues || undefined,
          selectedValues: selectedTherapyData?.newSelectedOptions || undefined,
          specify: {
            currentDates: {
              start: {
                to: values.startdateForTherapiesCurrent || undefined,
                from: values.enddateForTherapiesCurrent || undefined,
              },

              end: true,
            },
            previousDates: {
              start: {
                to: values.startdateForTherapiesPreviousLowerLimit || undefined,
                from:
                  values.startdateForTherapiesPreviousUpperLimit || undefined,
              },
              end: {
                to: values.enddateForTherapiesPreviousLowerLimit || undefined,
                from: values.enddateForTherapiesPreviousUpperLimit || undefined,
              },
            },
            currentTime: {
              min: {
                unit: values.intervalCurrentTherapyAtLeast || undefined,
                count: values.numberOfCurrentTherapyAtLeast || undefined,
              },

              max: {
                unit: values.intervalCurrentTherapyMaximum || undefined,
                count: values.numberOfCurrentTherapyMaximum || undefined,
              },
            },

            previousTime: {
              min: {
                unit: values.intervalPreviousTherapyAtLeast || undefined,
                count: values.numberOfPreviousTherapyAtLeast || undefined,
              },

              max: {
                unit: values.intervalForPreviousTherapyMaximum || undefined,
                count: values.numberOfPreviousTherapyMaximum || undefined,
              },
            },
          },
        },
      };

      console.log("Values to send:::", valuesToSend);
      setIsOpenRequestModal(false);

      if (user) {
        createBidMutation(valuesToSend, { fixedCacheKey: "create-bid" });
      } else {
        // Condition 2: User is not defined
        localStorage.setItem("createdBid", JSON.stringify(valuesToSend));

        setTimeout(() => {
          navigate(RouteNames.MyBids.route);
        }, 500);
      }
    }
    // resetForm();
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const initialValuesForEndDate = {
    enddateForTherapiesCurrent: "",
  };

  const formikForEndDate = useFormik({
    initialValuesForEndDate,
  });

  const [selectedTherapyData, setSelectedTherapyData] = useState([]);
  const [updatedTherapyData, setUpdatedTherapyData] = useState(
    firstformdata?.clinical?.therapy
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const therapyData = dataItemsforGeneralInfo();
  const handleSelect = (selectedTherapyData) => {
    const selectedValues = selectedTherapyData?.selectedOptions?.flatMap(
      (option) => option.value
    );
    setSelectedTherapyData(selectedTherapyData);
    setSelectedValues(selectedValues);

    setIsModalOpen(false);

    setIsModal2Open(true);
  };
  const closeModal = () => {
    setSelectedTherapyData(null);
    setIsOpenRequestModal(false);
  };
  useEffect(() => {
    setSelectedTherapyData(updatedTherapyData);
  }, [updatedTherapyData]);

  console.log(
    "firstformdata?.clinical?.therapy",
    firstformdata?.clinical?.therapy
  );

  const handleClose = () => {
    setShowModal(false);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    // Ensure two digits for month and day
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  useEffect(() => {
    const updatedSelectedTherapyData = selectedTherapyData?.map((therapy) => {
      if (therapy?.value?.includes("Current")) {
        return { ...therapy, endDate: true };
      }
      return therapy;
    });

    if (
      JSON.stringify(updatedSelectedTherapyData) !==
      JSON.stringify(selectedTherapyData)
    ) {
      setSelectedTherapyData(updatedSelectedTherapyData);
    }
  }, [selectedTherapyData]);

  return (
    <>
      <>
        <div className="generalinfo">
          <h2>{user?.username ? user?.username : user}</h2>
          <div>
            <div className="form1">
              <Container>
                {firstformloading ? (
                  <Loader style={{ padding: "150px" }} />
                ) : (
                  <Form
                    className="form-fields"
                    style={{ paddingTop: "40px" }}
                    onSubmit={FormikGeneral.handleSubmit}
                  >
                    <Row>
                      {/* -------------------------------------General-------------------------*/}
                      <div>
                        <div className="form-dropdown">
                          <p>General</p>
                          <div className="field-dropdown">
                            <button
                              className="toggle-arrow"
                              onClick={toggleGeneral}
                              type="button"
                            >
                              {generalOpen ? "▲" : "▼"}
                            </button>
                          </div>
                        </div>
                        {generalOpen && (
                          <>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Birth Year</Form.Label>
                                <Form.Select
                                  id="birthYear"
                                  name="birthYear"
                                  value={FormikGeneral.values?.birthYear}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option className="field-input" value="">
                                    Select Birth Year
                                  </option>
                                  {years.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </Form.Select>
                                {FormikGeneral.touched.birthYear &&
                                  FormikGeneral.errors.birthYear ? (
                                  <div className="error">
                                    {FormikGeneral.errors.birthYear}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select
                                  id="gender"
                                  name="gender"
                                  value={FormikGeneral.values?.gender}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">Select Your Gender</option>

                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Non-Binary">Non-Binary</option>
                                </Form.Select>
                                {FormikGeneral.touched.gender &&
                                  FormikGeneral.errors.gender ? (
                                  <div className="error">
                                    {FormikGeneral.errors.gender}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            {/* Country */}
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Country of Residence</Form.Label>
                                <Form.Select
                                  id="country"
                                  name="country"
                                  value={FormikGeneral.values?.country}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">Select Your Country</option>

                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.country &&
                                  FormikGeneral.errors.country ? (
                                  <div className="error">
                                    {FormikGeneral.errors.country}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control
                                  type="text"
                                  id="postalCode"
                                  name="postalCode"
                                  value={FormikGeneral.values?.postalCode}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  readOnly={
                                    value?.role && userRole === "buyer"
                                      ? true
                                      : false
                                  }
                                />
                                <option value="">Enter first 4 letters</option>

                                {FormikGeneral.touched.postalCode &&
                                  FormikGeneral.errors.postalCode ? (
                                  <div className="error">
                                    {FormikGeneral.errors.postalCode}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Race/Ethnicity</Form.Label>
                                <Form.Select
                                  id="race"
                                  name="race"
                                  value={FormikGeneral.values?.race}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">Select Ethnicity</option>
                                  <option value="White">White</option>
                                  <option value="Black/African American">
                                    Black/African American
                                  </option>
                                  <option value="Asian">Asian</option>
                                  <option value="Hispanic/Latino">
                                    Hispanic/Latino
                                  </option>
                                  <option value="Native Hawaiian/Other Pacific Islander">
                                    Native Hawaiian/Other Pacific Islander
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.race &&
                                  FormikGeneral.errors.race ? (
                                  <div className="error">
                                    {FormikGeneral.errors.race}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Living Situation</Form.Label>
                                <Form.Select
                                  id="livingSituation"
                                  name="livingSituation"
                                  value={FormikGeneral.values?.livingSituation}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">Select Living Status</option>
                                  <option value="Married/co-habiting">
                                    Married/co-habiting
                                  </option>
                                  <option value="Never married/divorced/widowed">
                                    Never married/divorced/widowed
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.livingSituation &&
                                  FormikGeneral.errors.livingSituation ? (
                                  <div className="error">
                                    {FormikGeneral.errors.livingSituation}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Annual Household Income</Form.Label>
                                <Form.Select
                                  id="annualHouseHoldIncome"
                                  name="annualHouseHoldIncome"
                                  value={
                                    FormikGeneral.values?.annualHouseHoldIncome
                                  }
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">Select Income $</option>
                                  <option value="Below 15,000">
                                    Below 15,000
                                  </option>
                                  <option value="15,000 – 30,000">
                                    15,000 – 30,000
                                  </option>
                                  <option value="30,000 – 50,000">
                                    30,000 – 50,000
                                  </option>
                                  <option value="50,000 – 100,000">
                                    50,000 – 100,000
                                  </option>
                                  <option value="Above 100,000">
                                    Above 100,000
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.annualHouseHoldIncome &&
                                  FormikGeneral.errors.annualHouseHoldIncome ? (
                                  <div className="error">
                                    {FormikGeneral.errors.annualHouseHoldIncome}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>
                                  Highest Level of Education Reached
                                </Form.Label>
                                <Form.Select
                                  id="educationLevel"
                                  name="educationLevel"
                                  value={FormikGeneral.values?.educationLevel}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">
                                    Select Education level
                                  </option>
                                  <option value="Below 12 years">
                                    Below 12 years
                                  </option>
                                  <option value="High school diploma">
                                    High school diploma
                                  </option>
                                  <option value="Associate’s or technical degree">
                                    Associate’s or technical degree
                                  </option>
                                  <option value="Bachelor’s degree">
                                    Bachelor’s degree
                                  </option>
                                  <option value="Post-Graduate">
                                    Post-Graduate
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.educationLevel &&
                                  FormikGeneral.errors.educationLevel ? (
                                  <div className="error">
                                    {FormikGeneral.errors.educationLevel}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Health Insurance Status</Form.Label>
                                <Form.Select
                                  id="healthInsurance"
                                  name="healthInsurance"
                                  value={FormikGeneral.values?.healthInsurance}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">
                                    Select Insurance Status
                                  </option>
                                  <option value="Private">Private</option>
                                  <option value="Public">Public</option>
                                  <option value="None">None</option>
                                </Form.Select>
                                {FormikGeneral.touched.healthInsurance &&
                                  FormikGeneral.errors.healthInsurance ? (
                                  <div className="error">
                                    {FormikGeneral.errors.healthInsurance}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group className="form-group mb-3">
                                <Form.Label>Employment Status</Form.Label>
                                <Form.Select
                                  id="employmentStatus"
                                  name="employmentStatus"
                                  value={FormikGeneral.values?.employmentStatus}
                                  onChange={FormikGeneral.handleChange}
                                  onBlur={FormikGeneral.handleBlur}
                                  disabled={value?.role && userRole === "buyer"}
                                >
                                  <option value="">
                                    Select Employment Status
                                  </option>
                                  <option value="employed">Employed</option>
                                  <option value="not-employed">
                                    Not Employed
                                  </option>
                                </Form.Select>
                                {FormikGeneral.touched.employmentStatus &&
                                  FormikGeneral.errors.employmentStatus ? (
                                  <div className="error">
                                    {FormikGeneral.errors.employmentStatus}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Col>
                          </>
                        )}
                      </div>

                      {/* -------------------------------------Clinical----------------------- */}
                      <div>
                        <div className="form-dropdown">
                          <p>Clinical</p>
                          <div className="field-dropdown">
                            <button
                              className="toggle-arrow"
                              onClick={toggleClinical}
                              type="button"
                            >
                              {clinicalOpen ? "▲" : "▼"}
                            </button>
                          </div>
                        </div>
                        {clinicalOpen && (
                          <>
                            {isCheck && (
                              <Col xs={12}>
                                <Form.Group className="form-group mb-3">
                                  <Form.Label>Diagnosis:</Form.Label>
                                  <Form.Select
                                    id="diagnosis"
                                    name="diagnosis"
                                    value={FormikGeneral.values?.diagnosis}
                                    onChange={FormikGeneral.handleChange}
                                    disabled={
                                      value?.role && userRole === "buyer"
                                    }
                                  >
                                    <option value="">Select Diagnosis</option>
                                    <option value="Healthy">Healthy</option>
                                    <option value="Multiple Sclerosis – Relapsing Remitting">
                                      Multiple Sclerosis – Relapsing Remitting
                                    </option>
                                    <option value="Multiple Sclerosis – Secondary Progressive">
                                      Multiple Sclerosis – Secondary Progressive
                                    </option>
                                    <option value="Multiple Sclerosis – Primary Progressive">
                                      Multiple Sclerosis – Primary Progressive
                                    </option>
                                  </Form.Select>
                                  {FormikGeneral.touched.diagnosis &&
                                    FormikGeneral.errors.diagnosis ? (
                                    <div className="error">
                                      {FormikGeneral.errors.diagnosis}
                                    </div>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            )}

                            <>
                              {FormikGeneral.values?.diagnosis ===
                                "Healthy" ? null : (
                                <>
                                  <Col xs={12}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>
                                        {" "}
                                        Date of Diagnosis:{" "}
                                      </Form.Label>
                                      <Form.Control
                                        type="date"
                                        id="dateOfDiagnosis"
                                        name="dateOfDiagnosis"
                                        value={
                                          FormikGeneral.values?.dateOfDiagnosis
                                        }
                                        onChange={FormikGeneral.handleChange}
                                        onBlur={FormikGeneral.handleBlur}
                                        disabled={
                                          value?.role && userRole === "buyer"
                                        }
                                      />
                                      {FormikGeneral.touched.dateOfDiagnosis &&
                                        FormikGeneral.errors.dateOfDiagnosis ? (
                                        <div className="error">
                                          {FormikGeneral.errors.dateOfDiagnosis}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                  <Col xs={12}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Institution</Form.Label>
                                      <Form.Select
                                        id="institution"
                                        name="institution"
                                        value={
                                          FormikGeneral.values?.institution
                                        }
                                        onChange={FormikGeneral.handleChange}
                                        onBlur={FormikGeneral.handleBlur}
                                        disabled={
                                          value?.role && userRole === "buyer"
                                        }
                                      >
                                        <option value="">
                                          Select Institution
                                        </option>
                                        <option value="Company 1">
                                          Company 1
                                        </option>
                                        <option value="Company 2">
                                          Company 2
                                        </option>
                                        <option value="Company 3">
                                          Company 3
                                        </option>
                                      </Form.Select>
                                      {FormikGeneral.touched.institution &&
                                        FormikGeneral.errors.institution ? (
                                        <div className="error">
                                          {FormikGeneral.errors.institution}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Col>

                                  <Col xs={12}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Anlyses</Form.Label>
                                      <Select
                                        id="analysis"
                                        name="analysis"
                                        disabled={
                                          value?.role && userRole === "buyer"
                                        }
                                        options={options}
                                        isMulti
                                        value={FormikGeneral?.values?.analysis?.map(
                                          (value) =>
                                            options
                                              .flatMap(
                                                (optionGroup) =>
                                                  optionGroup.options
                                              )
                                              .find(
                                                (option) =>
                                                  option.value === value
                                              )
                                        )}
                                        onChange={(selectedOptions) => {
                                          const selectedValues =
                                            selectedOptions?.map(
                                              (option) => option.value
                                            );

                                          FormikGeneral.setFieldValue(
                                            "analysis",
                                            selectedValues
                                          );
                                        }}
                                        onBlur={FormikGeneral.handleBlur}
                                        closeMenuOnSelect={false}
                                      />

                                      {FormikGeneral.touched.analysis &&
                                        FormikGeneral.errors.analysis ? (
                                        <div className="error">
                                          {FormikGeneral.errors.analysis}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Col>

                                  <Col xs={12}>
                                    <Form.Group className="form-group mb-3">
                                      <Form.Label>Date of Analysis</Form.Label>
                                      <Form.Control
                                        type="date"
                                        id="dateOfAnalysis"
                                        name="dateOfAnalysis"
                                        value={
                                          FormikGeneral.values?.dateOfAnalysis
                                        }
                                        onChange={FormikGeneral.handleChange}
                                        onBlur={FormikGeneral.handleBlur}
                                        disabled={
                                          value?.role && userRole === "buyer"
                                        }
                                      />

                                      {FormikGeneral.touched.dateOfAnalysis &&
                                        FormikGeneral.errors.dateOfAnalysis ? (
                                        <div className="error">
                                          {FormikGeneral.errors.dateOfAnalysis}
                                        </div>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                  <Row>
                                    <Col xs={12}>
                                      <Form.Group className="form-group mb-3">
                                        <Form.Label>Therapy</Form.Label>
                                        <div className="therapyButtons">
                                          <Button
                                            style={{
                                              marginBottom: "38px",
                                              backgroundColor: "#5b99a6",
                                              border: "none",
                                              width: "50%",
                                            }}
                                            className="buttonBuy"
                                            onClick={handleAddTherapy}
                                          >
                                            Add Therapy
                                          </Button>

                                          <Button
                                            style={{
                                              marginBottom: "38px",
                                              backgroundColor: "#5b99a6",
                                              border: "none",
                                              width: "50%",
                                            }}
                                            className="buttonBuy"
                                            onClick={handleUpdateTherapy}
                                          >
                                            Update Therapy
                                          </Button>
                                        </div>
                                        <Modal
                                          isOpen={isModalOpen}
                                          onRequestClose={() =>
                                            setIsModalOpen(false)
                                          }
                                          ariaHideApp={false}
                                          style={{
                                            overlay: {
                                              backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            },
                                            content: {
                                              width: "50vw",
                                              margin: "auto",
                                              height: "80vh",
                                              padding: "40px",
                                            },
                                          }}
                                        >
                                          <div className="multi">
                                            <h2 style={{ textAlign: "center" }}>
                                              Add Therapy
                                            </h2>
                                            <MultiCascaderForGeneralForm
                                              options={therapyData}
                                              onSelect={handleSelect}
                                              initialSelectedData={
                                                selectedTherapyData
                                              }
                                            />
                                          </div>
                                        </Modal>

                                        <Modal
                                          isOpen={isModal2Open}
                                          onRequestClose={() =>
                                            setIsModal2Open(false)
                                          }
                                          ariaHideApp={false}
                                          style={{
                                            overlay: {
                                              backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            },
                                            content: {
                                              width: "50vw",
                                              margin: "auto",
                                              height: "80vh",
                                              padding: "40px",
                                            },
                                          }}
                                        >
                                          <h2 style={{ textAlign: "center" }}>
                                            Select dates
                                          </h2>
                                          {selectedTherapyData?.map(
                                            (therapy, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  border: "1px solid #ccc",
                                                  borderRadius: "8px",
                                                  padding: "20px",
                                                  marginBottom: "20px",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <div>{therapy.displayName}</div>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <div
                                                    style={{
                                                      flex: 1,
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <Form.Label>
                                                      Start Date
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="date"
                                                      value={therapy.startDate}
                                                      onChange={(e) => {
                                                        const startDate =
                                                          e.target.value;
                                                        const updatedTherapy = {
                                                          ...therapy,
                                                          startDate,
                                                        };
                                                        const updatedSelectedTherapyData =
                                                          selectedTherapyData.map(
                                                            (t) =>
                                                              t.value ===
                                                                therapy.value
                                                                ? updatedTherapy
                                                                : t
                                                          );
                                                        setSelectedTherapyData(
                                                          updatedSelectedTherapyData
                                                        );
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      flex: 1,
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    <Form.Label>
                                                      End Date
                                                    </Form.Label>

                                                    <Form.Control
                                                      type={
                                                        therapy.value.includes(
                                                          "Current"
                                                        )
                                                          ? "text"
                                                          : "date"
                                                      }
                                                      value={
                                                        therapy.endDate === true
                                                          ? ""
                                                          : therapy.endDate
                                                      }
                                                      placeholder={
                                                        therapy.value.includes(
                                                          "Current"
                                                        )
                                                          ? "Present"
                                                          : ""
                                                      }
                                                      onChange={(e) => {
                                                        const endDate =
                                                          e.target.value;
                                                        const date =
                                                          endDate === ""
                                                            ? null
                                                            : new Date(endDate);
                                                        const formattedEndDate =
                                                          date
                                                            ? date
                                                              .toISOString()
                                                              .slice(0, 10) +
                                                            "T00:00:00.000Z"
                                                            : "";
                                                        const updatedTherapy = {
                                                          ...therapy,
                                                          endDate:
                                                            formattedEndDate,
                                                        };
                                                        const updatedSelectedTherapyData =
                                                          selectedTherapyData.map(
                                                            (t) =>
                                                              t.value ===
                                                                therapy.value
                                                                ? updatedTherapy
                                                                : t
                                                          );
                                                        setSelectedTherapyData(
                                                          updatedSelectedTherapyData
                                                        );
                                                      }}
                                                      disabled={therapy.value.includes(
                                                        "Current"
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}

                                          <CustomButton
                                            label={"Submit"}
                                            color={"#333333"}
                                            textcolor={"white"}
                                            backgroundColor={"#333333"}
                                            height={"38px"}
                                            width="100%"
                                            onClick={handleAddTherapyData}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                          />
                                        </Modal>

                                        <Modal
                                          isOpen={isUpdateTherapyOpen}
                                          onRequestClose={() =>
                                            SetIsUpdateTherapyOpen(false)
                                          }
                                          ariaHideApp={false}
                                          style={{
                                            overlay: {
                                              backgroundColor:
                                                "rgba(0, 0, 0, 0.6)",
                                            },
                                            content: {
                                              width: "50vw",
                                              margin: "auto",
                                              height: "80vh",
                                              padding: "40px",
                                            },
                                          }}
                                        >
                                          <h2 style={{ textAlign: "center" }}>
                                            Update Therapy
                                          </h2>
                                          {firstformdata?.clinical?.therapy
                                            .filter((therapy) =>
                                              therapy.value.includes("Current")
                                            )
                                            .map((therapy, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  border: "1px solid #ccc",
                                                  borderRadius: "8px",
                                                  padding: "20px",
                                                  marginBottom: "20px",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <div>{therapy.displayName}</div>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <div
                                                    style={{
                                                      flex: 1,
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <Form.Label>
                                                      Start Date
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      value={formatDate(
                                                        therapy.startDate
                                                      )}
                                                      readOnly
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      flex: 1,
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    <Form.Label>
                                                      End Date
                                                    </Form.Label>

                                                    <Form.Control
                                                      type="date"
                                                      name={`enddateForTherapiesCurrent_${index}`}
                                                      id={`enddateForTherapiesCurrent_${index}`}
                                                      value={
                                                        updatedTherapyData &&
                                                        updatedTherapyData[
                                                          index
                                                        ]?.endDate
                                                      }
                                                      onChange={(e) => {
                                                        const updatedEndDate =
                                                          e.target.value;
                                                        // Update the endDate in the state
                                                        setUpdatedTherapyData(
                                                          (prevState) => {
                                                            const updatedData =
                                                              [...prevState]; // Create a copy of the state array
                                                            updatedData[index] =
                                                            {
                                                              ...updatedData[
                                                              index
                                                              ],
                                                              endDate:
                                                                updatedEndDate,
                                                            }; // Update the endDate for the corresponding index
                                                            return updatedData; // Return the updated state
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ))}

                                          <CustomButton
                                            label={"Submit"}
                                            color={"#333333"}
                                            textcolor={"white"}
                                            backgroundColor={"#333333"}
                                            height={"38px"}
                                            width="100%"
                                            onClick={handleUpdateTherapyData}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                          />
                                        </Modal>
                                      </Form.Group>
                                    </Col>
                                    {selectedTherapyData?.selectedOptions?.some(
                                      (item) => item?.value.includes("Previous")
                                    ) && (
                                        <Col xs={12}>
                                          <div className="therapy-dates-container">
                                            <h4 className="therapy-dates-heading">
                                              Previous therapy
                                            </h4>

                                            <Form.Group className="form-group mb-3">
                                              <FormCheck
                                                type="checkbox"
                                                id="setByDateCheckboxPreviousTherapy"
                                                name="therapyOption"
                                                label="Set by start/end date"
                                                onChange={handleCheckbox}
                                                checked={
                                                  selectedOptionPreviousTherapy ===
                                                  "setByDateCheckboxPreviousTherapy"
                                                }
                                              />
                                              <FormCheck
                                                type="checkbox"
                                                id="setByTimeCheckboxPreviousTherapy"
                                                name="therapyOption"
                                                label="Set time on therapy"
                                                onChange={handleCheckbox}
                                                checked={
                                                  selectedOptionPreviousTherapy ===
                                                  "setByTimeCheckboxPreviousTherapy"
                                                }
                                              />
                                            </Form.Group>

                                            {selectedOptionPreviousTherapy ===
                                              "setByTimeCheckboxPreviousTherapy" && (
                                                <div className="therapy-dates-container">
                                                  <Form.Label
                                                    style={{ fontWeight: "bold" }}
                                                  >
                                                    At least
                                                  </Form.Label>

                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Interval
                                                      </Form.Label>
                                                      <Form.Select
                                                        name="intervalPreviousTherapyAtLeast"
                                                        id="intervalPreviousTherapyAtLeast"
                                                        onChange={(e) => {
                                                          formik.handleChange(e);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={
                                                          formik.values
                                                            .intervalPreviousTherapyAtLeast
                                                        }
                                                      >
                                                        <option value="Days">
                                                          Days
                                                        </option>
                                                        <option value="Weeks">
                                                          Weeks
                                                        </option>
                                                        <option value="Months">
                                                          Months
                                                        </option>
                                                      </Form.Select>

                                                      {formik.touched
                                                        .intervalPreviousTherapyAtLeast &&
                                                        formik.errors
                                                          .intervalPreviousTherapyAtLeast ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .intervalPreviousTherapyAtLeast
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Number of{" "}
                                                        {formik.values.interval}
                                                      </Form.Label>
                                                      <Form.Control
                                                        id="numberOfPreviousTherapyAtLeast"
                                                        name="numberOfPreviousTherapyAtLeast"
                                                        type="number"
                                                        value={
                                                          formik.values
                                                            .numberOfPreviousTherapyAtLeast
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        onBlur={formik.handleBlur}
                                                      />
                                                      {formik.touched
                                                        .numberOfPreviousTherapyAtLeast &&
                                                        formik.errors
                                                          .numberOfPreviousTherapyAtLeast ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .numberOfPreviousTherapyAtLeast
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Form.Label
                                                    style={{ fontWeight: "bold" }}
                                                  >
                                                    Maximum
                                                  </Form.Label>

                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Interval
                                                      </Form.Label>
                                                      <Form.Select
                                                        name="intervalForPreviousTherapyMaximum"
                                                        id="intervalForPreviousTherapyMaximum"
                                                        onChange={(e) => {
                                                          formik.handleChange(e);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={
                                                          formik.values
                                                            .intervalForPreviousTherapyMaximum
                                                        }
                                                      >
                                                        <option value="Days">
                                                          Days
                                                        </option>
                                                        <option value="Weeks">
                                                          Weeks
                                                        </option>
                                                        <option value="Months">
                                                          Months
                                                        </option>
                                                      </Form.Select>

                                                      {formik.touched
                                                        .intervalForPreviousTherapyMaximum &&
                                                        formik.errors
                                                          .intervalForPreviousTherapyMaximum ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .intervalForPreviousTherapyMaximum
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Number of{" "}
                                                        {formik.values.interval}
                                                      </Form.Label>
                                                      <Form.Control
                                                        id="numberOfPreviousTherapyMaximum"
                                                        name=""
                                                        type="number"
                                                        value={
                                                          formik.values
                                                            .numberOfPreviousTherapyMaximum
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        onBlur={formik.handleBlur}
                                                      />
                                                      {formik.touched
                                                        .numberOfPreviousTherapyMaximum &&
                                                        formik.errors
                                                          .numberOfPreviousTherapyMaximum ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .numberOfPreviousTherapyMaximum
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                </div>
                                              )}

                                            <Form.Group
                                              className="form-group mb-3 d-flex align-items-center "
                                              style={{ gap: "4px" }}
                                            >
                                              <Col xs={6}>
                                                <Form.Label>
                                                  Start Date(Lower Limit)
                                                </Form.Label>
                                                <Form.Control
                                                  type="date"
                                                  id="startdateForTherapiesPreviousLowerLimit"
                                                  name="startdateForTherapiesPreviousLowerLimit"
                                                  value={
                                                    formik.values
                                                      .startdateForTherapiesPreviousLowerLimit
                                                  }
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                />
                                              </Col>
                                              {formik.values
                                                .startdateForTherapiesPreviousLowerLimit && (
                                                  <Col xs={6}>
                                                    <Form.Label>
                                                      Start Date(Upper Limit)
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="date"
                                                      id="startdateForTherapiesPreviousUpperLimit"
                                                      name="startdateForTherapiesPreviousUpperLimit"
                                                      value={
                                                        formik.values
                                                          .startdateForTherapiesPreviousUpperLimit
                                                      }
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      min={
                                                        formik.values
                                                          .startdateForTherapiesPreviousLowerLimit
                                                      }
                                                    />
                                                  </Col>
                                                )}
                                            </Form.Group>
                                            <Form.Group
                                              className="form-group mb-3 d-flex align-items-center "
                                              style={{ gap: "4px" }}
                                            >
                                              <Col xs={6}>
                                                <Form.Label>
                                                  End Date(Lower Limit)
                                                </Form.Label>
                                                <Form.Control
                                                  type="date"
                                                  id="enddateForTherapiesPreviousLowerLimit"
                                                  name="enddateForTherapiesPreviousLowerLimit"
                                                  value={
                                                    formik.values
                                                      .enddateForTherapiesPreviousLowerLimit
                                                  }
                                                  onChange={formik.handleChange}
                                                  onBlur={formik.handleBlur}
                                                />
                                              </Col>
                                              {formik.values
                                                .enddateForTherapiesPreviousLowerLimit && (
                                                  <Col xs={6}>
                                                    <Form.Label>
                                                      End Date(Upper Limit)
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="date"
                                                      id="enddateForTherapiesPreviousUpperLimit"
                                                      name="enddateForTherapiesPreviousUpperLimit"
                                                      value={
                                                        formik.values
                                                          .enddateForTherapiesPreviousUpperLimit
                                                      }
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      min={
                                                        formik.values
                                                          .enddateForTherapiesPreviousLowerLimit
                                                      }
                                                    />
                                                  </Col>
                                                )}
                                            </Form.Group>
                                          </div>
                                        </Col>
                                      )}
                                    {selectedTherapyData?.selectedOptions?.some(
                                      (item) => item?.value.includes("Current")
                                    ) && (
                                        <Col xs={12}>
                                          <div className="therapy-dates-container">
                                            <h4 className="therapy-dates-heading">
                                              Current Therapy
                                            </h4>

                                            <Form.Group className="form-group mb-3">
                                              <FormCheck
                                                type="checkbox"
                                                id="setByDateCheckboxCurrentTherapy"
                                                name="therapyOption"
                                                label="Set by start/end date"
                                                onChange={
                                                  handleCheckboxCurrentTherapy
                                                }
                                                checked={
                                                  selectedOptionCurrentTherapy ===
                                                  "setByDateCheckboxCurrentTherapy"
                                                }
                                              />
                                              <FormCheck
                                                type="checkbox"
                                                id="setTimeCheckboxCurrentTherapy"
                                                name="therapyOption"
                                                label="Set time on therapy"
                                                onChange={
                                                  handleCheckboxCurrentTherapy
                                                }
                                                checked={
                                                  selectedOptionCurrentTherapy ===
                                                  "setTimeCheckboxCurrentTherapy"
                                                }
                                              />
                                            </Form.Group>

                                            {selectedOptionCurrentTherapy ===
                                              "setTimeCheckboxCurrentTherapy" && (
                                                <div className="therapy-dates-container">
                                                  <Form.Label
                                                    style={{ fontWeight: "bold" }}
                                                  >
                                                    At least
                                                  </Form.Label>

                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Interval
                                                      </Form.Label>
                                                      <Form.Select
                                                        name="intervalCurrentTherapyAtLeast"
                                                        id="intervalCurrentTherapyAtLeast"
                                                        onChange={(e) => {
                                                          formik.handleChange(e);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={
                                                          formik.values
                                                            .intervalCurrentTherapyAtLeast
                                                        }
                                                      >
                                                        <option value="Days">
                                                          Days
                                                        </option>
                                                        <option value="Weeks">
                                                          Weeks
                                                        </option>
                                                        <option value="Months">
                                                          Months
                                                        </option>
                                                      </Form.Select>

                                                      {formik.touched
                                                        .intervalCurrentTherapyAtLeast &&
                                                        formik.errors
                                                          .intervalCurrentTherapyAtLeast ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .intervalCurrentTherapyAtLeast
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Number of{" "}
                                                        {formik.values.interval}
                                                      </Form.Label>
                                                      <Form.Control
                                                        id="numberOfCurrentTherapyAtLeast"
                                                        name="numberOfCurrentTherapyAtLeast"
                                                        type="number"
                                                        value={
                                                          formik.values
                                                            .numberOfCurrentTherapyAtLeast
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        onBlur={formik.handleBlur}
                                                      />
                                                      {formik.touched
                                                        .numberOfCurrentTherapyAtLeast &&
                                                        formik.errors
                                                          .numberOfCurrentTherapyAtLeast ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .numberOfCurrentTherapyAtLeast
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Form.Label
                                                    style={{ fontWeight: "bold" }}
                                                  >
                                                    Maximum
                                                  </Form.Label>

                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Interval
                                                      </Form.Label>
                                                      <Form.Select
                                                        name="intervalCurrentTherapyMaximum"
                                                        id="intervalCurrentTherapyMaximum"
                                                        onChange={(e) => {
                                                          formik.handleChange(e);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={
                                                          formik.values
                                                            .intervalCurrentTherapyMaximum
                                                        }
                                                      >
                                                        <option value="Days">
                                                          Days
                                                        </option>
                                                        <option value="Weeks">
                                                          Weeks
                                                        </option>
                                                        <option value="Months">
                                                          Months
                                                        </option>
                                                      </Form.Select>

                                                      {formik.touched
                                                        .intervalCurrentTherapyMaximum &&
                                                        formik.errors
                                                          .intervalCurrentTherapyMaximum ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .intervalCurrentTherapyMaximum
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <Form.Group className="form-group mb-3">
                                                      <Form.Label>
                                                        Number of{" "}
                                                        {
                                                          formik.values
                                                            .intervalCurrentTherapyMaximum
                                                        }
                                                      </Form.Label>
                                                      <Form.Control
                                                        id="numberOfCurrentTherapyMaximum"
                                                        name="numberOfCurrentTherapyMaximum"
                                                        type="number"
                                                        value={
                                                          formik.values
                                                            .numberOfCurrentTherapyMaximum
                                                        }
                                                        onChange={
                                                          formik.handleChange
                                                        }
                                                        onBlur={formik.handleBlur}
                                                      />
                                                      {formik.touched
                                                        .numberOfCurrentTherapyMaximum &&
                                                        formik.errors
                                                          .numberOfCurrentTherapyMaximum ? (
                                                        <div className="error">
                                                          {
                                                            formik.errors
                                                              .numberOfCurrentTherapyMaximum
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </Form.Group>
                                                  </Col>
                                                </div>
                                              )}

                                            <Form.Group className="form-group mb-3">
                                              <Form.Label>
                                                Start Date(Lower Limit)
                                              </Form.Label>
                                              <Form.Control
                                                type="date"
                                                id="startdateForTherapiesCurrent"
                                                name="startdateForTherapiesCurrent"
                                                value={
                                                  formik.values
                                                    .startdateForTherapiesCurrent
                                                }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                              />

                                              {formik.values
                                                .startdateForTherapiesCurrent && (
                                                  <>
                                                    <Form.Label>
                                                      Start date(Upper Limit)
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="date"
                                                      id="enddateForTherapiesCurrent"
                                                      name="enddateForTherapiesCurrent"
                                                      value={
                                                        formik.values
                                                          .enddateForTherapiesCurrent
                                                      }
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      min={
                                                        formik.values
                                                          .startdateForTherapiesCurrent
                                                      }
                                                    />
                                                  </>
                                                )}
                                            </Form.Group>
                                          </div>
                                        </Col>
                                      )}
                                  </Row>
                                </>
                              )}
                            </>
                          </>
                        )}
                      </div>

                      {/* --------------------------------Healthy Behaviour---------------------- */}
                      <div>
                        <div className="form-dropdown">
                          <p>Health Behavior</p>
                          <div className="field-dropdown">
                            <button
                              className="toggle-arrow"
                              onClick={toggleHealth}
                              type="button"
                            >
                              {healthOpen ? "▲" : "▼"}
                            </button>
                          </div>
                        </div>
                        {healthOpen && (
                          <>
                            <>
                              <Col xs={12}>
                                <Form.Group className="form-group mb-3">
                                  <Form.Label>Height</Form.Label>
                                  <Form.Select
                                    id="height"
                                    name="height"
                                    value={FormikGeneral.values?.height}
                                    onChange={FormikGeneral.handleChange}
                                    onBlur={FormikGeneral.handleBlur}
                                    disabled={
                                      value?.role && userRole === "buyer"
                                    }
                                  >
                                    <option value="">Select an item</option>
                                    {/* Generate options for heights from 3 ft 0 inch to 8 ft 12 inches */}
                                    {Array.from({ length: 6 }, (_, index) => {
                                      const feet = index + 3; // 3 to 8 feet
                                      const options = [];
                                      for (let inch = 0; inch <= 12; inch++) {
                                        options.push(
                                          <option
                                            key={`${feet}-${inch}`}
                                            value={`${feet}-${inch}`}
                                          >
                                            {`${feet} ft ${inch} inch`}
                                          </option>
                                        );
                                      }
                                      return (
                                        <optgroup
                                          key={feet}
                                          label={`${feet} ft`}
                                        >
                                          {options}
                                        </optgroup>
                                      );
                                    })}
                                  </Form.Select>
                                  {FormikGeneral.touched.height &&
                                    FormikGeneral.errors.height ? (
                                    <div className="error">
                                      {FormikGeneral.errors.height}
                                    </div>
                                  ) : null}
                                </Form.Group>
                              </Col>
                              <Col xs={12}>
                                <Form.Group className="form-group mb-3">
                                  <Form.Label>Weight in pounds</Form.Label>
                                  <Form.Control
                                    type="number"
                                    id="weight"
                                    name="weight"
                                    value={FormikGeneral.values?.weight}
                                    onChange={FormikGeneral.handleChange}
                                    onBlur={FormikGeneral.handleBlur}
                                    disabled={
                                      value?.role && userRole === "buyer"
                                    }
                                    pattern="[0-9]*"
                                  />

                                  {FormikGeneral.touched.weight &&
                                    FormikGeneral.errors.weight ? (
                                    <div className="error">
                                      {FormikGeneral.errors.weight}
                                    </div>
                                  ) : null}
                                </Form.Group>
                              </Col>
                              <Col xs={12}>
                                <Form.Group className="form-group mb-3">
                                  <Form.Label>Smoking</Form.Label>
                                  <Form.Select
                                    id="smoking"
                                    name="smoking"
                                    value={FormikGeneral.values?.smoking}
                                    onChange={FormikGeneral.handleChange}
                                    onBlur={FormikGeneral.handleBlur}
                                    disabled={
                                      value?.role && userRole === "buyer"
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>

                                    {FormikGeneral.touched.smoking &&
                                      FormikGeneral.errors.smoking ? (
                                      <div className="error">
                                        {FormikGeneral.errors.smoking}
                                      </div>
                                    ) : null}
                                  </Form.Select>
                                </Form.Group>
                              </Col>

                              <Col xs={12}>
                                <Form.Group className="form-group mb-3">
                                  <Form.Label>Alcohol</Form.Label>
                                  <Form.Select
                                    id="alcoholAbuse"
                                    name="alcoholAbuse"
                                    value={FormikGeneral.values?.alcoholAbuse}
                                    onChange={FormikGeneral.handleChange}
                                    onBlur={FormikGeneral.handleBlur}
                                    disabled={
                                      value?.role && userRole === "buyer"
                                    }
                                  >
                                    <option value="">Select an option</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>

                                    {FormikGeneral.touched.alcoholAbuse &&
                                      FormikGeneral.errors.alcoholAbuse ? (
                                      <div className="error">
                                        {FormikGeneral.errors.alcoholAbuse}
                                      </div>
                                    ) : null}
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </>
                          </>
                        )}
                      </div>
                    </Row>
                    {/* ------------------------------------SubmitButton--------------------- */}

                    <div className="d-grid gap-2">
                      <Button
                        onClick={() => {
                          if (
                            FormikGeneral?.values?.diagnosis !==
                            initialValues?.diagnosis
                          ) {
                            setShowModal(true);
                          } else if (
                            FormikGeneral?.values?.diagnosis ===
                            initialValues?.diagnosis
                          ) {
                            setShowModal(false);
                          } else {
                            FormikGeneral.handleSubmit();
                          }
                        }}
                        style={{
                          marginBottom: "38px",
                          backgroundColor: "#5b99a6",
                          border: "none",
                        }}
                        className="buttonBuy"
                        type={"submit"}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Container>
            </div>
          </div>
        </div>
      </>
      {showModal && (
        <MyModal FormikGeneral={FormikGeneral} handleClose={handleClose} />
      )}
      ;
    </>
  );
};
export const MyModal = ({ FormikGeneral, handleClose }) => {
  return (
    <>
      <div className="modal-overlay">
        <div className="validationModal">
          <button className="close-button" onClick={handleClose}>
            X
          </button>
          <h2>Validation</h2>
          <p>Health Care Professional</p>
          <Form onSubmit={FormikGeneral.handleSubmit}>
            <div className="email-validation">
              <Form.Label>Email</Form.Label>
              <input
                // ref={validateEmailRef}
                type="email"
                id="email"
                name="email"
                value={FormikGeneral.values.email}
                onChange={FormikGeneral.handleChange}
                onBlur={FormikGeneral.handleBlur}
                placeholder="Enter your email address"
              />
            </div>

            <div className="identifier-validation">
              <Form.Label>Identifier</Form.Label>
              <input
                type="text"
                // id="identifier"
                name="identifier"
                value={FormikGeneral.values.identifier}
                onChange={FormikGeneral.handleChange}
                onBlur={FormikGeneral.handleBlur}
                placeholder="Enter as Identifying code"
              />
            </div>
            <button className="submit-btn" onClick={FormikGeneral.handleSubmit}>
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
