import React from 'react'
import './style.scss'
import { BrowserRouter as Router, Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className="privacyStatement--">
        
<h1  style={{ textAlign: "center", margin: "45px" }}>
    TERMS & CONDITIONS
    </h1>
    <hr style={{marginBottom: '18px'}}/>
<p>
These Terms & Conditions govern the use of the Liberdat software, commercialized by Liberdat B.V., referred to as “LIBERDAT”, a subsidiary of Liberhold 
B.V., represented by S. Peraee, registered at Boxbergerweg 140-F6, 7413 EL, Deventer, The Netherlands, with chamber of commerce number 94704791.</p>

<p>LIBERDAT and users of the sotware are referred to individually as “Party” or jointly as “Parties”.</p>

<h2>
    Opening Statements</h2>
    <hr style={{marginBottom: '18px'}}/>
    <p>The Liberdat software is accessible via the website <a href="https://liberdat.io/">https://liberdat.io/</a> and via the Liberdat works in conjunction with a mobile application application which can be downloaded from Apple App Store and Google Play Store.</p>

<p>Before signing up, users are is obliged to read, understand, and accept these Terms & Conditions which form a legally binding Agreement between users and LIBERDAT regarding the use of the software. If a User is using the software on behalf of a legal entity, LIBERDAT assumes that the user is authorized and entitled to accept these Terms & Conditions on behalf of the legal entity they represent. Before signing up,</p>

<p>users are required to read, understand, and accept the  <Link to="/privacy-statement"   onClick={() => {
    window.scroll(0, 0);
  }}>Privacy Statement</Link>.</p>

<p>The LIBERDAT software is intended to facilitate the exchange of data between Data Requesters and Data Sharers. In addition, via the prediction tool, exchanged data can be 
utilized for the prediction of disease biomarkers to aid in the monitoring of
disease. The software does not serve other medical 
purposes.</p>

<p>LIBERDAT contracts with subcontractors to provide additional functionalities to the software. 
Please refer to the Privacy Statement for a full list of 
subcontractors.</p>

<p>LIBERDAT does not sell your data nor does it grant rights to subcontractors to sell your data.</p>


<p>In order to receive a license to use the Software, as either a Data Requester or individual, users agree to be bound by the following Terms & Conditions: </p>

<h2>
    1.  Definitions </h2>
    <hr style={{marginBottom: '18px'}}/>
    <p><strong>Agreement</strong> - The Terms & Conditions in addition to all the appendices.</p>

{/* <p><strong>Data request </strong> –The request from a Data Requester for a Data Item which is listed on the Homepage of the Platform.</p>

<p><strong>Data Requester </strong> - Every User that places one or more Data Requests for an Individual’s Data Items on the Platform.</p>

<p><strong>"Request" button</strong>  – Allows Data Requesters  to place a Data Request for a Data Item on the Platform.</p> */}

<p><strong>Confidential Information</strong> –The information disclosed by LIBERDAT to Users for the purposes of this Agreement.</p>

<p><strong>Content</strong> – Any content provided by users.</p>

{/* <p><strong>Data Category</strong> – A collection of Data Items belonging to a specific category.</p> */}

{/* <p><strong>Data Items</strong> – The Items that Data Requesters  list on the Platform in order to find a possible data exchange with an Individual.</p> */}

<p><strong>Data Processing Agreement</strong> – The agreement between LIBERDAT as a processor and a Data Requester as controller (for definitions of processor and controller please see Regulation (EU) 2016/679 Of The European Parliament And Of The Council (“GDPR”)).</p>
<p><strong>Data request </strong> –The request from a Requester for profile data from a Sharer which is listed on the Homepage of the Platform.</p>
<p><strong>Exchanged Data </strong> –Profile Data that are exchanged between a Requester and Sharer, possibly complemented by questionnaires data.</p>

<p><strong>Final Price</strong> – The settled price for a Transaction. The Final price consists of a) the settled price for the Profile Data between Requester and Sharer; and b) any applicable taxes or fees.</p>
<p><strong>Homepage</strong> – The opening webpage of the Platform which lists the Data Requests of Data Requesters.</p>
<p><strong>Identification Data</strong> – The personal data a user provides for identification purposes such as username and password (see the Data Processing agreement for a full list).</p>
{/* <p><strong>Data Sharer</strong> - A User that shares Data Items via the Platform.</p> */}
<p><strong>Integrated Payment Service</strong> – The online payment system allowing Users to make monetary transactions for Profile Data via the Platform.</p>
{/* <p><strong>Negotiation Process</strong> – The process available to Data Requesters  and Data Sharers to make counterbids which allows for the settlement of a price both parties agree on.</p> */}
<p><strong>Payment Service Provider</strong> – LIBERDAT utilizes the services of the payment service provider Stripe, Inc., an Irish-American financial services and software as a service (SaaS) company dual-headquartered in South San Francisco, California, United States and Dublin, Ireland. Stripe, Inc. is a licensed electronic money institution regulated by the Central Bank of Ireland.</p>
<p><strong>Personal Data</strong> – All the personal data that is processed of Users which consist of a) Identification Data; b) User Data; and c) Exchanged Data.</p>
<p><strong>Platform</strong> - The LIBERDAT website and mobile application.</p>

<p><strong>Privacy Statement</strong> – The document that describes how LIBERDAT collects, stores, and transfers personal data of Visitors or Users on the Platform. The Privacy Statement can be accessed via the Platform.</p>
<p><strong>Profile</strong> – The personal page of a User for Profile Data and Wallet management.</p>
<p><strong>Profile Data</strong> – The data provided by users in their profile.</p>

<p><strong>“Request” button</strong> – Allows Data Requesters  to place a Data Request for Profile Data on the Platform.</p>
<p><strong>Data Requester</strong> - A User that makes Data Requests via the Platform.</p>
<p><strong>Sharer</strong> - A User that shares Profile Data via the Platform.</p>

<p><strong>Software</strong> – The Liberdat software provides the following functionalities a) an online platform which facilitates data exchange between Users; b) the storage of Personal Data; c) a predictive modelling tool d) providing Requesters access to Exchanged Data; e) an Integrated Payment Service; f) search functionality to optimize the user experience.</p>
<p><strong>Standard Contractual Clauses</strong> – Applicable clauses regarding the transfer of Exchanged Data by LIBERDAT to a Data Requester located in a third country.</p>
<p><strong>Terms & Conditions</strong> –The binding agreement between LIBERDAT and a User, as defined in the opening statements of this document.</p>
<p><strong>Transaction</strong> – A monetary transaction between a Data Requester and an Data Sharer for Exchanged Data.</p>
{/* <p><strong>Transacted Data</strong> – Data Items that are transacted between a Data Requester and Data Sharer and that are thereby downloadable from a LIBERDAT server by the Data Requester (see the Data Processing agreement for a full list).</p> */}
<p><strong>User</strong> – Any person who has created an account on the Platform and has accepted these Terms & Conditions.</p>
<p><strong>User account or Account</strong> – The User’s account created when signing up on the Platform.</p>
<p><strong>User Data</strong> – The non-identifiable information a user provides in their Profile (see the Data Processing agreement for a full list).</p>
<p><strong>Visitor</strong> – Any Data Sharer who visits the Platform, but who has not created an Account.</p>
<p style={{marginBottom: '32px'}}><strong>Wallet</strong> – Financial services provided by the Payment Service Provider which allows Users to manage finances on the Platform.</p>

<h2>2. Minors</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>2.1</strong> Children under the age of 16 need to provide written consent from their parents or legal guardian before using the Software. Consent can be sent via e-mail to <a href="mailto:info@liberdat.io">info@liberdat.io</a> by providing identification of the parent or legal guardian and the created username of the children on the Software. After consent, kids under the age of 16 can only use the Software under the supervision of a parent or legal guardian.</p>

<p style={{marginBottom: '32px'}}><strong>2.2</strong> The Accounts of minors will be removed if the Software is used without the consent of a parent or legal guardian.</p>

<h2>3. Visitors</h2>
<hr style={{marginBottom: '18px'}}/>

<p style={{marginBottom: '32px'}}>Visitors can search and view Data Requests on the Platform. Additionally, Visitors can fill out a Data Request for Profile Data. However, an Account has to be created to assign Indivduals to the Data Request. 

</p>



<h2>4. Signing up </h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>4.1</strong> In order to utilize the Software, Users sign up and create an Account as a Sharer or as a Requester. Users are required to provide accurate and up-to-date information. Data Requesters  and Data Sharers will be asked to provide a username and password for identification purposes each time the Platform is accessed. Data Requesters  will be asked additionally to provide the name of their affiliated institution, department name, and corresponding institutional e-mail address. LIBERDAT uses this personal data for the purposes and in the manner described in the <Link to="/privacy-statement"   onClick={() => {
    window.scroll(0, 0);
  }}>Privacy Statement</Link>. For the security of its Users, LIBERDAT may verify the provided information and ask questions, provide relevant information or assist in verification procedures. Furthermore, LIBERDAT may verify the User's telephone number, email, the User's credit card, debit card or other payment method, or bank account.</p>

<p style={{marginBottom: '32px'}}><strong>4.2</strong> Users will not attempt to use the personal account of anyone else. Users will not attempt to create an unauthorized Account by any means. LIBERDAT is not liable for any loss or damage arising from failure to comply with the above. LIBERDAT reserves the right to disable access to Users Accounts at our discretion in case of non-compliance with these Terms & Conditions.</p>

<h2>5. Subcontractors</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '32px'}}>Some features of the Sotware are facilitated by subcontractors, which are subject to different terms & conditions.</p>

<h2>6. Access, use, & information provided</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>6.1</strong> The Software may change without prior notice due to updates. 
LIBERDAT makes all reasonable efforts to ensure the accessibility of the 
Platform which might be halted without prior notice due to for example 
technical malfunctioning or maintenance. Moreover, LIBERDAT reserves 
the right to at all times restrict access to users if the Terms & Conditions 
for the use of the Software are breached. LIBERDAT cannot be held liable 
for any damage or loss if the Platform is rendered temporarily or 
permanently inaccessible or if access to the Platform is restricted.</p>

<p><strong>6.2</strong> Users are responsible for use of the Software and for any content 
provided on it. Users cannot send any unsolicited, commercial, offensive 
or harmful content to other Users of the Software. Moreover, Users cannot
share confidential, illegal, harmful or libellous content, or any content 
infringing third party rights on the Platform. LIBERDAT does not endorse, 
support, represent or guarantee the accuracy, completeness or reliability 
of any content provided by Users on the Platform. Furthermore, LIBERDAT 
does not monitor or control the content Users provide and therefore 
cannot be held liable. Finally, use of the Software is at the User’s own risk.
LIBERDAT reserves the right to remove content at its discretion and 
without prior notice. Users cannot use the Software for any illegal or 
unauthorized purposes, and Users are required to comply with all 
applicable laws and regulations. Users will not try to restrict other Users in
the use of the Software and Users will not enable or encourage other 
Users to violate these Terms & Conditions. Users will not hinder or disrupt 
the provision of the Software via spyware, malware, viruses or other 
destructive code. LIBERDAT reserves the right to disable access to Users 
accounts at its discretion in case of non-compliance or if a user of the 
Software forms a legal risk to us.</p>

<p style={{marginBottom: '32px'}}><strong>6.3</strong> LIBERDAT makes efforts to ensure that information provided on the 
Platform is correct. The information and features offered on the Platform 
may be subject to changes without prior warning. However, LIBERDAT 
holds no obligation to make updates to the provided Software.Users 
acknowledge the information on the Platform may not always be accurate,
complete or fit for their purposes and that LIBERDAT cannot be held 
responsible for any consequences.
</p>

<h2>7. Requesting</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>7.1</strong> Only Data Requests that meet all of the following conditions may be 
listed on the Platform: a) Data Request(s) must be Data Item(s) supported
by the Platform; b) haring, use or possession of the Data Item related to 
the Data Request must not violate the rights of any third party (including 
any intellectual property rights) and must not violate any local, national or
international applicable law or regulation. The Data Item must be safe and
cannot be counterfeit; c) the Data Request must comply with all 
commonly applied requirements on the Platform, unless there is an 
agreement to the contrary between the Requester and Sharer; d) the Data
Request must comply with the Payment Service Provider’s list of 
prohibited and restricted products and services.</p>

<p><strong>7.2</strong> To list a Data Request on the Platform, the Requester must fill out the 
“Request” field.</p>

<p><strong>7.3</strong> When a Requester lists a Data Request on the Platform, this 
constitutes an offered Data Request which may be accepted by a Sharer.</p>

<p><strong>7.4</strong> The Requester who lists a Data Request can withdraw it or amend any
changes before users have been assigned to the request.</p>

<p><strong>7.5</strong> Data Requests made by other means than the “Request” button are 
made solely at the Requester’s risk. LIBERDAT is not responsible for, and 
disclaims all liability arising out of or related to, purchases  off the 
Platform. For Requester security, LIBERDAT presses Requesters to 
complete Data Requests using the “Request” button and not to make 
Transactions outside of the Platform.</p>

<p><strong>7.6</strong> Data Requests presented on a Requesters Homepage are search 
results sorted by “relevance” taking into consideration the following main 
criteria: a) relevance to the search query; b) the Requesters Data 
Requests; c) the date on which the Data Request was uploaded. With 
these parameters, LIBERDAT ensures that the listings presented higher in 
ranking match the Requesters search expectations.</p>

<p style={{marginBottom: '32px'}} ><strong>7.7</strong> When Data Sharers search for a Data Request, listings are sorted by default by “relevance” as mentioned above in section 7.6. Alternatively, on the Platform, Data Sharers can also choose for the listings to be sorted by price or by date. If Data Sharers choose for listings to be sorted by price and date, the preferences they indicated in their account will still be taken into consideration to classify listings.</p>

<h2>8. Sharing</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>8.1</strong> Only Profile Data that meet all of the following conditions may be 
shared via the Platform: a) the Data Item(s) are supported by the 
Software; b) sharing, use or possession of the Profile Data must not 
violate the rights of any third party (including any intellectual property 
rights) and must not violate any local, national or international applicable 
law or regulation. The Profile Data must be safe and cannot be 
counterfeit; c) the Profile Data must comply with all commonly applied 
requirements on the Platform, unless there is an agreement to the 
contrary between the Requester and Sharer; d) The Profile Data must 
comply with the Payment Service Provider’s list of prohibited and 
restricted products and services.
</p>

<p><strong>8.2</strong> To receive Data Requests for Profile Data, User can allow LIBERDAT to
read Apple Health (iOS) or Google fit (Android), enter Profile Data under 
“My Data”, and accept to fill out questionnaires.</p>

<p><strong>8.3</strong> When a Sharer takes actions as mentioned in section 8.2, this 
constitutes an offered Data Item by the Sharer.</p>

<p><strong>8.4</strong> Profile Data can be withdrawn by Sharers by either deleting 
information in their profile and or deleting their account. </p>

<p><strong>8.5</strong> Sharing data via other means as explained in the previous sections is 
done solely at the Users’ risk. To the extent permitted by law, LIBERDAT is
not responsible for, and disclaims all liability arising out of or related to, 
purchases conducted off the Platform. For Sharer security and that of the 
transactions, LIBERDAT discourages Sharers to complete Transactions 
outside the Platform.</p>
<p style={{marginBottom: '32px'}}><strong>8.6</strong> When Sharers search for a Data Request, listings are sorted by default
by “relevance” as mentioned above in section 7.6.</p>
<h2>9. Matching Requesters & Sharers</h2>
<hr style={{marginBottom: '18px'}}/>
< p style={{marginBottom: '32px'}}>Requesters can choose to assign users either automatically or manually. 
Automatically assigning allows requesters to match variables in their Data
Requests with the variables provided by Sharers. Additionally, Requesters 
can assign users manually via Usernames. To guarantee data authenticity,
Sharers are obliged to update and if necessary validate their data before a
Data Exchange can take place.</p>

<h2>10. Requester & Sharer contract</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '32px'}}>When a Data Request has been accepted, both the Requester and Sharer 
become a party to the following contract: both the Requester and the 
Sharer acknowledge that a legally binding contract between the 
Requester and the Sharer exist based on: a) the description of the Data 
Request; b) the relevant conditions included in these Terms & Conditions. 
Requester pays for the Data Item and the Sharer licenses the use of the 
Data Item. Healthcare professionals who are involved in the direct clinical 
care of sharers do not pay. LIBERDAT is not a party to the legally binding 
contract. European Uninion (EU) consumer rights and protection do not 
apply when buying from Sharers as opposed to legal entities. </p>

<h2>11. Paying</h2>

<hr style={{marginBottom: '18px'}}/>
<p><strong>11.1</strong> LIBERDAT contracts with a Payment Service Provider to process 
payments and to store information relating to credit cards, debit cards, 
and any other payment method offered on the Platform. By accepting 
LIBERDAT’s Terms & Conditions, Users automatically accept the terms & 
conditions of the Payment Service provider (click <a href="https://stripe.com/nl/legal/consumer">here</a> to view these terms
and conditions). Payment of the Final Price can be made by any payment 
method supported by the Payment Service Provider. If a User of the 
Platform provides a payment method that is suspected to belong to a third
person, the User may be required to provide additional proof that the 
payment method belongs to the User. Any funds available in the Wallet 
can be automatically used to make purchases on the Platform. Insufficient
funds to cover the Final Price of a Transaction can be covered by an 
additional payment through one of the available payment methods. Once 
a Transaction is initiated, the Payment Service Provider acquires funds 
from the Requester on behalf of the Sharer and holds those funds for 
disbursement until the Transaction is completed (see section 11.2). 
LIBERDAT supports its Users in using the services provided by the 
Payment Service Provider, however, LIBERDAT does not provide any 
payment processing services for Users. Users are solely responsible for 
the provision of accurate information associated with credit cards, debit 
cards, and any other payment method offered on the Platform and 
LIBERDAT disclaims any responsibility and all liability for User-provided 
information to the full extent permitted by applicable law.</p>

<p><strong>11.2</strong> The Final Price paid by the Requester will be retained by the 
Payment Service Provider until the Transaction is completed. The 
Transaction is completed after the Data Item(s), as specified in the Data 
Request, have been transferred to a LIBERDAT server. Once the 
Transaction is completed, the funds are transferred by the Payment 
Service Provider to the Sharer’s Wallet. The Requester will receive a 
message that the Data item(s) can be downloaded (only from a desktop 
computer) for a period of 30 days after which the Exchanged Data will be 
permanently deleted from the LIBERDAT server. A User can withdraw a 
balance in the Wallet to their personal bank account without a transfer 
fee. However, Users must provide bank account details for the first pay-
out (see section 12).</p>

<p><strong>11.3</strong> The right to use the Integrated Payment Service is only for sales in a 
User’s own Account. A User may not resell, hire, or on any other basis 
allow third parties to use the Integrated Payment Service to enable such 
third parties to be paid for their services.</p>

<p><strong>11.4</strong> The Integrated Payment Service is only for Transactions regarding 
Profile Data supported by the Platform and not for any other products or 
services.</p>

<p style={{marginBottom: '32px'}}><strong>11.5</strong> All prices on the Platform are expressed in Euros (EUR) including any
applicable taxes.</p>

<h2>12. Wallet</h2>

<hr style={{marginBottom: '18px'}}/>
<p><strong>12.1</strong> The Wallet is a service provided by the Payment Service Provider. By
accepting LIBERDAT’s Terms & Conditions, Users automatically accept the
terms & conditions of the Payment Service Provider (click <a href="https://stripe.com/nl/unsupported-browser?location=%2Fnl%2Flegal%2Fconsumer">here</a> for these 
terms and conditions). For the first Transaction, the Sharer will be asked 
to create a Wallet by providing the Individual’s name, surname, date of 
birth, address, phone number and IBAN number. If the Sharer fails to do 
so within five (5) days, the funds will be donated to the Platform. Before a 
first Transaction takes place, Sharers can also create the Wallet by going 
to their profile and providing the required data.</p>

<p><strong>12.2</strong> Users will be subject to regulatory procedures applied by the 
Payment Service Provider, such as identity checks and other requirements
of the “Know Your Customer” procedure when respective financial 
thresholds are met. Those financial thresholds are set by the Payment 
Service Provider and depend on the total amount of pay-outs made by the
User. In addition, the Payment Service Provider may also carry out KYC 
checks on the User without reaching set thresholds if it suspects that the 
User is engaging in possibly fraudulent or suspicious behaviour. For the 
identity check purposes, User may be asked to provide additional 
information as requested by the Payment Service Provider. If a User fails 
to provide the documents required by the Payment Service Provider, the 
User’s Wallet may be suspended and the User will not be able to pay out 
the funds until confirmation of their identity. The Payment Service 
Provider might also suspend User’s ability to make transactions from its 
Wallet. If on the other hand a User has committed an illegal act, e.g. 
uploaded a fraudulent document or took any other unlawful actions, and 
the Payment Service Provider deems such User as fraudulent, the User 
will no longer be able to pass KYC checks which will render the User 
unable to access or withdraw the funds.</p>

<p style={{marginBottom: '32px'}}><strong>12.3</strong> Users can transfer funds from their Wallet to their bank account, 
transfer will be performed by the Payment Service Provider. To enable this
option, the User must first go to User account settings and add the User’s 
bank account number, full name and billing address. If a user reaches the 
KYC thresholds referred to in section 12.2 above or if the Payment Service
Provider suspects that the User is engaging in possibly suspicious or 
fraudulent activity, the User will not be able to transfer funds until they 
complete the relevant KYC checks.</p>

<h2>13. Prediction Algorithm</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>13.1</strong> The prediction algorithm serves users with the function of predicting 
disease biomarkers via the “Predict” button. Based on Profile Data 
provided by Users, a personal model is created which can be updated by 
the Users. User’s models are collectively trained through which the 
algorithm facilitates predictions.</p>

<p><strong>13.2</strong> The prediction algorithm only serves the purpose of predicting 
disease biomarkers. The prediction algorithm does not provide diagnostic 
or treatment recommendations.</p>

<h2>14. Intellectual property rights</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>14.1</strong> LIBERHOLD B.V. retains all rights, including all intellectual property 
rights of the Platform. Intellectual property rights include amongst others 
but is not limited to: (trade) marks, logos, service marks, domain names, 
drawings, models, patents, copyrights and moral rights, rights relating to 
databases, software, know-how and other rights, as well as all other 
industrial and intellectual rights, in each case regardless of whether they 
are registered or not and including the applications for registration, as 
well as all equivalent rights or means of protection leading to a similar 
result anywhere in the world.</p>

<p><strong>14.2</strong> Through use of the Platform, Users agree to the Terms & Conditions 
for the license for using the Platform. Users obtain a personal, revocable, 
exclusive, non-transferable right to use the Platform in accordance with 
these Terms & Conditions. There is no transfer of intellectual property 
rights, in whole or in part, and Users are not allowed to reproduce, alter, 
disseminate, sell, loan, broadcast, license, reverse engineer, decompile, 
disassemble or otherwise exploit the source code of the Software or make 
derivative works thereof without LIBERDAT’s prior permission. The 
LIBERDAT name and logo cannot be used.</p>

<p><strong>14.3</strong> Requesters and Sharers remain the owner of any data provided, and 
LIBERDAT receives a worldwide, non-exclusive, royalty-free license to use 
the data for provision and improvement of the Software.</p>

<p><strong>14.4</strong> Sharers give Requesters a worldwide, non-exclusive, royalty free 
license to use Transferred Data for research purposes which include, 
amongst others, but not limited to, analysis, presentation, and publishing 
of the data.</p>

<p><strong>14.5</strong> This license does not comprise the right to incorporate the Software 
or its parts into products, which are sold or transferred to third parties. 
The grant of this license does not include the right to use the Platform to 
provide services for the benefit of third parties or to use the Platform to 
provide clinical or medical services.</p>

<p style={{marginBottom: '32px'}}><strong>14.6</strong> Nothing in this Agreement precludes LIBERDAT from entering into 
agreements with subcontractors concerning the Platform.</p>

<h2>15. Subcontractors</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '32px'}}>The Platform interacts with software from subcontractors. By accepting 
these Terms & Conditions, Users by default accept the terms and 
conditions of the subcontractors. Please refer to the Privacy Statement for
a full list of subcontractors and their terms and conditions.</p>

<h2>16. Delivery & Support</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>16.1</strong> This Agreement does not include support by LIBERDAT for the 
Software.</p>

<p style={{marginBottom: '32px'}}><strong>16.2</strong> LIBERDAT gives no guarantee of access, availability, continuity, of 
the Software. LIBERDAT reserves the right to discontinue the Software at 
any time without advance notification to Users.</p>

<h2>17. Data protection</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>17.1</strong> LIBERDAT processes personal data of Sharers on behalf of the 
Requester (controller). LIBERDAT acts as a processor on behalf of the 
controller to retrieve, store, and transfer Exchanged Data to the controller.
The Data Processing Agreement between LIBERDAT and the Requester 
applies.</p>



<p style={{marginBottom: '32px'}}><strong>17.2</strong> LIBERDAT also processes personal data from Requesters and Sharers
for its own purpose which relate to the improvement of the Software. More
information relating to the processing of personal data can be found in 
our <Link to="/privacy-statement"   onClick={() => {
    window.scroll(0, 0);
  }} >Privacy Statement.</Link>. In this case, LIBERDAT acts as data controller for 
  processing User’s data.</p>


<h2>18. Data sharing</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>18.1</strong> Users agree that LIBERDAT shares Personal Data of users with other 
users on the Platform in order to fulfill the Software functions.</p>

<p><strong>18.2</strong> LIBERDAT processes Personal Data to improve and further develop 
the Software.</p>

<p><strong>18.3</strong> LIBERDAT processes Personal Data for its own purposes and thereby 
acts as data controller.</p>

<p><strong>18.4</strong> LIBERDAT processes Personal Data as strictly necessary for its own 
purposes, in compliance with Article 5 of the GDPR.</p>

<p><strong>18.5</strong> LIBERDAT implements appropriate measures to secure Personal 
Data shared by Users in compliance with Article 32 of the GDPR.</p>

<p style={{marginBottom: '32px'}}><strong>18.6</strong> When Requesters are not established in the European Economic 
Area (EEA), the <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">Standard Contractual Clauses </a> of the European 
Commission apply to the transfer of Exchanged Data outside of the EEA, if
the country in which Requesters are established is not covered by an 
existing adequacy decision adopted by the European Commission. Where 
LIBERDAT acts as a data processor, the Standard Contractual Clauses 
apply.</p>

<h2>19. Questionnaires</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '32px'}}>Requesters agree that any questionnaires configured through the Platform
can be used by LIBERDAT for purposes including, but not limited to, 
providing such questionnaires to other Requesters on the Platform for use 
as or in their own questionnaires. This does not include the answers 
provided by Sharers. Requesters guarantee that any questionnaires 
submitted do not infringe on any third-party rights and that any necessary
approval from third parties has been obtained in order to grant the right 
to LIBERDAT to use the questionnaires.

</p>

<h2>20. Confidentiality</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>20.1</strong> Requesters are not permitted to distribute, disclose or disseminate 
Confidential Information, except to colleagues, peers, or employees who 
have a need to know the Confidential Information for the purposes of this 
Agreement and who shall be bound by confidentiality obligations at least 
as stringent as the one provided for in this Agreement.</p>

<p><strong>20.2</strong> The obligations of confidentiality do not apply to information 
required to be disclosed by operation of law or by court or administrative 
order. Users will provide LIBERDAT with prior written notice of any such 
requirement and will cooperate with LIBERDAT in the disclosure of the 
information.

</p>

<p style={{marginBottom: '32px'}}><strong>20.3</strong> Users agree to treat the Platform as Confidential Information for an 
unlimited duration.</p>

<h2>21. Warranty & liability</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>21.1</strong> LIBERDAT is not liable for any direct or indirect damages incurred by 
Users of the Platform.</p>

<p><strong>21.2</strong> Users acknowledge that the Platform only facilitates data exchange 
between Requesters and Sharers and does not provide medical advice. 
Users acknowledge that the Platform may not be used as a substitute for 
medical advice, diagnosis, prevention, monitoring or treatment of any 
health condition or problem.</p>

<p><strong>21.3</strong> Requesters acknowledge and agree that they and their organisation 
are solely responsible for any interpretation of data bought via the 
Platform. Reseachers acknowledge and agree that this license does not 
constitute ethical approval of any kind for academic or clinical use and 
that Reseachers are solely responsible for obtaining prior ethical approval 
for use of the Platform within the terms of this Agreement.</p>

<p><strong>21.4</strong> Users acknowledge that LIBERDAT is not responsible for the 
accuracy or veracity of the credentials or qualifications of either Sharers 
or Requesters and their affiliated institution. Users agree that they are 
solely responsible for exchanging data with other Users on the Platform.</p>

<p><strong>21.5</strong> Users acknowledge that the Platform is made available for free. The 
Platform is provided in its present form and LIBERDAT makes no 
representations and extends no warranties of any kind, either expressed 
or implied with respect to the Platform. There are no expressed or implied 
warranties of merchantability or fitness for a particular purpose or 
warranties that the use of the Platform will not infringe any patent, 
copyright, trademark, or other proprietary rights. Users will indemnify and 
hold LIBERDAT harmless from all losses, damages, expenses, costs and 
other liabilities in connection with use of the Platform and data.</p>

<p><strong>21.6</strong> LIBERDAT is not liable for any failure in performance arising out of 
causes beyond its control or for any direct or indirect damages caused to 
Users or third parties arising out of use of the Platform or the data, unless 
caused by the wilful misconduct of LIBERDAT.</p>

<p style={{marginBottom: '32px'}}><strong>21.7</strong> LIBERDAT’s liability will not be limited to the extent such limitation is
not permitted by law or to the extent that damages are caused by wilful 
misconduct of LIBERDAT.</p>

<h2>22. Duration</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>22.1</strong> This Agreement is effective from the date Users register for the 
Platform and remains in force until either account deletion by Users or 
LIBERDAT terminates this Agreement in case of a violation of these Terms 
& Conditions.</p>

<p><strong>22.2</strong> Neither Party will on termination of this Agreement be relieved of 
obligations accrued prior to the date of termination and neither will 
termination affect any rights of a Party accrued prior to the date of 
termination. The obligations of the parties contained in paragraph 13, 16, 
17, 18, 19 will survive the end of the Agreement.</p>

<p style={{marginBottom: '32px'}}><strong>22.3</strong> Upon termination of this Agreement Users are mandated to cease 
using the Software. Furthermore, Users are obliged to state in writing to 
LIBERDAT that all Exchanged Data has been destroyed.</p>
<h2>23. Miscellaneous</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>23.1</strong> Any notice authorized or required to be given to LIBERDAT under this
Agreement shall be in writing to:<br/>

Liberdat B.V.  <br/>
Boxbergerweg 140-F6 <br/>
7413 EL, Deventer <br/>
The Netherlands <br/>
<a href="mailto:info@liberdat.io">info@liberdat.io</a></p>



<p><strong>23.2</strong> LIBERDAT may assign, transfer or sublicense this Agreement or parts
thereof to subcontractors without prior User consent.</p>

<p><strong>23.3</strong> Non-compliance by a Party under this Agreement will not be 
considered a breach of this Agreement if caused by occurrences beyond 
the reasonable control of the Party affected.</p>

<p><strong>23.4</strong> Users are encouraged to provide LIBERDAT with feedback on the 
Platform. LIBERDAT is permitted to use feedback for amending changes to
the Platform.</p>

<p style={{marginBottom: '32px'}}><strong>23.5</strong> These Terms & Conditions supersede all prior oral or written 
agreements and understandings between the Parties.</p>


<h2>24. Changes</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '32px'}} >The Terms & Conditions are subject to changes over time. The changes 
will not be retroactive, and the most current version of the Terms & 
Conditions, which can be found <Link to="/termsandconditions"   onClick={() => {
    window.scroll(0, 0);
  }}>here</Link>, will govern our relationship with 
  Users. LIBERDAT notifies Users of any upcoming changes 30 days in 
  advance via the Platform. By continuing use of the Software after 30 days,
  Users agree to be bound by the revised Terms & Conditions.</p>

<h2>25. Disputes</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>25.1</strong> In the event of disputes in the interpretation of this Agreement, the 
Parties will first undertake to settle differences amicably.</p>

<p style={{marginBottom: '32px'}}><strong>25.2</strong> This Agreement is governed by the laws of The Netherlands, to the 
exclusion of any conflict-of-laws rules which would cause the laws of 
another jurisdiction to apply. Any dispute related to this Agreement will be
adjudicated exclusively by the Dutch courts.</p>


 
<h1 style={{ textAlign: "center", margin: "45px" }}>Appendix 1.</h1>
<hr style={{marginBottom: '18px'}}/>
<h1  style={{ textAlign: "center", margin: "45px" }}>
    DATA PROCESSING AGREEMENT</h1>
<h2>1. General</h2>
<hr style={{marginBottom: '18px'}}/>


<p><strong>1.1</strong>Regarding the processing of personal data by the processor on behalf 
of the controller, the Requester is the “controller” and LIBERDAT is the 
“processor” according to the meaning of <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&qid=1691404126036">EU Regulation 2016/679 of 27 April 2016</a> on the protection of Sharers with regard to the processing of 
Personal Data and on the free movement of such data (“GDPR”)</p>

<p><strong>1.2</strong> The processing involves the types of Personal Data as described in the
subappendix of this Data Processing Agreement.</p>

<p><strong>1.3</strong> The processing involves data subjects as described in the 
subappendix of this Data Processing Agreement.</p>

<p><strong>1.4</strong> The processing will be conducted for the purposes outlined in the 
subappendix of this Data Processing Agreement, or to meet the legal or 
judicial obligations of the processor or controller. This processing will 
continue for the duration specified in this agreement or as long as 
necessary to fulfill the legal or judicial obligations of the processor. The 
processor will inform the controller in advance if processing is required to 
meet the legal or judicial obligations of either party, unless prohibited by 
law.</p>

<p><strong>1.5</strong> The controller guarantees that its requests via the Platform comply 
with applicable data protection laws and acknowledges that the processor 
is not responsible to determine both which laws or regulations are 
applicable to the controller's business and whether the controller’s 
request(s) comply with applicable law.</p>

<p><strong>1.6</strong> Using the Software outside the EU or EEA may proceed only if the 
requirements of Article 44 of the GDPR are met.</p>

{/* <p ><strong>1.7</strong> Relocation of the LIBERDAT Services to a third country requires prior consent of the Controller and may take place if the requirements of Articles 44 of the GDPR are satisfied.</p> */}




<h2 >2. Subcontractors	</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>2.1</strong> The controller grants the processor rights to engage other 
subcontractors. Please refer to the Privacy Statement for a full list of 
subcontractors</p>

<p><strong>2.2</strong> The controller can object to the appointment or replacement of other 
subcontractors. In such case, Parties may terminate this Agreement, 
without judicial intervention and without compensation, with effect from 
the date on which the appointment or replacement takes effect.</p>

<p style={{marginBottom: '32px'}} ><strong>2.3</strong> If a subcontractor fails to fulfill its data protection obligations, the 
processor remains liable.</p>

<h2>3. Confidentiality</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>3.1</strong> The processor treats Personal Data strictly confidential and does not 
disclose it to third parties without prior controller consent unless required 
by legal or judicial obligation.</p>

<p><strong>3.2</strong> The processor discloses Personal Data of the controller to its 
employees, and subcontractors who are directly involved in the execution 
of this agreement.</p>

<p style={{marginBottom: '32px'}} ><strong>3.3</strong> The processor ensures that subcontractors authorised for Personal 
Data processing of the controller are obliged to maintain confidentiality 
under the GDPR via statutory obligation.</p>


<h2>4. Security</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>4.1</strong> The processor will ensure that it provides sufficient guarantees to 
implement appropriate technical and organisational measures to ensure 
that the processing complies with the requirements of the GDPR and that 
the data subject's rights are protected.</p>

<p><strong>4.2</strong> The processor will take all measures required under the GDPR Article 
32.</p>

<p><strong>4.3</strong> The data security measures may be correspondingly adapted to 
technical and organizational developments as long as they do not fall 
below the threshold levels stipulated herein. The processor will implement
any changes required to maintain information security without undue 
delay and taking into account the requirements of GDPR Article 32.</p>

{/* <p><strong>4.4</strong> At the Controller's request, the Processor will inform the Controller in writing of these measures as well as the measures taken by its sub-processors.</p>

<p style={{marginBottom: '32px'}}><strong>4.5</strong> The Processor will conform to the standards of approved codes of conduct and certification mechanisms as applicable within the industry. At the first request of the Controller, the Processor will provide proof thereof.</p> */}


<h2>5. Compliance</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>5.1</strong> The processor will make all information available to the controller to 
demonstrate compliance with GDPR Article 28 and will allow audits 
including inspections, by the controller or any other auditor authorized by 
the controller and cooperate with them in accordance with Article 7.</p>

{/* <p style={{marginBottom: '32px'}}><strong>5.2</strong> The Processor guarantees that there are no obligations arising from applicable legislation that make it impossible to comply with the obligations of this agreement. </p> */}

<h2>6. Information & assistance</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>6.1</strong> Assistance will be provided by the processor to aid  the controller with
appropriate technical and organizational measures, to fulfil the obligation 
of the controller to comply with requests for the exercise of the data 
subject's rights laid down in Chapter III GDPR, to the extent reasonably 
possible whilst taking into account the nature of the processing.</p>

<p><strong>6.2</strong> Assistance will be provided by the The Processor to aid the controller 
in complying with the obligations of the GDPR Articles 32 to 36,  to the 
extent reasonably possible whilst taking into account the nature of the 
processing and the information available to the processor.</p>

<p><strong>6.3</strong> The controller will be informed by the processor if a request violates 
the GDPR or any other Union or Member State law provision on data 
protection. In this case, the processor reserves the right to halt any 
undertaking in request of the controller until it is amended.</p>

{/* <p style={{marginBottom: '32px'}}><strong>6.4</strong> The Processor will inform the Controller immediately if, in its opinion, an instruction violates the GDPR or any other Union or Member State law provision on data protection. In this case, the Processor has the option of suspending the implementation of the instruction concerned until it is confirmed or amended by the Controller.</p> */}


<h2>7. Audit</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '22px'}}>Advanced notification for an audit is required by the controller to the 
processor of at least 3 weeks. Audits shall be carried out maximally twice 
per year and on working days. Audits are carried out at the expense of the
controller. The processor will inform the controller if it considers that an 
order violates the GDPR or other data protection legislation of the EU or a 
Member State</p>

<h2>8. Deletion</h2>
<hr style={{marginBottom: '18px'}}/>
<p style={{marginBottom: '22px'}}>The processor guarantees the deletion of all Personal Data of the 
controller when the controller account is deleted  unless a provision of 
Union or Member State law provides for the retention of such Personal 
Data.</p>

<h2>9. Data breaches</h2>
<hr style={{marginBottom: '18px'}}/>
<p><strong>9.1</strong> In the event of a Personal Data breach within the meaning of the 
GDPR Article 4.12 with respect to Personal Data of the controller 
processed by the processor or its sucontractors, the processor will inform 
the controller by e-mail, and the processor will provide all information 
necessary to enable the controller to fulfil its obligations under the GDPR 
Articles 33 and 34.</p>

<p><strong>9.2</strong> The processor will immediately take all measures necessary to limit 
and resolve the breach and will assist the controller and supervisory 
authorities in investigating the breach. These measures are taken in 
consultation with the controller unless in cases of extreme urgency which 
require the immediate intervention of the processor.</p>

<p><strong>9.3</strong> Unless required by law or expressly instructed in writing by the 
controller, the processor will not pass on any information regarding a 
Personal Data breach to any third party.</p>

<p style={{marginBottom: '22px'}}><strong>9.4</strong> The processor and its subcontractors will appoint among their staff a 
single point of contact who will be responsible for all communication 
between the processor, the subcontractor and the controller in the event 
of an incident which has led or may lead to an accidental or non-
authorized destruction or loss, or a non-authorized access, alteration or 
transmission of the Personal Data processed on behalf of the controller.</p>

 
<h2  style={{ textAlign: "center", margin: "45px" }}>Subappendix</h2>
<hr style={{marginBottom: '18px'}}/>

    <p style={{marginBottom: '7px'}}><strong >Personal Data:</strong></p>
    <br></br>
    <ul >
        <li>Identification Data</li>
        <ul style={{ listStyleType: 'disc' }} >
            <li>Username and password;</li>
            <li>Institution name, department name, and corresponding institutional 
e-mail address (Requesters)</li>
            <li>Bank account details (processed separately by the licensed third-
party Stripe, Inc.)</li>
            <li>IP address (for troubleshooting purposes only)</li>
        </ul>
        <li>User Data</li>
        <ul style={{ listStyleType: 'disc' }} >
            <li>Data requests from Requesters</li>
            <li>Profile data: General, Clinical, and Connected Applications data from
Sharers</li>
        </ul>
        <li>Exchanged Data</li>
        <ul style={{ listStyleType: 'disc' }} >
        <li>Profile data</li>
            <li>Questionnaire data</li>
        </ul>
    </ul>
    <p style={{marginBottom: '7px'}}><strong >Data subjects:</strong></p>
    <br></br>
    <ul style={{ listStyleType: 'disc' }} >
        <li>Users who register on the Platform as a Sharer.</li>
    </ul>
    <p style={{marginBottom: '7px'}}><strong >LIBERDAT performs the below-mentioned tasks on behalf of 
Controllers (Requesters)</strong></p>
    <br></br>
    <ul style={{ listStyleType: 'disc' }} >
        <li>Collection of consent from Sharers to process Personal Data;</li>
        <li>Standard analytics provided by the Software;</li>
        <li>Storage of Personal Data</li>
        <li>Temporary storage of Exchanged Data;</li>
        <li>Rendering Exchanged Data downloadable;</li>

    </ul>

<h1 style={{ textAlign: "center", margin: "45px" }}>Appendix 2.</h1>
<h1 style={{ textAlign: "center", margin: "45px" }}> STANDARD CONTRACTUAL CLAUSES</h1>
<hr style={{marginBottom: '18px'}}/>

   <p> LIBERDAT appeals to Module 4 of the Standard Contractual Clauses (SCCs)
for the international transfer of Exchanged Data. The SCCs refer to Module
4 of the Commission Implementing Decision (EU) 2021/914 of 4 June 2021
on standard contractual clauses for the transfer of personal data to third 
countries pursuant to Regulation (EU) 2016/679 of the European 
Parliament and of the Council.</p>


<p><strong>Regarding the SCCs:</strong></p>
<ul style={{ listStyleType: 'disc' }} >
    <li>LIBERDAT is the data exporter and its activities comprise the 
transfer of Exchanged Data as data processor to Requesters as data
controller;</li>
    <li>Requesters are data importers and their activities comprise 
processing Exchanged Data as data controllers;</li>
    <li>Controller and processor do not enter into a docking clause (Clause 
7(a)-(c));</li>
    <li>No agreement has been made on an additional redress mechanism 
for data subjects (Clause 11(a)) between the Parties;</li>
    <li>Dutch law applies.</li>
</ul>

<p><strong>Regarding Annex I.B:</strong></p>
<ul style={{ listStyleType: 'disc' }} >
    <li>Data subjects are referred to in the Data Processing Agreement;</li>
    <li>Personal Data is referred to in the Data Processing Agreement 
where it is sorted into categories;</li>
    <li>Depending on the amount of Agreements the controller and 
processor engage in, the frequency of the transfer can be either 
one-off (one Agreement) or continuous (multiple Agreements);</li>
    <li>The processing is described in the Terms & Conditions;</li>
    <li>The purpose of the transfer consists of the tasks as stated in the 
Data Processing Agreement;</li>
    <li>The retention period is set by the data controller who has bought 
the data;</li>
    <li>There is no transfer of Personal Data to subcontractors.</li>
</ul>

</div>
  )
}

export default TermsAndConditions