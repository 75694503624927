import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
export const Error = React.memo(() => {
  return (
    <div className="header">
      <h1 className="header-title">404</h1>
      <h1 className="sub-header">Oops! Page not found in LiberDat</h1>
      <p className="sub-text">
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </p>
      <Link to="/" className="button-primary">
        HOME
      </Link>
    </div>
  );
});
