import React from 'react';
import { PulseLoader } from 'react-spinners';

export const Loader = ({ padding, color }) => {
  return (
    <div style={{ margin: 'auto', display: 'flex', textAlign: 'center', justifyContent: 'center', padding, color: color}}>
      <PulseLoader  color={color} />
    </div>
  );
};
