import { init, setUserId, track, reset } from '@amplitude/analytics-browser';


// Initialize Amplitude

const devKey = "7bc46ccd5791fae4253c5fef8983de17"
const prodKey = '69fcd44911777a449219a13110a5b5ab'

export const initializeAmplitude = async () => {
  try {
     init(devKey,{
        defaultTracking: true,
     });
    console.log('Amplitude initialized successfully');
  } catch (error) {
    console.error('Amplitude initialization failed', error);
  }
};

// Track an event with optional event properties
export const trackEvent = async (eventName, eventProperties = {}) => {
  try {
     track(eventName, eventProperties);
    console.log(`Tracked event: ${eventName}`, eventProperties);
  } catch (error) {
    console.error(`Failed to track event: ${eventName}`, error);
  }
};


  export const setUserIdForTracking = async (userId) => {
    let modifiedUserId = userId;
  
    // Check if the userId is less than 5 characters
    if (userId && userId.length < 5) {
      modifiedUserId = `${userId}-uid`; // Append '-uid' or any string to make it 5+ chars
      console.warn(`User ID too short, modifying to: ${modifiedUserId}`);
    }
  
    try {
       setUserId(modifiedUserId);
      console.log(`User ID set for tracking: ${modifiedUserId}`);
    } catch (error) {
      console.error(`Failed to set User ID: ${modifiedUserId}`, error);
    }
  };

// Clear the user ID when logging out
export const clearUserIdForTracking = async () => {
    try {
    reset(); // Clears the user identity in Amplitude
      console.log('User ID cleared for tracking');
    } catch (error) {
      console.error('Failed to clear User ID', error);
    }
  };


