import React, { useEffect } from 'react';
// import './style.scss';
import { Images } from '../../assets/images';
import toast from 'react-hot-toast';

import { useVerifyAccountDeleteMutation } from '../../store/api';

const ConfirmDelete = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  console.log(token);

  // console.log(token);

  const [verifyConfirmDelete, { data:accountDeleteData, isSuccess:accountDelSuccess, isError, accountDelerror }] =
  useVerifyAccountDeleteMutation();

    useEffect(() => {
      const verifyAccountDeleteHandler = async () => {
        if (token ) {
          try {
            await verifyConfirmDelete(token);
          } catch (error) {
            console.error('Confirmation error:', error);
          }
        }
      };
    
      verifyAccountDeleteHandler(); 
    }, [ token ]); 
    

  return (

    <div className="back">
      <div className="containeremail">
        {
          accountDelSuccess ? (
            <>
              <h2>Account Delete Successfull</h2>
              <p>
                <b>Congrats!</b> Your account has been deleted
              </p>
              <img src={Images.textlogo} alt="Text Logo" />
            </>
          ) : accountDelerror ? (
            <>
              <h2 style={{ color: 'red' }}>Account Deletion Failed</h2>
              <p>
                <b>Your account has not been deleted</b>
              </p>
            
            </>
          ) : null 
        }
      </div>
    </div>
  );
};

export default ConfirmDelete;
