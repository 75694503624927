import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notiCount',
  initialState: {
   notificationCount:0
  },
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    clearNotificationCount: (state) => {
      state.notificationCount = 0;
    },
  },
});

export const { setNotificationCount ,clearNotificationCount} = notificationsSlice.actions;
export default notificationsSlice.reducer;
