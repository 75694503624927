import React, { useEffect } from 'react';
import './style.scss';
import { Images } from '../../assets/images';
import toast from 'react-hot-toast';

import { useVerifyEmailAddressMutation } from '../../store/api';

const EmailVerification = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  // console.log(token);

  const [verify, { data, isSuccess, isError, error }] =
    useVerifyEmailAddressMutation();

    useEffect(() => {
      const verifyEmailHandler = async () => {
        if (token ) {
          try {
            await verify(token);
          } catch (error) {
            console.error('Verification error:', error);
            // Handle error (e.g., display message, retry with delay)
          }
        }
      };
    
      verifyEmailHandler(); 
    }, [ token ]); 


    console.log("error", error)
    

  return (
    //         <div className="container">
    //       <img src={Images.liberdaticon} ></img>
    //     <h2>Email Verification</h2>
    //     <p>Thank you for signing up! To complete the registration process, please verify your email address by clicking the link below:</p>
    //     <a href="VERIFICATION_LINK" className="verification-link">Verify Email</a>
    //     <p className="footer">If you didn't sign up for this service, you can safely ignore this email.</p>
    //     <a href="VERIFICATION_LINK" className="verification-link unsubscribe">Unsubscribe</a>

    // </div>

    <div className="back">
      <div className="containeremail">
        {
          isSuccess ? (
            <>
              <h2>Email Verification Successful</h2>
              <p>
                <b>Congrats!</b> Your email has been verified.
              </p>
              <img src={Images.textlogo} alt="Text Logo" />
            </>
          ) : isError ? (
            <>
              <h2 style={{ color: 'red' }}>Email Verification Failed</h2>
              <p>
                <b>Your email has not been verified.</b>
              </p>
            
            </>
          ) : null 
        }
      </div>
    </div>
  );
};

export default EmailVerification;
