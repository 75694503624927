import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import * as Yup from "yup"; // Import Yup
import CustomTextField from "../../components/customtextfield/CustomTextField";
import CustomButton from "../../components/custombutton/CustomButton";
import RouteNames from "../../routes/RouteNames";
import { Images } from "../../assets/images";
import "./style.scss";

import { Loader } from "../../components/Loader";
import {
  useCreateBidMutation,
  useGetMyBidsQuery,
  useLoginMutation,
  useForgetPasswordMutation,
} from "../../store/api";
import { setUser } from "../../store/reducers/authSlice";

import { ErrorMessage, Formik, useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

export default function ForgetPassword() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handleSubmit = (values) => {
    console.log("values", values);
  };
  const handlePrivacyChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };

  const [
    forgetPasswordMutation,
    { isError, isSuccess, isLoading, error, data },
  ] = useForgetPasswordMutation();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await forgetPasswordMutation(values);
    } catch (error) {
      toast.error(error);
    }

    resetForm();
  };
  const initialValues = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login_body">
      <div className="logo">
        <div className="backarrow" onClick={handleClick}>
          <IoMdArrowRoundBack
            size={20}
            style={{ color: "#5B99A6", cursor: "pointer" }}
          />
        </div>

        <img src={Images.logo_} alt="LiberDat" className="logoimage" />
      </div>

      <Form
        className="container1"
        style={{ paddingTop: "40px" }}
        onSubmit={formik.handleSubmit}
      >
        <div style={{ width: "80%" }}>
          <CustomTextField
            name="email"
            label="Email Address"
            type="text"
            placeholder="Enter your email address"
            formik={formik}
          />

          <div className="login-link">
            <br />
          </div>
        </div>
        <CustomButton
          style={{ paddingBottom: "15px" }}
          label={"Send Verification"}
          color={"#333333"}
          textcolor={"white"}
          backgroundColor={"#333333"}
          type="submit"
          width="80%"
        />
      </Form>
      <p>
        Create a new Account?&nbsp;
        <span
          onClick={() => navigate(RouteNames.Signup.route)}
          style={{ color: "#5B99A6", cursor: "pointer" }}
        >
          Sign up
        </span>
      </p>
      <p>
        <img src={Images.liberdaticon} alt="LiberDat" className="lowerimage" />
      </p>
    </div>
  );
}
