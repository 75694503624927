import React from "react";
// import "./style.scss";

const FAQs = () => {
  return (
    <div className="container22">
      <h1 style={{ textAlign: "center", margin: "30px" }}>
        Frequently asked questions
      </h1>

      <hr style={{ marginBottom: '18px' }} />

      <div className="faq">
        <strong>Can my data be accessed without my permission?</strong>
        <p>
          No, you are explicitly asked for permission before every data exchange.
        </p>
      </div>

      <div className="faq">
        <strong>Does LIBERDAT sell my data?</strong>
        <p>
          No, LIBERDAT does not sell your data nor does it allow third parties to do so.
        </p>
      </div>

      <div className="faq">
        <strong>Does LIBERDAT process data that is traceable to me?</strong>
        <p>
          No, LIBERDAT does not process identifiable information such as your name, email address, birth date, full postal code etc.
        </p>
      </div>

      <div className="faq">
        <strong>What is LIBERDAT’s business model?</strong>
        <p>
          Liberdat utilizes a transaction-based business model whereby its users are the prime beneficiaries of data exchanges. Every time a user decides to engage in a monetary data transaction, Liberdat wil hold back a minor percentage of the monetary value of that data exchange.
        </p>
      </div>

      <div className="faq">
        <strong>Why is Liberdat a marketplace?</strong>
        <p>
          Firstly, to enable a transaction-based business model whereby Liberdat’s users are the prime beneficiaries. Secondly, a marketplace allows for repeated data exchanges which leads to accrued value for users. Finally, a marketplace promotes a transparent environment which facilitates safe and secure data exchanges.
        </p>
      </div>

      <div className="faq">
        <strong>Why can I receive money for exchanging my data?</strong>
        <p>
          As a user of the Liberdat platform, you own your data and therefore can opt to receive a monetary compensation for data exchanges.
        </p>
      </div>

      <div className="faq">
        <strong>Do I benefit from using the LIBERDAT application?</strong>
        <p>
          Yes, by collecting and sharing your data you gain valuable personal insights.
        </p>
      </div>

      <div className="faq">
        <strong>Is my data safe if I use the LIBERDAT application?</strong>
        <p>
          Yes, LIBERDAT is a GDPR-compliant platform and contracts with third parties, such as Amazon Web Services and MongoDB Inc., which are ISO-27001 certified.
        </p>
      </div>

      <div className="faq">
        <strong>What happens if I stop using the LIBERDAT application?</strong>
        <p>
          You will no longer be able to share data for valuable insights.
        </p>
      </div>

      <div className="faq">
        <strong>How do I delete my account?</strong>
        <p>
          At any moment in time you can delete your account either manually
          (Profile -&gt; Setting -&gt; Delete account) or you can send an email
          with the request to delete your account to{" "}
          <a href="mailto:info@liberdat.io">info@liberdat.io</a>.
        </p>
      </div>

      {/* <div className="faq">
        <strong>Why are data exchanges visible on the website?</strong>
        <p>
        LIBERDAT promotes a transparent environment in order to facilitate safe and secure data exchanges.
        </p>
      </div> */}

    </div>
  );
};

export default React.memo(FAQs);
