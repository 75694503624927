

import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import CustomButton from "../../components/custombutton/CustomButton";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const FilterContent = ({
  item,
  subOptionsContent,
  selectedSubOptions,
  handleSubOptionClick,
  applyFilters,
  handleReset,
  subOptions,
  selectedDropdownItem,
  setSelectedDropdownItem,
  institutesData,
  departmentsData,
  formik,
}) => {
  return (
    <div className="content">
      <Form onSubmit={applyFilters} className="" style={{ padding: "25px" }}>
        <Row>
          {item === "Institution" ? (
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Institution</Form.Label>
                <Form.Select
                  name="institution"
                  id="institution"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.institution}
                >
                  <option value="" disabled>
                    Select an institution
                  </option>
                  {institutesData &&
                    institutesData.map((institute, index) => (
                      <option key={index} value={institute}>
                        {institute}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          ) : item === "Country of Residence" ? (
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  id="country"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="" disabled>
                    Select a country
                  </option>
                  <option value="Netherlands">Netherlands</option>
                  {/* Add more countries as needed */}
                </Form.Select>
              </Form.Group>
            </Col>
          ) : selectedDropdownItem ? (
            subOptions[selectedDropdownItem].map((subItem, index) => (
              <Col key={index} xs={12}>
                <Form.Check
                  type="checkbox"
                  label={subItem}
                  checked={selectedSubOptions.includes(subItem)}
                  onChange={() => handleSubOptionClick(item, subItem)}
                />
              </Col>
            ))
          ) : (
            subOptionsContent.map((subItem, index) => (
              <Col key={index} xs={12}>
                <p
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => setSelectedDropdownItem(subItem)}
                >
                  {subItem}
                </p>
              </Col>
            ))
          )}
          {item === "Department" && (
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <Form.Select
                  name="department"
                  id="department"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                >
                  <option value="" disabled>
                    Select a department
                  </option>
                  {departmentsData &&
                    departmentsData.map((department, index) => (
                      <option key={index} value={department}>
                        {department}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          {selectedDropdownItem && (
            <Col xs={12}>
              <p
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => setSelectedDropdownItem(null)}
              >
                Back
              </p>
            </Col>
          )}
          <Col xs={6}>
            <CustomButton
              className="Btn1"
              label={"Save"}
              color={"#333333"}
              textcolor={"white"}
              width={"100%"}
              backgroundColor={"#333333"}
              type="submit"
            />
          </Col>
          <Col xs={6}>
            <CustomButton
              className="btn1"
              label={"Reset"}
              color={"#333333"}
              textcolor={"black"}
              width={"100%"}
              backgroundColor={"white"}
              border={"2px solid black"}
              onClick={handleReset}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterContent;

