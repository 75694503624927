import React, { useEffect, useState } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import RouteNames from "./routes/RouteNames";
import Header from "./components/header";
import BuyForm from "./pages/buy/BuyForm";
import MyLiberDat from "./components/myliberdat/MyLiberDat";
import WalletPage from "./pages/wallet/Wallet";
import MyBids from "./pages/bids/Bids";
import AssignUser from "./pages/bids/AssignUser";
import Home from "./pages/home/Home";
import { Error } from "./pages/notfound/Error";
import { Notification } from "./pages/Notification/Notification";
import { Messages } from "./pages/messages/Messages";
import { Innerheader } from "./components/innerheader/Innerheader";
import { Datapage } from "./pages/datapage/Datapage";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import Footer from "./components/footer/Footer";
import About from "./pages/about/About";
import { Toaster } from "react-hot-toast";
import UpdateBids from "./pages/updatebids/UpdateBids";
import Acceptbidreq from "./pages/requests/Acceptbidreq";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/termsandconditions/TermsAndConditions";
import SurveyComponent from "./components/surveyjs/SurveyComponent";
import SurveyViewer from "./components/surveyjs/SurveyViewer";
import CSV from "./pages/csv/CSV";
import SurveyCompleted from "./components/surveyjs/SurveyCompleted";
import MobileApp from "./pages/mobileapp/MobileApp";
import { HowItWorks } from "./pages/howitworks/HowItWorks";

import GeneralInfo from "./components/myliberdat/generalInfo/GeneralInfo";
import BuyerInfo from "./components/myliberdat/buyerInfo/BuyerInfo";
import ProfileInfo from "./pages/profileInfo/ProfileInfo";
import EmailVerification from "./components/emailverification/EmailVerification";
import FAQs from "./pages/faq's/FAQs";

import Team from "./pages/team/Team";
import DeleteAccount from "./pages/deleteAccount/DeleteAccount";
import DeleteByEmail from "./pages/deleteByEmail/DeleteByEmail";
import ConfirmDelete from "./pages/confirmDelete/ConfirmDelete";
import AuthSuccess from "./pages/authSuccess/authSuccess";
import UserPrediction from "./pages/userPrediction/UserPrediction";
import store, { persistor } from "./store";
import socket from "./store/actions/socket";
import { initializeAmplitude } from "./utils/amplitude";
import ErrorBoundaryComponent from "./components/ErrorBoundry";
import { useSelector, useDispatch } from 'react-redux';
import LogoutModal from "./components/logoutmodal/LogoutModal";
import { clearUser } from './store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import Authentication from "./pages/authentication/Authentication";
import ProtectedRoute from "./ProtectedRoutes";
import PrivacyP from "./pages/privacypolicy/PrivacyP";


function App() {

  socket.getSocket();
  initializeAmplitude()




  return (
    <ErrorBoundaryComponent>
      <Provider store={store}>
        <Router>
          <PersistGate loading={null} persistor={persistor}>
            <Toaster />
            <AppContent />
          </PersistGate>
        </Router>
      </Provider>
    </ErrorBoundaryComponent>
  );
}


function AppContent() {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const tokenExpiry = useSelector((state) => state?.auth?.user?.tokens?.access?.expires);
  const user = useSelector((state) => state?.auth?.user?.user);

  useEffect(() => {
    if (tokenExpiry && new Date().toISOString() >= tokenExpiry) {
      setLogoutModalVisible(true);
    }
  }, [tokenExpiry]);

  const closeLogoutModal = () => {
    setLogoutModalVisible(false);
  };

  const location = useLocation();
  const hideInnerHeaderRoutes = [
    RouteNames.Login.route,
    RouteNames.ForgetPassword.route,
    RouteNames.ResetPassword.route,
    RouteNames.Signup.route,
    RouteNames.Home.route,
    RouteNames.Authentication.route,
  ];

  const shouldShowInnerHeader = !hideInnerHeaderRoutes.includes(
    location.pathname
  );

  return (
    <>
      <LogoutModal visible={logoutModalVisible} onClose={closeLogoutModal} />
      {shouldShowInnerHeader && <Header />}

      <Routes>
        <Route path={RouteNames.Default.route} element={<HowItWorks />} />
        <Route path={RouteNames.Home.route} element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path={RouteNames.Buy.route} element={<ProtectedRoute>
          <BuyForm />
        </ProtectedRoute>
        } />
        <Route path={RouteNames.Myliberdat.route} element={<ProtectedRoute><MyLiberDat /></ProtectedRoute>} />
        <Route path={RouteNames.Wallet.route} element={<ProtectedRoute><WalletPage /></ProtectedRoute>} />
        <Route path={RouteNames.Login.route} element={<Login />} />
        <Route path={RouteNames.Authentication.route} element={<Authentication />} />
        <Route path={RouteNames.Signup.route} element={<Signup />} />
        <Route
          path={RouteNames.ForgetPassword.route}
          element={<ForgetPassword />}
        />
        <Route
          path={RouteNames.ResetPassword.route}
          element={<ResetPassword />}
        />
        <Route path={RouteNames.MyBids.route} element={<ProtectedRoute><MyBids /></ProtectedRoute>} />
        <Route path={RouteNames.AssignUser.route} element={<ProtectedRoute><AssignUser /></ProtectedRoute>} />
        <Route path={RouteNames.Datapage.route} element={<ProtectedRoute><Datapage /></ProtectedRoute>} />
        <Route path={RouteNames.About.route} element={<About />} />
        <Route path={RouteNames.Team.route} element={<Team />} />
        <Route
          path={RouteNames.PrivacyPolicy.route}
          element={<PrivacyPolicy />}
        />
        <Route
          path={RouteNames.PrivacyP.route}
          element={<PrivacyP />}
        />
        <Route
          path={RouteNames.TermsAndConditions.route}
          element={<TermsAndConditions />}
        />
        <Route path={RouteNames.Survey.route} element={<ProtectedRoute><SurveyComponent /></ProtectedRoute>} />
        <Route
          path={RouteNames.SurveyViewer.route}
          element={<ProtectedRoute><SurveyViewer /></ProtectedRoute>}
        />
        <Route path="/edit-survey/:surveyId" element={<SurveyComponent />} />
        <Route path={RouteNames.CSV.route} element={<ProtectedRoute><CSV /></ProtectedRoute>} />
        <Route
          path={RouteNames.SurveyCompleted.route}
          element={<SurveyCompleted />}
        />
        <Route path={RouteNames.MobileApp.route} element={<MobileApp />} />
        <Route path={RouteNames.HowItWorks.route} element={<HowItWorks />} />

        <Route path={RouteNames.UpdateBids.route} element={<UpdateBids />} />
        <Route path={RouteNames.GeneralInfo.route} element={<ProtectedRoute><GeneralInfo /></ProtectedRoute>} />
        <Route path={RouteNames.BuyerInfo.route} element={<ProtectedRoute><BuyerInfo /></ProtectedRoute>} />
        <Route path={RouteNames.ProfileInfo.route} element={<ProtectedRoute><ProfileInfo /></ProtectedRoute>} />
        <Route
          path={RouteNames.EmailVerification.route}
          element={<EmailVerification />}
        />

        <Route path={RouteNames.FAQs.route} element={<FAQs />} />

        <Route
          path={RouteNames.DeleteAccount.route}
          element={<ProtectedRoute><DeleteAccount /></ProtectedRoute>}
        />
        <Route
          path={RouteNames.DeleteByEmail.route}
          element={<ProtectedRoute><DeleteByEmail /></ProtectedRoute>}
        />




        <Route
          path={RouteNames.ConfirmDelete.route}
          element={<ConfirmDelete />}
        />

        <Route
          path={RouteNames.AcceptBidReq.route}
          element={<Acceptbidreq />}
        />
        <Route
          path={RouteNames.Notifications.route}
          element={<Notification />}
        />
        <Route path={RouteNames.AuthSucces.route} element={<AuthSuccess />} />
        <Route
          path={RouteNames.UserPrediction.route}
          element={<UserPrediction />}
        />

        <Route path={RouteNames.Messages.route} element={<Messages />} />
        <Route path={RouteNames.PageNotFound.route} element={<Error />} />
      </Routes>
      <Footertab />
    </>
  );
}

function Footertab() {
  const location = useLocation();
  const hideBottomTabRoutes = [
    RouteNames.Login.route,
    RouteNames.ForgetPassword.route,
    RouteNames.ResetPassword.route,
    RouteNames.Signup.route,

    // Add other routes where you want to hide the BottomTab
  ];

  const shouldShowBottomTab = !hideBottomTabRoutes.includes(location.pathname);

  return shouldShowBottomTab ? <Footer /> : null;
}

export default React.memo(App);
