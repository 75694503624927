import React from "react";
import "./style.scss";
import { Images } from "../../assets/images";

const Team = () => {
  return (
    <>
      <div className="team-member">
        {/* <h2 className="teamHeading">Team</h2> */}
        <img src={Images.ShahroezPeraee} alt="Team Member" />
        <div className="information">
          <h4>Shahroez Peraee</h4>
          <h6>Founder and CEO</h6>
          <p>
            Health-Tech entrepreneur with an educational background in
            biomedical sciences and a career spanning multiple years of software
            implementation at various institutes. On a mission to improve lives
            through democratizing data ownership.
          </p>
        </div>
      </div>
    </>
  );
};

export default Team;
