const dataItemsforGeneralInfo = () => {
  const dataItemsforGeneralInfo = [
    {
      label: "Current Therapy",

      value: "Therapies_Current",
      parent: "Therapies",
      children: [
        {
          label: "Pharmaceutical",

          value: "Therapies_Current_Pharmaceutical",
          parent: "Therapies_Current",
          children: [
            {
              label: "Tablet",

              value: "Therapies_Current_Pharmaceutical_Tablet",
              parent: "Therapies_Current_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Current_Pharmaceutical_Tablet_No other",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Mavenclad(cladribine) 10mg",
                  displayName: "Current - Mavenclad(cladribine) 10mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Mavenclad",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Tecfidera(dimethyl fumarate) 240mg",
                  displayName: "Current - Tecfidera(dimethyl fumarate) 240mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Tecfidera",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Vumerity(diroximel fumarate) 231mg",
                  displayName: "Current - Vumerity(diroximel fumarate) 231mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Vumerity",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Gilenya(fingolimod) 0.5mg",
                  displayName: "Current - Gilenya(fingolimod) 0.5mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Gilenya",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Tascenso(fingolimod) 0.5mg",
                  displayName: "Current - Tascenso(fingolimod) 0.5mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Tascenso",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Bafiertam(monomethyl fumarate) 95mg",
                  displayName: "Current - Bafiertam(monomethyl fumarate) 95mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Bafiertam",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Zeposia(ozanimod) 0.92mg",
                  displayName: "Current - Zeposia(ozanimod) 0.92mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Zeposia",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Ponvory(ponesimod) 20mg",
                  displayName: "Current - Ponvory(ponesimod) 20mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Ponvory",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Mayzent(siponimod) 2mg",
                  displayName: "Current - Mayzent(siponimod) 2mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Mayzent",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
                {
                  label: "Aubagio(teriflunomide) 14mg",
                  displayName: "Current - Aubagio(teriflunomide) 14mg",

                  value: "Therapies_Current_Pharmaceutical_Tablet_Aubagio",
                  parent: "Therapies_Current_Pharmaceutical_Tablet",
                },
              ],
            },
            {
              label: "Injection",

              value: "Therapies_Current_Pharmaceutical_Injection",
              parent: "Therapies_Current_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Current_Pharmaceutical_Injection_No other",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  label: "Purified Cortrophin Gel(corticotropin) 80mL",
                  displayName:
                    "Current - Purified Cortrophin Gel(corticotropin) 80mL",

                  value:
                    "Therapies_Current_Pharmaceutical_Injection_Corticotropin",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Copaxone(glatiramer acetate) 40mL",
                  label: "Copaxone(glatiramer acetate) 40mL",

                  value: "Therapies_Current_Pharmaceutical_Injection_Copaxone",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Avonex(interferon β-1a) 0.5mL",
                  label: "Avonex(interferon β-1a) 0.5mL",

                  value: "Therapies_Current_Pharmaceutical_Injection_Avonex",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Rebif(interferon β-1a) 0.5mL",
                  label: "Rebif(interferon β-1a) 0.5mL",

                  value: "Therapies_Current_Pharmaceutical_Injection_Rebif",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Betaseron(interferon β-1b) 0.3mg",
                  label: "Betaseron(interferon β-1b) 0.3mg",

                  value: "Therapies_Current_Pharmaceutical_Injection_Betaseron",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Extavia(interferon β-1b) 0.3mg",
                  label: "Extavia(interferon β-1b) 0.3mg",

                  value: "Therapies_Current_Pharmaceutical_Injection_Extavia",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName: "Current - Kesimpta(ofatumumab) 20mg/0.4mL",
                  label: "Kesimpta(ofatumumab) 20mg/0.4mL",

                  value: "Therapies_Current_Pharmaceutical_Injection_Kesimpta",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
                {
                  displayName:
                    "Current - Plegridy(peginterferon β‑1a) 125mcg/0.5mL",
                  label: "Plegridy(peginterferon β‑1a) 125mcg/0.5mL",

                  value: "Therapies_Current_Pharmaceutical_Injection_Plegridy",
                  parent: "Therapies_Current_Pharmaceutical_Injection",
                },
              ],
            },
            {
              label: "Infusion",

              value: "Therapies_Current_Pharmaceutical_Infusion",
              parent: "Therapies_Current_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Current_Pharmaceutical_Infusion_No other",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
                {
                  displayName: "Current - Lemtrada(alemtuzumab) 12mg/1.2mL",
                  label: "Lemtrada(alemtuzumab) 12mg/1.2mL",

                  value: "Therapies_Current_Pharmaceutical_Infusion_Lemtrada",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
                {
                  displayName: "Current - Mitoxantrone 2mg/mL",
                  label: "Mitoxantrone 2mg/mL",

                  value:
                    "Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
                {
                  displayName: "Current - Tysabri(natalizumab) 300mg/15mL",
                  label: "Tysabri(natalizumab) 300mg/15mL",

                  value: "Therapies_Current_Pharmaceutical_Infusion_Tysabri",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
                {
                  displayName: "Current - Ocrevus(ocrelizumab) 30mg/mL",
                  label: "Ocrevus(ocrelizumab) 30mg/mL",

                  value: "Therapies_Current_Pharmaceutical_Infusion_Ocrevus",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
                {
                  displayName: "Current - Briumvi(ublituximab-xiiy) 25mg/mL",
                  label: "Briumvi(ublituximab-xiiy) 25mg/mL",

                  value: "Therapies_Current_Pharmaceutical_Infusion_Briumvi",
                  parent: "Therapies_Current_Pharmaceutical_Infusion",
                },
              ],
            },
          ],
        },
        {
          label: "Surgical",

          value: "Therapies_Current_Surgical",
          parent: "Therapies_Current",
          children: [
            {
              displayName: "Current - Scalpel Incisions",
              label: "Scalpel Incisions",

              value: "Therapies_Current_Surgical_Scalpel",
              parent: "Therapies_Current_Surgical",
            },
            {
              displayName: "Current - Laparoscopic",
              label: "Laparoscopic",

              value: "Therapies_Current_Surgical_Laparoscopic",
              parent: "Therapies_Current_Surgical",
            },
            {
              displayName: "Current - Suture Closure",
              label: "Suture Closure",

              value: "Therapies_Current_Surgical_Suture",
              parent: "Therapies_Current_Surgical",
            },
          ],
        },
        {
          label: "Rehabilitation",

          value: "Therapies_Current_Rehabilitation",
          parent: "Therapies_Current",
          children: [
            {
              displayName: "Current - Physical Therapy",
              label: "Physical Therapy",

              value: "Therapies_Current_Rehabilitation_Physical",
              parent: "Therapies_Current_Rehabilitation",
            },
            {
              displayName: "Current - Occupational Therapy",
              label: "Occupational Therapy",

              value: "Therapies_Current_Rehabilitation_Occupational",
              parent: "Therapies_Current_Rehabilitation",
            },
            {
              displayName: "Current - Speech and Language",
              label: "Speech and Language",

              value: "Therapies_Current_Rehabilitation_Speech",
              parent: "Therapies_Current_Rehabilitation",
            },
          ],
        },
        {
          label: "Psychological",

          value: "Therapies_Current_Psychological",
          parent: "Therapies_Current",
          children: [
            {
              displayName: "Current - Cognitive Behavioral Therapy (CBT)",
              label: "Cognitive Behavioral Therapy (CBT)",

              value: "Therapies_Current_Psychological_(CBT)",
              parent: "Therapies_Current_Psychological",
            },
            {
              displayName: "Current - Humanistic Therapy",
              label: "Humanistic Therapy",

              value: "Therapies_Current_Psychological_Humanistic",
              parent: "Therapies_Current_Psychological",
            },
            {
              displayName: "Current - Psychodrama",
              label: "Psychodrama",

              value: "Therapies_Current_Psychological_Psychodrama",
              parent: "Therapies_Current_Psychological",
            },
          ],
        },
      ],
    },
    {
      label: "Previous Therapy",

      value: "Therapies_Previous",
      parent: "Therapies",
      children: [
        {
          label: "Pharmaceutical",

          value: "Therapies_Previous_Pharmaceutical",
          parent: "Therapies_Previous",
          children: [
            {
              label: "Tablet",

              value: "Therapies_Previous_Pharmaceutical_Tablet",
              parent: "Therapies_Previous_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_No other",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                },
                {
                  label: "Mavenclad(cladribine) 10mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Mavenclad",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Mavenclad(cladribine) 10mg",
                },
                {
                  label: "Tecfidera(dimethyl fumarate) 240mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Tecfidera",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Tecfidera(dimethyl fumarate) 240mg",
                },
                {
                  label: "Vumerity(diroximel fumarate) 231mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Vumerity",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Vumerity(diroximel fumarate) 231mg",
                },
                {
                  label: "Gilenya(fingolimod) 0.5mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Gilenya",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Gilenya(fingolimod) 0.5mg",
                },
                {
                  label: "Tascenso(fingolimod) 0.5mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Tascenso",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Tascenso(fingolimod) 0.5mg",
                },
                {
                  label: "Bafiertam(monomethyl fumarate) 95mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Bafiertam",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Bafiertam(monomethyl fumarate) 95mg",
                },
                {
                  label: "Zeposia(ozanimod) 0.92mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Zeposia",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Zeposia(ozanimod) 0.92mg",
                },
                {
                  label: "Ponvory(ponesimod) 20mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Ponvory",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Ponvory(ponesimod) 20mg",
                },
                {
                  label: "Mayzent(siponimod) 2mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Mayzent",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Mayzent(siponimod) 2mg",
                },
                {
                  label: "Aubagio(teriflunomide) 14mg",

                  value: "Therapies_Previous_Pharmaceutical_Tablet_Aubagio",
                  parent: "Therapies_Previous_Pharmaceutical_Tablet",
                  displayName: "Previous - Aubagio(teriflunomide) 14mg",
                },
              ],
            },
            {
              label: "Injection",

              value: "Therapies_Previous_Pharmaceutical_Injection",
              parent: "Therapies_Previous_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Previous_Pharmaceutical_Injection_No other",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                },
                {
                  label: "Purified Cortrophin Gel(corticotropin) 80mL",

                  value:
                    "Therapies_Previous_Pharmaceutical_Injection_Corticotropin",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName:
                    "Previous - Purified Cortrophin Gel(corticotropin) 80mL",
                },
                {
                  label: "Copaxone(glatiramer acetate) 40mL",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Copaxone",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Copaxone(glatiramer acetate) 40mL",
                },
                {
                  label: "Avonex(interferon β-1a) 0.5mL",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Avonex",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Avonex(interferon β-1a) 0.5mL",
                },
                {
                  label: "Rebif(interferon β-1a) 0.5mL",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Rebif",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Rebif(interferon β-1a) 0.5mL",
                },
                {
                  label: "Betaseron(interferon β-1b) 0.3mg",

                  value:
                    "Therapies_Previous_Pharmaceutical_Injection_Betaseron",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Betaseron(interferon β-1b) 0.3mg",
                },
                {
                  label: "Extavia(interferon β-1b) 0.3mg",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Extavia",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Extavia(interferon β-1b) 0.3mg",
                },
                {
                  label: "Kesimpta(ofatumumab) 20mg/0.4mL",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Kesimpta",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName: "Previous - Kesimpta(ofatumumab) 20mg/0.4mL",
                },
                {
                  label: "Plegridy(peginterferon β‑1a) 125mcg/0.5mL",

                  value: "Therapies_Previous_Pharmaceutical_Injection_Plegridy",
                  parent: "Therapies_Previous_Pharmaceutical_Injection",
                  displayName:
                    "Previous - Plegridy(peginterferon β‑1a) 125mcg/0.5mL",
                },
              ],
            },
            {
              label: "Infusion",

              value: "Therapies_Previous_Pharmaceutical_Infusion",
              parent: "Therapies_Previous_Pharmaceutical",
              children: [
                {
                  label: "No other",

                  value: "Therapies_Previous_Pharmaceutical_Infusion_No other",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                },
                {
                  label: "Lemtrada(alemtuzumab) 12mg/1.2mL",

                  value: "Therapies_Previous_Pharmaceutical_Infusion_Lemtrada",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                  displayName: "Previous - Lemtrada(alemtuzumab) 12mg/1.2mL",
                },
                {
                  label: "Mitoxantrone 2mg/mL",

                  value:
                    "Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                  displayName: "Previous - Mitoxantrone 2mg/mL",
                },
                {
                  label: "Tysabri(natalizumab) 300mg/15mL",

                  value: "Therapies_Previous_Pharmaceutical_Infusion_Tysabri",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                  displayName: "Previous - Tysabri(natalizumab) 300mg/15mL",
                },
                {
                  label: "Ocrevus(ocrelizumab) 30mg/mL",

                  value: "Therapies_Previous_Pharmaceutical_Infusion_Ocrevus",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                  displayName: "Previous - Ocrevus(ocrelizumab) 30mg/mL",
                },
                {
                  label: "Briumvi(ublituximab-xiiy) 25mg/mL",

                  value: "Therapies_Previous_Pharmaceutical_Infusion_Briumvi",
                  parent: "Therapies_Previous_Pharmaceutical_Infusion",
                  displayName: "Previous - Briumvi(ublituximab-xiiy) 25mg/mL",
                },
              ],
            },
          ],
        },
        {
          label: "Surgical",

          value: "Therapies_Previous_Surgical",
          parent: "Therapies_Previous",
          children: [
            {
              label: "Scalpel Incisions",

              value: "Therapies_Previous_Surgical_Scalpel",
              parent: "Therapies_Previous_Surgical",
              displayName: "Previous - Scalpel Incisions",
            },
            {
              label: "Laparoscopic",

              value: "Therapies_Previous_Surgical_Laparoscopic",
              parent: "Therapies_Previous_Surgical",
              displayName: "Previous - Laparoscopic",
            },
            {
              label: "Suture Closure",

              value: "Therapies_Previous_Surgical_Suture",
              parent: "Therapies_Previous_Surgical",
              displayName: "Previous - Suture Closure",
            },
          ],
        },
        {
          label: "Rehabilitation",

          value: "Therapies_Previous_Rehabilitation",
          parent: "Therapies_Previous",
          children: [
            {
              label: "Physical Therapy",

              value: "Therapies_Previous_Rehabilitation_Physical",
              parent: "Therapies_Previous_Rehabilitation",
              displayName: "Previous - Physical Therapy",
            },
            {
              label: "Occupational Therapy",

              value: "Therapies_Previous_Rehabilitation_Occupational",
              parent: "Therapies_Previous_Rehabilitation",
              displayName: "Previous - Occupational Therapy",
            },
            {
              label: "Speech and Language",

              value: "Therapies_Previous_Rehabilitation_Speech",
              parent: "Therapies_Previous_Rehabilitation",
              displayName: "Previous - Speech and Language",
            },
          ],
        },
        {
          label: "Psychological",

          value: "Therapies_Previous_Psychological",
          parent: "Therapies_Previous",
          children: [
            {
              label: "Cognitive Behavioral Therapy (CBT)",

              value: "Therapies_Previous_Psychological_(CBT)",
              parent: "Therapies_Previous_Psychological",
              displayName: "Previous - Cognitive Behavioral Therapy (CBT)",
            },
            {
              label: "Humanistic Therapy",

              value: "Therapies_Previous_Psychological_Humanistic",
              parent: "Therapies_Previous_Psychological",
              displayName: "Previous - Humanistic Therapy",
            },
            {
              label: "Psychodrama",

              value: "Therapies_Previous_Psychological_Psychodrama",
              parent: "Therapies_Previous_Psychological",
              displayName: "Previous - Psychodrama",
            },
          ],
        },
      ],
    },
  ];

  return dataItemsforGeneralInfo;
};

export default dataItemsforGeneralInfo;
