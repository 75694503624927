import io from "socket.io-client";
import store from "..";
import { setNotificationCount } from "../reducers/notificationsSlice";


let socket = null;

const getSocket = () => {
  return socket !== null ? socket : initialize();
};
export const disconnectSocket = () => {
  if (socket !== null) {
    socket.disconnect(true);
    socket = null;
  }
};
export const initialize = () => {
  try {
    console.log("Initializing socket");

    const { getState } = store
    const currentUser = getState().auth?.user?.user;
    console.log("Current User:", currentUser);
    if (socket !== null) return socket;
    if (currentUser) {
      setTimeout(() => {
        socket = io("https://api.liberdat.io", {
          query: currentUser,
          reconnectionAttempts: Infinity,
          autoConnect: true,
          transports: ['websocket'],
        });
        socket.on("connect", () => {
          console.log("Connection established");
        });
        socket.on("notificationCount", (count) => {
          console.log("Notification count:", count);
          store.dispatch(setNotificationCount(count?.count));
        });
        socket.on("connect_error", (err) => {
          console.log(`Connect error due to ${err.message}`);
        });
        return socket;
      }, 500);
    }
  } catch (error) {
    console.log("Socket Initialization Error:", error);
  }
};


export default { initialize, getSocket, disconnectSocket }