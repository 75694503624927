import twiter from "./1.jpg";
import phone from "./2.jpg";
import signal from "./3.jpg";

import birth from "./5.jpg";

import ques from "./7.jpg";

import apple from "./apple-pay-og-twitter.jpg";
import icon from "./Icon.png";
import ideal from "./ideal-logo.png";
import visa from "./visa.png";
import playstore from "./playstore.png";
import appstore from "./appstore.png";
import en from "./USA.png";
import nl from "./nl.png";
import wallet2 from "./img5.png";
import paypal from "./paypal.png";
import arrows from "./arrows.gif";
import Coverpage from "./Coverpage.jpg";
import Home from "./Home.jpg";
import mobileapp from "./mobileapp.png";
import mb2 from "./mb2.jpg";
import textlogo from "./textlogo.png";

import liberdaticon from "./liberdaticon.png";

import heart from "./heart.png";
import question from "./question.png";
import card from "./card.png";
import mobile from "./mobile.png";
import menu from "./menu.png";
import search from "./search.png";
import wifi from "./wifi.png";
import whatsapp from "./whatsapp.png";
import logo_ from "./logo_.png";
import howitworks from "./howitworks.jpg";
import signupp from "./signupp.jpg";
import signup2 from "./signup2.jpg";
import access from "./access.jpg";
import getrewarded from "./getrewarded.jpg";
import request from "./request.jpg";
import engage from "./engage.jpg";
import helooo from "./helooo.jpg";
import ShahroezPeraee from "./ShahroezPeraee.jpg";

export const Images = {
  twiter,
  phone,
  signal,
  heart,
  birth,
  card,
  ques,
  search,
  apple,
  icon,
  ideal,
  visa,
  wallet2,
  paypal,
  playstore,
  appstore,
  en,
  nl,
  arrows,
  Coverpage,
  Home,
  mobileapp,
  mb2,
  textlogo,
  liberdaticon,

  question,
  mobile,
  menu,
  wifi,
  whatsapp,
  logo_,
  howitworks,
  signup2,
  signupp,
  getrewarded,
  access,
  request,
  engage,
  helooo,
  ShahroezPeraee,
};
