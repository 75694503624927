import React from 'react';
import { Dropdown } from 'react-bootstrap';
import './style.scss';

const CustomDropdown = ({ id, name, options, formik, onSelect }) => {
  return (
    <Dropdown className="custom-dropdown" id={id} name={name}>
      <Dropdown.Toggle variant="outline-secondary" id={`dropdown-${id}`}>
        {options?.find((o) => o?.value === formik.values[name])?.label || `Select Value`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            onClick={() => {
              if (onSelect) {
                onSelect(option.value);
              }
              formik.setFieldValue(name, option.value);
            }}
            active={formik.values[name] === option.value}
            eventKey={option.value}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomDropdown;
