import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import CustomButton from '../../components/custombutton/CustomButton';
import RouteNames from '../../routes/RouteNames';

import {
  useCreateQuestionnaireMutation,
  useLazyGetQuestionnaireQuery,
} from '../../store/api';
import toast from 'react-hot-toast';

function SurveyViewer() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const { questionnaire, bidRef, surveyTitle} = location.state;



  const [CreateQuestionnaire, { isError, isSuccess, isLoading, error, data }] =
    useCreateQuestionnaireMutation();


  const [GetQuestionnaire, {
    data: isGettingQuetionnaireData,
    isError: isGettingQuetionnaireError,
    isSuccess: isGettingQuetionnaireSuccess,
    isLoading: isGettingQuetionnaireLoading,
  }] = useLazyGetQuestionnaireQuery();

  // console.log('questionnaire>>>', questionnaire);

  // useEffect(() => {
  //   if (isGettingQuetionnaireSuccess) {
  //     console.log('isGettingQuetionnaireData>>>', isGettingQuetionnaireData);
  //   } else if (isGettingQuetionnaireError) {
  //     console.log('isGettingQuetionnaireError>>>', isGettingQuetionnaireError);
  //   }
  // }, [isGettingQuetionnaireSuccess, isGettingQuetionnaireError]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Questionnaire Created Successfully!!');
     setTimeout( ()=>{
      navigate(RouteNames.MyBids.route)
     }, 1000)
    } else if (isError) {
      toast.error('Error submitting Survey', error);
    }
  },[isSuccess, isError]);

  const handleAssign = async () => {
    try {
      await CreateQuestionnaire({
        questionnaire: questionnaire.map((question) => ({
    
          question: question.text,
          options: question.options,
    
       
        })),
        surveyTitle: surveyTitle,
        bidRef: bidRef,
      });
     await GetQuestionnaire();
      // console.log("isGettingQuetionnaireData",isGettingQuetionnaireData);
     
    } catch (error) {
      toast.error('Error submitting Survey', error); // Displaying error message using toast
    }
    // If you need to navigate after handling the preview, you can do so here
    // navigate(RouteNames.SurveyCompleted.route, { state: { questions } });
  };

  return (
    <div className="survey-container">
       <div className="d-flex align-items-center">
        <AiOutlineArrowLeft
          onClick={() => navigate(-1)} 
          color='#5b99a6'
          style={{ cursor: 'pointer', fontSize: '24px',marginBottom:'5px' }}
        />
        <h2>Preview Your Survey ({surveyTitle})</h2>
      </div>
      <form>
        {questionnaire.map((question, questionIndex) => (
          <div key={questionIndex} className="question-container">
            <p style={{ fontWeight: 'bold', fontSize:'19px' }}>{questionIndex+1}.  {question.text}</p>
            {question.options && question.options.length > 0 ? ( // Check if options exist and are not empty
              <ul>
                {question.options.map((option, optionIndex) => (
                  <li key={optionIndex}>
                    <label>
                      <input
                        type="radio"
                        name={`question_${questionIndex}`}
                        disabled 
                      />
                      &nbsp;&nbsp;&nbsp;{option}
                    </label>
                  </li>
                ))}
              </ul>
            ) : (
              <textarea type="text" className='disabledinput-container' disabled placeholder='Answer'/>
            )}
          </div>
        ))}

        <CustomButton
          type={'button'}
          style={{ paddingBottom: '15px'}}
          label={'Complete Survey'}
          color={'#333333'}
          textcolor={'white'}
          width={'50%'}
          backgroundColor={'#5b99a6'}
          width={'60%'}
          backgroundColor={'#5b99a6'}
    
          onClick={handleAssign}
          isLoading={isLoading}

          
        />
      </form>
    </div>
  );
}

export default SurveyViewer;
