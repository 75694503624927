import React, { useState, useRef } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import CustomButton from "../../components/custombutton/CustomButton";
import { useVerifyOTPMutation } from "../../store/api"; // Assuming you have an OTP verification mutation
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import toast from "react-hot-toast";

const OTPModal = ({ show, onHide, userId, otpType }) => {
    console.log("otpType", otpType);
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const [verifyOtp, { isSuccess, isError, isLoading }] = useVerifyOTPMutation();

    // Handle input change and auto-move to next
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {  // Only allow digits
            const newOtp = [...otp];
            newOtp[index] = value;  // Set value in the correct index
            setOtp(newOtp);

            // Automatically focus on the next input
            if (index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Handle backspace to move focus to previous input
   // Handle backspace to remove digit and move focus to previous input
const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
        const newOtp = [...otp];
        
        if (otp[index] === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        } else {
            // Remove the current digit
            newOtp[index] = "";
            setOtp(newOtp);
        }
    }
};


    // Submit OTP once all fields are filled
    const handleSubmit = async () => {
        const otpCode = otp.join("");  // Join OTP digits into a single string

        if (otpCode.length === 6) {
            try {
                const bodyToSend = {
                    userId,
                    otpType,
                    otp: otpCode,
                };

                const result = await verifyOtp(bodyToSend);

                // Check if the OTP was successfully verified
                if (result?.data?.success === true) {
      localStorage.setItem("twoFAliberdat", 'authenticated');

                    toast.success("OTP verified successfully!");

                    navigate(RouteNames.Home.route);
                } else {
                    toast.error("Invalid OTP. Please try again.");
                }
            } catch (error) {
                toast.error("OTP verification failed!");
            }
        } else {
            toast.error("Please enter a valid 6-digit OTP.");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered className="otp-modal">
            <Modal.Header closeButton>
                <Modal.Title>Verification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="otp-instructions">
                    A verification code of 6 digits has been sent to your {otpType === "Email" ? "email" : "phone"}.
                </p>

                <Form className="otp-form">
                    <Row className="otp-inputs">
                        {otp.map((data, index) => (
                            <Col key={index} xs={2} className="otp-input-wrapper">
                                <Form.Control
                                    type="text"
                                    maxLength="1"  // Only allow one digit per input
                                    value={data}
                                    onChange={(e) => handleChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    ref={(el) => inputRefs.current[index] = el}  // Store the input reference
                                    className="otp-input"
                                />
                            </Col>
                        ))}
                    </Row>
                    <CustomButton
                        label="Verify"
                        color={"#fff"}
                        textcolor={"#ffffff"}
                        backgroundColor={"#5B99A6"}
                        width="100%"
                        isLoading={isLoading}
                        onClick={handleSubmit}
                    />
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default OTPModal;
