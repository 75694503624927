export const diagnosisForSharer = [
    {
        label: 'Current',
        multiSelect: false,
        value: 'Current_Diagnosis',
        data: [
            {
                label: 'Healthy',
                displayName: 'Current - Healthy',
                multiSelect: true,
                value: 'Current_Diagnosis_Healthy',
                parent: 'Current_Diagnosis',
                bidValue: 'Healthy',
                data: [],
            },
            {
                label: 'Hypercholesterolemia',
                displayName: 'Current - Hypercholesterolemia',
                multiSelect: true,
                value: 'Current_Diagnosis_Hypercholesterolemia',
                parent: 'Current_Diagnosis',
                bidValue: 'Hypercholesterolemia',
                data: [],
            },
            {
                label: 'Hypertension',
                displayName: 'Current - Hypertension',
                multiSelect: true,
                value: 'Current_Diagnosis_Hypertension',
                parent: 'Current_Diagnosis',
                bidValue: 'Hypertension',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Relapsing Remitting',
                displayName: 'Current - Relapsing Remitting',
                multiSelect: true,
                value: 'Current_Diagnosis_Multiple Sclerosis – Relapsing Remitting',
                parent: 'Current_Diagnosis',
                bidValue: 'Multiple Sclerosis – Relapsing Remitting',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Secondary Progressive',
                displayName: 'Current - Secondary Progressive',
                multiSelect: true,
                value: 'Current_Diagnosis_Multiple Sclerosis – Secondary Progressive',
                parent: 'Current_Diagnosis',
                bidValue: 'Multiple Sclerosis – Secondary Progressive',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Primary Progressive',
                displayName: 'Current - Primary Progressive',
                multiSelect: true,
                value: 'Current_Diagnosis_Multiple Sclerosis – Primary Progressive',
                parent: 'Current_Diagnosis',
                bidValue: 'Multiple Sclerosis – Primary Progressive',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (AChR)',
                displayName: 'Current - Myasthenia Gravis (AChR)',
                multiSelect: true,
                value: 'Current_Diagnosis_Myasthenia Gravis (AChR)',
                parent: 'Current_Diagnosis',
                bidValue: 'Myasthenia Gravis (AChR)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (MuSK)',
                displayName: 'Current - Myasthenia Gravis (MuSK)',
                multiSelect: true,
                value: 'Current_Diagnosis_Myasthenia Gravis (MuSK)',
                parent: 'Current_Diagnosis',
                bidValue: 'Myasthenia Gravis (MuSK)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (LRP4)',
                displayName: 'Current - Myasthenia Gravis (LRP4)',
                multiSelect: true,
                value: 'Current_Diagnosis_Myasthenia Gravis (LRP4)',
                parent: 'Current_Diagnosis',
                bidValue: 'Myasthenia Gravis (LRP4)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (Agrin)',
                displayName: 'Current - Myasthenia Gravis (Agrin)',
                multiSelect: true,
                value: 'Current_Diagnosis_Myasthenia Gravis (Agrin)',
                parent: 'Current_Diagnosis',
                bidValue: 'Myasthenia Gravis (Agrin)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (Seronegative)',
                displayName: 'Current - Myasthenia Gravis (Seronegative)',
                multiSelect: true,
                value: 'Current_Diagnosis_Myasthenia Gravis (Seronegative)',
                parent: 'Current_Diagnosis',
                bidValue: 'Myasthenia Gravis (Seronegative)',
                data: [],
            },
            {
                label: 'Type 2 Diabetes',
                displayName: 'Current - Type 2 Diabetes',
                multiSelect: true,
                value: 'Current_Diagnosis_Type 2 Diabetes',
                parent: 'Current_Diagnosis',
                bidValue: 'Type 2 Diabetes',
                data: [],
            },
        ],
    },
    {
        label: 'Previous',
        multiSelect: false,
        value: 'Previous_Diagnosis',
        data: [
            {
                label: 'Healthy',
                displayName: 'Previous - Healthy',
                multiSelect: true,
                value: 'Previous_Diagnosis_Healthy',
                parent: 'Previous_Diagnosis',
                bidValue: 'Healthy',
                data: [],
            },
            {
                label: 'Hypercholesterolemia',
                displayName: 'Previous - Hypercholesterolemia',
                multiSelect: true,
                value: 'Previous_Diagnosis_Hypercholesterolemia',
                parent: 'Previous_Diagnosis',
                bidValue: 'Hypercholesterolemia',
                data: [],
            },
            {
                label: 'Hypertension',
                displayName: 'Previous - Hypertension',
                multiSelect: true,
                value: 'Previous_Diagnosis_Hypertension',
                parent: 'Previous_Diagnosis',
                bidValue: 'Hypertension',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Relapsing Remitting',
                displayName: 'Previous - Relapsing Remitting',
                multiSelect: true,
                value: 'Previous_Diagnosis_Multiple Sclerosis – Relapsing Remitting',
                parent: 'Previous_Diagnosis',
                bidValue: 'Multiple Sclerosis – Relapsing Remitting',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Secondary Progressive',
                displayName: 'Previous - Secondary Progressive',
                multiSelect: true,
                value: 'Previous_Diagnosis_Multiple Sclerosis – Secondary Progressive',
                parent: 'Previous_Diagnosis',
                bidValue: 'Multiple Sclerosis – Secondary Progressive',
                data: [],
            },
            {
                label: 'Multiple Sclerosis – Primary Progressive',
                displayName: 'Previous - Primary Progressive',
                multiSelect: true,
                value: 'Previous_Diagnosis_Multiple Sclerosis – Primary Progressive',
                parent: 'Previous_Diagnosis',
                bidValue: 'Multiple Sclerosis – Primary Progressive',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (AChR)',
                displayName: 'Previous - Myasthenia Gravis (AChR)',
                multiSelect: true,
                value: 'Previous_Diagnosis_Myasthenia Gravis (AChR)',
                parent: 'Previous_Diagnosis',
                bidValue: 'Myasthenia Gravis (AChR)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (MuSK)',
                displayName: 'Previous - Myasthenia Gravis (MuSK)',
                multiSelect: true,
                value: 'Previous_Diagnosis_Myasthenia Gravis (MuSK)',
                parent: 'Previous_Diagnosis',
                bidValue: 'Myasthenia Gravis (MuSK)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (LRP4)',
                displayName: 'Previous - Myasthenia Gravis (LRP4)',
                multiSelect: true,
                value: 'Previous_Diagnosis_Myasthenia Gravis (LRP4)',
                parent: 'Previous_Diagnosis',
                bidValue: 'Myasthenia Gravis (LRP4)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (Agrin)',
                displayName: 'Previous - Myasthenia Gravis (Agrin)',
                multiSelect: true,
                value: 'Previous_Diagnosis_Myasthenia Gravis (Agrin)',
                parent: 'Previous_Diagnosis',
                bidValue: 'Myasthenia Gravis (Agrin)',
                data: [],
            },
            {
                label: 'Myasthenia Gravis (Seronegative)',
                displayName: 'Previous - Myasthenia Gravis (Seronegative)',
                multiSelect: true,
                value: 'Previous_Diagnosis_Myasthenia Gravis (Seronegative)',
                parent: 'Previous_Diagnosis',
                bidValue: 'Myasthenia Gravis (Seronegative)',
                data: [],
            },
        ],
    },
];

