import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    addedUsersData: [],
    // notificationCount:null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    // setNotificationCount: (state, action) => {
    //   state.notificationCount = action.payload;
    // },
    clearUser: (state) => {
      state.user = null;
    },
    setAddedUsersData: (state, action) => {
      state.addedUsersData = action.payload;
    },
    setIntentKey: (state, action) => {
      state.intentKey = action.payload;
    },
  },
});
export const { setUser, setAddedUsersData, clearUser, setIntentKey } = authSlice.actions;
export default authSlice.reducer;