// PrivacyModal.js
import React from "react";
import Modal from "react-modal";
import "./style.scss";
import { IoIosCloseCircle } from "react-icons/io";

const PrivacyStatementModal = ({ isOpen, onRequestClose }) => {
  const handleCloseModal = () => {
    onRequestClose();
  };

  // const [OpenModal, setOpenModal] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
        content: {
          width: "82vw",
          margin: "auto",
          height: "80vh",
          padding: "20px",
        },
      }}
    >
      <div className="privacypolicy">
        <hr />

        <p className="paras">
          Liberdat B.V., represented by S. Peraee, registered at Boxbergerweg
          140-F6, 7413 EL, Deventer, with company number 90378989 (referred to
          as “LIBERDAT”) acts as the data controller of your personal data and
          takes your privacy and the protection of your personal data into great
          consideration.
        </p>

        <p className="paras">
          This privacy statement explains how and why LIBERDAT uses the personal
          data of its Platform users following applicable laws such as{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&qid=1691404126036"
            target="_blank"
            rel="noopener noreferrer"
          >
            Regulation (EU) 2016/679 Of The European Parliament And Of The
            Council
          </a>{" "}
          (“GDPR”).
        </p>

        <p className="paras">
          This Privacy Statement explains how and why LIBERDAT uses the personal
          data of its Platform users. Following applicable laws such as{" "}
          <a
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&qid=1691404126036"
            target="_blank"
            rel="noopener noreferrer"
          >
            Regulation (EU) 2016/679 Of The European Parliament And Of The
            Council
          </a>{" "}
          (“GDPR”).
        </p>

        <p className="paras">
          The processing of personal data happens exclusively after a user has
          read, understood, and accepted this Privacy Statement.
        </p>

        <p className="paras">
          LIBERDAT processes personal data such as identification data, user
          data, and transacted data in order to a) set up a license agreement
          for users; b) provide users with the LIBERDAT services; c) improve the
          LIBERDAT services; d) inform users about updates.{" "}
        </p>

        <p>Identification Data</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Data sharers remain anonymous if they sign up with an anonymous
            username.
          </li>
          <li>Username and password (Data sharers).</li>
          <li>
            Institution name, department name, and corresponding institutional
            e-mail address (Data requesters).
          </li>
          <li>
            Bank account details (processed separately by the licensed
            third-party Stripe, Inc.).
          </li>
          <li>IP address (for troubleshooting purposes only).</li>
        </ul>

        <p>User Data</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>Data requests</li>
          <li>
            Profile data (provided optionally by users such as sociodemographic
            and clinical variables).
          </li>
        </ul>

        <p>Identification Data</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>Username and password(Data Sharers).</li>
          <li>
            Data sharers remain anonymous if they sign up with an anonymous
            username.
          </li>
          <li>
            Institution name, department name, and corresponding institutional
            e-mail address (Data Requesters).
          </li>
          <li>
            Bank account details (processed separately by the licensed
            third-party Stripe, Inc.).
          </li>
          <li>IP address (for troubleshooting purposes only).</li>
        </ul>

        <p>User Data</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>Data Requests</li>
          <li>
            Profile data (provided optionally by users such as sociodemographic
            and clinical variables).
          </li>
        </ul>

        <p className="paras">Transacted Data</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Data from Apple Health (for iOS device users) and Google Health
            Connect (for Android device users).
          </li>
          <li>Questionnaire data.</li>
        </ul>

        <p className="paras">
          Users’ personal data will remain in the Netherlands and will not be
          sent to anyone without first informing users or obtaining explicit
          permission.
        </p>

        <p className="paras">
          LIBERDAT keeps personal data confidential and takes all reasonable
          measures to keep it safe. LIBERDAT applies organizational measures
          such as access management through which only certain employees have
          access to personal data. Furthermore, LIBERDAT implements technical
          measures, such as anonymization, pseudonymization, and encryption, to
          secure personal data.
        </p>

        <p className="paras">
          Identification data is stored for as long as users use the LIBERDAT
          services. User data is stored for as long as the user decides to keep
          it in their personal profile. LIBERDAT stores transacted data items in
          a database, provided by MongoDB Inc., for 1 month, after which it is
          permanently removed.
        </p>

        <p className="paras">
          Users have access rights to their personal data and can correct where
          necessary or add missing information.
        </p>

        <p className="paras">
          Identification data is stored for as long as users use the LIBERDAT
          services. User data is stored for as long as the user decides to keep
          it in their personal profile. LIBERDAT stores transacted data items in
          a database, provided by MongoDB Inc., for 1 month, after which it is
          permanently removed.
        </p>

        <p className="paras">
          Under certain conditions, Users may demand the sole use of their
          personal data for specific cases.
        </p>

        <p className="paras">
          Where technically feasible, Users have the right to have their
          personal data transmitted to other parties.
        </p>

        <p className="paras">
          Users have the right to rectification or deletion of their personal
          data.
        </p>

        <p className="paras">
          Questions or complaints can be sent to{" "}
          <a href="mailto:info@liberdat.io" className="paras">
            info@liberdat.io
          </a>
          . Users can also file a complaint with the competent data protection
          authority.
        </p>

        <p className="paras">
          LIBERDAT informs about upcoming changes to the Privacy Statement via
          the Platform.
        </p>

        <h2 className="paras">Subcontractors</h2>
        <hr />

        <p className="paras">
          LIBERDAT informs about upcoming changes to the Privacy Statement via
          the Platform.
        </p>

        <h2 className="paras">Subcontractors</h2>
        <hr />

        <p>LIBERDAT contracts with the following subcontractors:</p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Firebase Cloud messaging (Google) and Apple Push Notification
            Service (Apple): to send notifications.
          </li>
          <li>
            Stripe, Inc.:to facilitate monetary transactions between users.
          </li>
          <li>Amazon Web Services (AWS): to process the LIBERDAT services.</li>
          <li>MongoDB Atlas: To temporarily store data.</li>
          <li>
            Google Play Store (Google): to download the app on Android devices.
          </li>
          <li>Apple AppStore (Apple): to download the app on iOS devices.</li>
        </ul>
      </div>

      <div className="cls" onClick={handleCloseModal}>
        <IoIosCloseCircle size={28} style={{ cursor: "pointer" }} />
      </div>
    </Modal>
  );
};

export default PrivacyStatementModal;
