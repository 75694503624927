import React, { useCallback, useState, useEffect } from "react";
import Header from "../../components/header/index";
import Body from "../body/index";
import Marketbids from "../bidsection";
import toast from "react-hot-toast";
import { useAssignBidMutation, useSendPaymentStripeIdMutation } from "../../store/api";

const Home = () => {
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("Data");

  //Sending this to Body component
  const [activeIndex, setActiveIndex] = useState(1);
  const [sendStripeId] = useSendPaymentStripeIdMutation();
  const [
    assignBid,
    {
      data: dataassign,
      isSuccess: dataisSuccess,
      isError: dataisError,
      error: dataerror,
      // isLoading,
    },
  ] = useAssignBidMutation();
  useEffect(() => {
    if (dataisSuccess) {
      toast.success("Your payment has been recieved")
    } else if (dataisError) {
      toast.error('error while assigning');
    }
  }, [dataisSuccess, dataisError]);
  useEffect(() => {
    console.log("activeIndex changed:", activeIndex);
  }, [activeIndex]);
  ;
  useEffect(() => {
    const queryString = window.location.search;
    // Use a regular expression to extract the 'code' parameter without decoding it
    // redirect_status=succeeded

    const match = queryString.match(/bidId=([^&]*)/);
    const pImatch = queryString.match(/payment_intent=([^&]*)/);
    const redStatus = queryString.match(/redirect_status=([^&]*)/);

    
    const extractedbidID = match ? match[1] : null;
    const extractedPaymentIntent = pImatch ? pImatch[1] : null;
    const extractedRedirectStatus = redStatus ? redStatus[1] : null;

    // setCode(extractedCode);
    

    handleQuery(extractedbidID,extractedPaymentIntent,extractedRedirectStatus);
  }, []);
  const handleQuery = useCallback(async (extractedbidID,extractedPaymentIntent,extractedRedirectStatus) => {
    try {
    
      if(extractedRedirectStatus==='succeeded' && extractedbidID && extractedPaymentIntent){
        const {data} = await sendStripeId(extractedPaymentIntent);
        const {type} = data;
          toast.success('Payment Successful');
          const params = {
            bidData: {
              
              status: 'Accepted',
              paymentMethod: type,
            },
            bidId: extractedbidID,
          };
          await assignBid(params);

      }
      
    } catch (error) {
      console.log("error :", error.toString());
    } finally {
      // Redirect to the custom URL scheme after sending the code
      // window.location.href = "liberdatApp://main";
    }
  }, []);
  //Sending this data to Body as Props
  const categories_heading = [
    {
      title: "Questionnaires",
      content: "Sample data for Questionnaires",
    },
    {
      title: "Medical",
      content: "Sample data for Medical",
    },
    {
      title: "Sensor",
      content: "Sample data for Sensors",
    },
    {
      title: "Location",
      content: "Sample data for Application",
    },
    {
      title: "Financial",
      content: "Sample data for Browse History",
    },
    {
      title: "Applications",
      content: "Sample data for Finance",
    },
  ];

  const handleCategoriesClick = (index) => {
    setActiveIndex(index);
  };
  const handleSearchChange = (text) => {
    setSearchTerm(text);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleSidebarToggle = (menu) => {
    setSelectedMenu(menu);
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Header
        handleSidebarToggle={handleSidebarToggle}
        hasSearch={true}
        searchText={searchTerm}
        handleSearchChange={handleSearchChange}
      />
      <Body
        categories_heading={categories_heading}
        selectedImageData={selectedImageData}
        setSelectedImageData={setSelectedImageData}
        activeIndex={activeIndex}
        handleCategoriesClick={handleCategoriesClick}
      />
      <Marketbids
        isOpen={isOpen}
        selectedImageData={selectedImageData}
        activeIndex={activeIndex}
        searchTerm={searchTerm}
        selectedMenu={selectedMenu}
        categories_heading={categories_heading}
      />
    </div>
  );
};

export default React.memo(Home);
