// PrivacyModal.js
import React from "react";
import Modal from "react-modal";
import "./style.scss";
import { Link } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";

const PrivacyModal = ({ isOpen, onRequestClose }) => {
  const handleCloseModal = () => {
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        },
        content: {
          width: "82vw",
          margin: "auto",
          height: "80vh",
          padding: "20px",
        },
      }}
    >
      <div className="privacyStatement">
        <h1 style={{ textAlign: "center", margin: "45px" }}>
          TERMS & CONDITIONS
        </h1>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          These Terms & Conditions are an Agreement between the Liberdat
          platform users, and Liberdat B.V., represented by S. Peraee,
          registered at Boxbergerweg 140-F6, 7413 EL, Deventer, The Netherlands,
          under the number 94704791 (referred to as “LIBERDAT”) for the use of
          the LIBERDAT platform.
        </p>

        <p>
          LIBERDAT and users are referred to individually as “Party” or jointly
          as “Parties”.
        </p>

        <h2>Opening Statements</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          The LIBERDAT Services are provided to users by Liberdat B.V. via the
          LIBERDAT platform which is accessible via the website{" "}
          <a href="https://liberdat.io/">https://liberdat.io/</a> and which
          works in conjunction with a mobile application accessible via the
          Apple App Store and Google Play Store.
        </p>

        <p>
          Before signing up on the LIBERDAT Platform, every individual is
          obliged to read, understand, and accept these Terms & Conditions which
          form a legally binding Agreement between users and LIBERDAT regarding
          access and use of the LIBERDAT services. If a User is using the
          LIBERDAT Services on behalf of a legal entity, LIBERDAT assumes that
          the user is authorized and entitled to accept these Terms & Conditions
          on behalf of the legal entity they represent. In order to create a
          LIBERDAT Account, individuals are required to read, understand, and
          accept the Terms & Conditions when prompted during the registration
          process.
        </p>

        <p>
          It is obligatory for all users to read, understand, and accept the{" "}
          <Link
            to="/privacypolicy#top"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            Privacy Statement
          </Link>
          .
        </p>

        <p>
          The LIBERDAT services are intended only as an online data Platform to
          facilitate the exchange of data between Researchers and Individuals.
          The LIBERDAT Services do not serve medical purposes in the context of
          diagnostics, prevention, alleviation or curation of any medical
          conditions. All information users engage with on the LIBERDAT Platform
          is not medical or therapeutic advice and Users acknowledge that the
          LIBERDAT platform does not provide any medical services.
        </p>

        <p>
          LIBERDAT grants third parties access to the Platform in order to
          provide the LIBERDAT Services (i.e. the Integrated Payment Service
          (see section 11)).
        </p>

        <p>LIBERDAT does not sell your data to third parties.</p>

        <p>
          LIBERDAT does not grant rights to third parties to sell your data.
        </p>

        <p>
          In order to receive a license to use the platform, as either a
          Researcher or individual, users agree to be bound by the following
          Terms & Conditions:{" "}
        </p>

        <h2>1. Definitions </h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>Agreement</strong> - The Terms & Conditions in addition to all
          the appendices.
        </p>

        <p>
          <strong>Data request </strong> –The request from a Researcher for a
          Data Item which is listed on the Homepage of the Platform.
        </p>

        <p>
          <strong>Researcher </strong> - Every User that places one or more Data
          Requests for an Individual’s Data Items on the Platform.
        </p>

        <p>
          <strong>"Request" button</strong> – Allows Researchers to place a Data
          Request for a Data Item on the Platform.
        </p>

        <p>
          <strong>Confidential Information</strong> – The information disclosed
          by LIBERDAT to Researchers for the purposes of this Agreement, which
          is a) disclosed in downloadable form via the “My Requests” window or
          b) obviously confidential in nature.
        </p>

        <p>
          <strong>Content</strong> – Any content provided by users.
        </p>

        <p>
          <strong>Data Category</strong> – A collection of Data Items belonging
          to a specific category.
        </p>

        <p>
          <strong>Data Items</strong> – The Items that Researchers list on the
          Platform in order to find a possible data exchange with an Individual.
        </p>

        <p>
          <strong>Data Processing Agreement</strong> – The agreement between
          LIBERDAT as a processor and a Researcher as controller (for
          definitions of processor and controller please see Regulation (EU)
          2016/679 Of The European Parliament And Of The Council (“GDPR”)).
        </p>
        <p>
          <strong>Final Price</strong> – The settled price for a Transaction
          after concluding the Negotiation Process. The Final price consists of
          a) the settled price for the Data Item(s) between Researcher and
          Individual; and b) any applicable taxes or fees.
        </p>
        <p>
          <strong>Homepage</strong> – The opening webpage of the Platform which
          lists the Data Requests of Researchers. The Homepage has different
          sections for each Data Category.
        </p>
        <p>
          <strong>Identification Data</strong> – The personal data a user
          provides for identification purposes such as username and password
          (see the Data Processing agreement for a full list).
        </p>
        <p>
          <strong>Individual</strong> - A User that sells or wishes to sell Data
          Items via the Platform.
        </p>
        <p>
          <strong>Integrated Payment Service</strong> – The online payment
          system allowing Users to make monetary transactions for Data Items via
          the Platform.
        </p>
        <p>
          <strong>Negotiation Process</strong> – The process available to
          Researchers and Individuals to make counterbids which allows for the
          settlement of a price both parties agree on.
        </p>
        <p>
          <strong>Platform</strong> - The LIBERDAT website and mobile
          application which gives access to the LIBERDAT Services.
        </p>
        <p>
          <strong>Payment Service Provider</strong> – LIBERDAT utilizes the
          services of the payment service provider Stripe Inc., an
          Irish-American financial services and software as a service (SaaS)
          company dual-headquartered in South San Francisco, California, United
          States and Dublin, Ireland. Stripe Inc. is a licensed electronic money
          institution regulated by the Central Bank of Ireland.
        </p>
        <p>
          <strong>Personal Data</strong> – All the personal data that is
          processed of Platform users which consist of a) Identification Data;
          b) User Data; c) Transacted Data.
        </p>
        <p>
          <strong>Privacy Statement</strong> – The document that describes how
          LIBERDAT collects, stores, and transfers personal data of Visitors or
          Users on the Platform. The Privacy Statement can be accessed on the
          Platform.
        </p>
        <p>
          <strong>Profile</strong> – The personal page of a user for data and
          Wallet management.
        </p>
        <p>
          <strong>“Request” button</strong> – Allows Researchers to place a Data
          Request for a Data Item on the Platform.
        </p>
        <p>
          <strong>Researcher</strong> - Every User that places one or more Data
          Requests for an Individual’s Data Items on the Platform.
        </p>
        <p>
          <strong>Services</strong> – The list of services which LIBERDAT
          provides to Users which consist of a) the provision of the online
          Platform (web and mobile application) to Users; b) the storage of
          Personal Data; c) providing Researchers with access to Transacted
          Data; d) an Integrated Payment Service; e) search functionality to
          optimize the user experience.
        </p>
        <p>
          <strong>Standard Contractual Clauses</strong> – Applicable clauses
          regarding the transfer of transacted Data by LIBERDAT to a Researcher
          located in a third country.
        </p>
        <p>
          <strong>Terms & Conditions</strong> –The binding agreement between
          LIBERDAT and the User, as defined in the opening statements of this
          document.
        </p>
        <p>
          <strong>Transaction</strong> – A monetary transaction between a
          Researcher and an Individual for a Data Item(s).
        </p>
        <p>
          <strong>Transacted Data</strong> – Data Items that are transacted
          between a Researcher and Individual and that are thereby downloadable
          from a LIBERDAT server by the Researcher (see the Data Processing
          agreement for a full list).
        </p>
        <p>
          <strong>User</strong> – Any person who has created an account on the
          Platform and has accepted these Terms & Conditions.
        </p>
        <p>
          <strong>User account or Account</strong> – The User’s account created
          upon registration on the Platform.
        </p>
        <p>
          <strong>User Data</strong> – The non-identifiable information a user
          provides in their Profile (see the Data Processing agreement for a
          full list).
        </p>
        <p>
          <strong>Visitor</strong> – Any individual who visits the Platform, but
          who has not created an Account.
        </p>
        <p style={{ marginBottom: "32px" }}>
          <strong>Wallet</strong> – Financial services provided by the Payment
          Service Provider which allows Users to manage finances on the
          Platform.
        </p>

        <h2>2. Minors</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>2.1</strong> Children under the age of 16 need to provide
          written consent from their parents or legal guardian before using the
          Platform. Consent can be sent via e-mail to{" "}
          <a href="mailto:info@liberdat.io">info@liberdat.io</a> by providing
          identification of the parent or legal guardian and the created
          username of the children on the Platform. After consent, kids under
          the age of 16 can only use the Platform under the supervision of a
          parent or legal guardian.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>2.2</strong> The Accounts of minors will be removed if the
          LIBERDAT Services are used without the consent of a parent or legal
          guardian.
        </p>

        <h2>3. Visitors</h2>
        <hr style={{ marginBottom: "18px" }} />

        <p style={{ marginBottom: "32px" }}>
          Visitors can search and view Data Items on the Platform. Additionally,
          Visitors can fill out a Data Request for Data Items. However, to
          assign Indivduals to the Data Request an Account has to be created.
        </p>

        <h2>4. Registration </h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>4.1</strong> In order to utilize the LIBERDAT Services, Users
          register and create an Account as a either an Individual or as a
          Researcher for the legal entity they are representing. Users are
          required to provide accurate and up-to-date information. Researchers
          and Individuals will be asked to provide a username and password for
          identification purposes each time the Platform is accessed.
          Researchers will be asked additionally to provide the name of their
          affiliated institution, department name, and corresponding
          institutional e-mail address. LIBERDAT uses this personal data for the
          purposes and in the manner described in the{" "}
          <Link
            to="/privacypolicy#top"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            Privacy Statement
          </Link>
          . For the security of its Users, LIBERDAT may verify the provided
          information and ask questions, provide relevant information or assist
          in verification procedures. Furthermore, LIBERDAT may verify the
          User's telephone number, email, the User's credit card, debit card or
          other payment method, or bank account.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>4.2</strong> Users will not attempt to use the personal
          account of anyone else. Users will not attempt to create an
          unauthorized Account by any means. LIBERDAT is not liable for any loss
          or damage arising from failure to comply with the above. LIBERDAT
          reserves the right to disable access to Users accounts at our
          discretion in case of non-compliance with these Terms & Conditions.
        </p>

        <h2>5. Third parties</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          Some features of the Platform are facilitated by third parties, which
          are subject to different terms & conditions. For example, to use the
          Integrated Payment Service, Users must agree to the Payment Service
          Provider’s{" "}
          <Link
            to="/termsandconditions"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            terms & conditions
          </Link>{" "}
          (see section 11.1). The Platform will provide Users with a link to the
          applicable terms & conditions which must be accepted in order to use
          the third-party services.
        </p>

        <h2>6. Access, use, & information provided</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>6.1</strong> The LIBERDAT Services may change without prior
          notice due to updates. LIBERDAT makes all reasonable efforts to ensure
          the accessibility of the Platform which might be halted without prior
          notice due to for example technical malfunctioning or maintenance.
          Moreover, LIBERDAT reserves the right to at all times restrict access
          to users if the Terms & Conditions for the use of the Services are
          breached. LIBERDAT cannot be held liable for any damage or loss if the
          Platform is rendered temporarily or permanently inaccessible or if
          access to the Platform is restricted.
        </p>

        <p>
          <strong>6.2</strong> Users are responsible for use of the Platform and
          for any content provided on it. Users cannot send any unsolicited,
          commercial, offensive or harmful content to other Users of the
          Platform. Moreover, Users cannot share confidential, illegal, harmful
          or libellous content, or any content infringing third party rights on
          the Platform. LIBERDAT does not endorse, support, represent or
          guarantee the accuracy, completeness or reliability of any content
          provided by Users on the Platform. Furthermore, LIBERDAT does not
          monitor or control the content Users provide and therefore cannot be
          held liable. Finally, use of the platform is at the User’s own risk.
          LIBERDAT reserves the right to remove content at its discretion and
          without prior notice. Users cannot use the Platform for any illegal or
          unauthorized purposes, and Users are required to comply with all
          applicable laws and regulations. Users will not try to restrict other
          Users in the use of the Platform and Users will not enable or
          encourage other Users to violate these Terms & Conditions. Users will
          not hinder or disrupt the provision of the LIBERDAT Services via
          spyware, malware, viruses or other destructive code. LIBERDAT reserves
          the right to disable access to Users accounts at its discretion in
          case of non-compliance or if a user of the Platform forms a legal risk
          to us.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>6.3</strong> LIBERDAT makes efforts to ensure that information
          provided on the Platform is correct. The content and features offered
          on the Platform may be subject to changes without prior warning.
          However, LIBERDAT holds no obligation to make updates to the provided
          services.Users acknowledge the information on the Platform may not
          always be accurate, complete or fit for their purposes and that
          LIBERDAT cannot be held responsible for any consequences.
        </p>

        <h2>7. Requesting</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>7.1</strong> Only Data Requests that meet all of the following
          conditions may be listed on the Platform: a) Data Request(s) must be
          Data Item(s) supported by the Platform; b) haring, use or possession
          of the Data Item related to the Data Request must not violate the
          rights of any third party (including any intellectual property rights)
          and must not violate any local, national or international applicable
          law or regulation. The Data Item must be safe and cannot be
          counterfeit; c) the Data Request must comply with all commonly applied
          requirements on the Platform, unless there is an agreement to the
          contrary between the Researcher and Individual; d) the Data Request
          must comply with the Payment Service Provider’s list of prohibited and
          restricted products and services.
        </p>

        <p>
          <strong>7.2</strong>To list a Data Request on the Platform, the
          Researcher must fill out the “Request” field.
        </p>

        <p>
          <strong>7.3</strong> When a Researcher lists a Data Request on the
          Platform, this constitutes an offered Data Request which may be
          accepted by an Individual.
        </p>

        <p>
          <strong>7.4</strong> The Researcher who lists a Data Request can
          withdraw it or amend the price at any time, before entering into a
          Negotiation Process with an Individual for the purchase of the Data
          Item(s) in question.
        </p>

        <p>
          <strong>7.5</strong> Purchases made without using the “Request” button
          are made solely at the Researchers risk. LIBERDAT is not responsible
          for, and disclaims all liability arising out of or related to,
          purchases conducted off the Platform. For Reseacher security and that
          of the transactions, LIBERDAT invites Researchers to complete
          Transactions using the “Request” button and not to make Transactions
          outside of the Platform.
        </p>

        <p>
          <strong>7.6</strong> Data Requests presented on an Individuals
          Homepage are search results sorted by “relevance” taking into
          consideration the following main criteria: a) relevance to the search
          query; b) the individuals preferences and characteristics (permissions
          and personal data); c) historical data about the Individuals browsing
          habits (Data Items, personal data); d) the date on which the Data
          Request was uploaded. With these parameters, LIBERDAT ensures that the
          listings presented higher in ranking match the User’s search
          expectations.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>7.7</strong> When Individuals search for a Data Request,
          listings are sorted by default by “relevance” as mentioned above in
          section 7.6. Alternatively, on the Platform, Individuals can also
          choose for the listings to be sorted by price or by date. If
          Individuals choose for listings to be sorted by price and date, the
          preferences they indicated in their account will still be taken into
          consideration to classify listings.
        </p>

        <h2>8. Sharing</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>8.1</strong> Only Data Items that meet all of the following
          conditions may be shared via the Platform: a) the Data Item(s) are
          supported by the Services; b) sharing, use or possession of the Data
          Items must not violate the rights of any third party (including any
          intellectual property rights) and must not violate any local, national
          or international applicable law or regulation. The Data Items must be
          safe and cannot be counterfeit; c) the Data Items must comply with all
          commonly applied requirements on the Platform, unless there is an
          agreement to the contrary between the Researcher and Individual; d)
          The Data Items must comply with the Payment Service Provider’s list of
          prohibited and restricted products and services.
        </p>

        <p>
          <strong>8.2</strong> To receive Data Requests for Data Items, User can
          allow LIBERDAT to read Apple Health (iOS) or Google fit (Android),
          fill in data in their profile under “My Data”, and accept to fill out
          questionnaires.
        </p>

        <p>
          <strong>8.3</strong> When an Individual takes actions as mentioned in
          section 8.2, this constitutes an offered Data Item by the Individual,
          which may lead to a Negotiation Process with a Researcher.
        </p>

        <p>
          <strong>8.4</strong>Offered Data Items can be withdrawn by Individuals
          by either disallowing LIBERDAT to read their data, deleting
          information in their profile, not accepting Data Requests, and or
          deleting their account.{" "}
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>8.5</strong> Sharing data via other means as explained in the
          previous sections is done solely at the Users’ risk. To the extent
          permitted by law, LIBERDAT is not responsible for, and disclaims all
          liability arising out of or related to, purchases conducted off the
          Platform. For Individual security and that of the transactions,
          LIBERDAT discourages Individuals to complete Transactions outside the
          Platform.
        </p>

        <h2>9. Negotiation process </h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          The negotiation process is initiated after an Individual is assigned
          to a Data Request who can subsequently accept, decline or choose to
          donate the offered funds to the Platform.
        </p>

        <h2>10. Researcher & Individual contract</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          After concluding a Negotiation Process, both the Researcher and
          Individual become a party to the following contract: both the
          Researcher and the Individual acknowledge that completion of the
          Negotiation Process creates a legally binding contract between the
          Researcher and the Individual based on: a) the description of the Data
          Request; b) the relevant conditions included in these Terms &
          Conditions. The Researcher pays for the Data Item and the Individual
          licenses the use of the Data Item. LIBERDAT is not a party to this
          contract. EU consumer rights and protection do not apply when buying
          from individuals as opposed to legal entities.{" "}
        </p>

        <h2>11. Paying</h2>

        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>11.1</strong> LIBERDAT contracts with a Payment Service
          Provider to process payments and to store information relating to
          credit cards, debit cards, and any other payment method offered on the
          Platform. By accepting LIBERDAT’s Terms & Conditions, Users
          automatically accept the terms & conditions of the Payment Service
          provider (click{" "}
          <a href="https://stripe.com/nl/legal/consumer">here</a> for these
          terms and conditions). Payment of the Final Price can be made by any
          payment method supported by the Payment Service Provider. If a User of
          the Platform provides a payment method that is suspected to belong to
          a third person, the User may be required to provide additional proof
          that the payment method belongs to the User. Any funds available in
          the Wallet can be automatically used to make purchases on the
          Platform. Insufficient funds to cover the Final Price of a Transaction
          can be covered by an additional payment through one of the available
          payment methods. Once a Transaction is initiated, the Payment Service
          Provider acquires funds from the Researcher on behalf of the
          Individual and holds those funds for disbursement until the
          Transaction is completed (see section 11.2). LIBERDAT supports its
          Users in using the services provided by the Payment Service Provider
          and provides direct assistance to Users for such services. However,
          LIBERDAT does not provide any payment processing services for Users.
          Users are solely responsible for the provision of accurate information
          associated with credit cards, debit cards, and any other payment
          method offered on the Platform and LIBERDAT disclaims any
          responsibility and all liability for User-provided information to the
          full extent permitted by applicable law.
        </p>

        <p>
          <strong>11.2</strong>The Final Price paid by the Researcher will be
          retained by the Payment Service Provider until the Transaction is
          completed. The Transaction is completed after the Data Item(s), as
          specified in the Data Request, have been transferred to a LIBERDAT
          server. Once the Transaction is completed, the funds are transferred
          by the Payment Service Provider to the Individual’s Wallet. The
          Researcher will receive a message that the Data item(s) can be
          downloaded (only from a desktop computer) for a period of 7 days after
          which the Data Items will be permanently deleted from the LIBERDAT
          server. A User can withdraw a balance in the Wallet to their personal
          bank account without a transfer fee. However, Users must provide bank
          account details for the first pay-out (see section 12).
        </p>

        <p>
          <strong>11.3</strong> The right to use the Integrated Payment Service
          is only for sales in a User’s own Account. A User may not resell,
          hire, or on any other basis allow third parties to use the Integrated
          Payment Service to enable such third parties to be paid for their
          services.{" "}
        </p>

        <p>
          <strong>11.4</strong> The Integrated Payment Service is only for
          Transactions regarding Data Items supported by the Platform and not
          for any other products or services.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>11.5</strong> All prices on the Platform are expressed in
          Euros (EUR) including any applicable taxes.
        </p>

        <h2>12. Wallet</h2>

        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>12.1</strong>The Wallet is a service provided by the Payment
          Service Provider. By accepting LIBERDAT’s Terms & Conditions, Users
          automatically accept the terms & conditions of the Payment Service
          Provider (click{" "}
          <a href="https://stripe.com/nl/unsupported-browser?location=%2Fnl%2Flegal%2Fconsumer">
            here
          </a>{" "}
          for these terms and conditions). For the first Transaction, the
          Individual will be asked to create a Wallet by providing the
          Individual’s name, surname, date of birth, address, phone number and
          IBAN number. If the Individual fails to do so within five (5) days,
          the funds will be donated to the Platform. Before a first Transaction
          takes place, Individuals can also create the Wallet by going to their
          profile and providing the required data.
        </p>

        <p>
          <strong>12.2</strong>Users will be subject to regulatory procedures
          applied by the Payment Service Provider, such as identity checks and
          other requirements of the “Know Your Customer” procedure when
          respective financial thresholds are met. Those financial thresholds
          are set by the Payment Service Provider and depend on the total amount
          of pay-outs made by the User. In addition, the Payment Service
          Provider may also carry out KYC checks on the User without reaching
          set thresholds if it suspects that the User is engaging in possibly
          fraudulent or suspicious behaviour. For the identity check purposes,
          User may be asked to provide additional information as requested by
          the Payment Service Provider. If a User fails to provide the documents
          required by the Payment Service Provider, the User’s Wallet may be
          suspended and the User will not be able to pay out the funds until
          confirmation of their identity. The Payment Service Provider might
          also suspend User’s ability to make transactions from its Wallet. If
          on the other hand a User has committed an illegal act, e.g. uploaded a
          fraudulent document or took any other unlawful actions, and the
          Payment Service Provider deems such User as fraudulent, the User will
          no longer be able to pass KYC checks which will render the User unable
          to access or withdraw the funds.{" "}
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>12.3</strong> Users can transfer funds from their Wallet to
          their bank account, transfer will be performed by the Payment Service
          Provider. To enable this option, the User must first go to User
          account settings and add the User’s bank account number, full name and
          billing address. If a user reaches the KYC thresholds referred to in
          section 12.2 above or if the Payment Service Provider suspects that
          the User is engaging in possibly suspicious or fraudulent activity,
          the User will not be able to transfer funds until they complete the
          relevant KYC checks.
        </p>

        <h2>13. Intellectual property rights</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>13.1</strong>The Platform is copyrighted by LIBERDAT. LIBERDAT
          retains all rights, including all intellectual property rights of the
          Platform, information, data and content provided. Intellectual
          property rights include amongst others but is not limited to: (trade)
          marks, logos, service marks, domain names, drawings, models, patents,
          copyrights and moral rights, rights relating to databases, software,
          know-how and other rights, as well as all other industrial and
          intellectual rights, in each case regardless of whether they are
          registered or not and including the applications for registration, as
          well as all equivalent rights or means of protection leading to a
          similar result anywhere in the world.
        </p>

        <p>
          <strong>13.2</strong>Through use of the Platform, Users agree to the
          Terms & Conditions for the license for using the Platform. Users
          obtain a personal, revocable, exclusive, non-transferable right to use
          the Platform in accordance with these Terms & Conditions. There is no
          transfer of intellectual property rights, in whole or in part, and
          Users are not allowed to reproduce, alter, disseminate, sell, loan,
          broadcast, license, reverse engineer, decompile, disassemble or
          otherwise exploit the content or source code of the LIBERDAT Services
          or make derivative works thereof without our prior permission. The
          LIBERDAT name and logo cannot be used.
        </p>

        <p>
          <strong>13.3</strong>Researchers and Individuals remain the owner of
          any data provided, and LIBERDAT receives a worldwide, non-exclusive,
          royalty-free license to use the data for providing the LIBERDAT
          Services and improving them.
        </p>

        <p>
          <strong>13.4</strong>Individuals give Researchers a worldwide,
          non-exclusive, royalty free license to use Transferred Data for
          research purposes which include, amongst others, but not limited to,
          analysis, presentation, and publishing of the data.{" "}
        </p>

        <p>
          <strong>13.5</strong>This license does not comprise the right to
          incorporate the LIBERDAT Platform or its parts into products, which
          are sold or transferred to third parties. The grant of this license
          does not include the right to use the Platform to provide services for
          the benefit of third parties or to use the Platform to provide
          clinical or medical services.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>13.6</strong> Nothing in this Agreement precludes LIBERDAT
          from entering into agreements with third parties concerning the
          Platform.
        </p>

        <h2>14. Third party software</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          The Platform interacts with third-party software from subcontractors
          which is licensed as outlined in the Data Processing Agreement.
        </p>

        <h2>15. Delivery & Support</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>15.1</strong> This Agreement does not include support by
          LIBERDAT for the Services.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>15.2</strong>LIBERDAT gives no guarantee of access,
          availability, continuity, of the Services. LIBERDAT reserves the right
          to discontinue the Services at any time without advance notification
          to Users.
        </p>

        <h2>16. Data protection</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>16.1</strong>LIBERDAT processes personal data of Individuals
          on behalf of the Researcher (controller). LIBERDAT acts as a processor
          on behalf of the controller to retrieve, store, and transfer
          transacted Data Items to the controller. The Data Processing Agreement
          between LIBERDAT and the Researcher applies.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>16.2</strong>LIBERDAT also processes personal data from
          Researchers and Individuals for its own purpose which relate to the
          improvement of the services. More information relating to the
          processing of personal data can be found in our{" "}
          <Link
            to="/privacypolicy#top"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            Privacy Statement.
          </Link>
          . In this case, LIBERDAT acts as data controller for processing User’s
          data.
        </p>

        <h2>17. Data sharing</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>17.1</strong> Users agree to share Personal Data with LIBERDAT
          for the purpose listed in this section.
        </p>

        <p>
          <strong>17.2</strong> LIBERDAT processes Personal Data for its own
          purposes and thereby acts as data controller.
        </p>

        <p>
          <strong>17.3</strong> LIBERDAT processes Personal Data to improve and
          further develop the Services.
        </p>

        <p>
          <strong>17.4</strong> LIBERDAT processes Personal Data as strictly
          necessary for its own purposes, in compliance with Article 5 of the
          GDPR.
        </p>

        <p>
          <strong>17.5</strong> LIBERDAT implements appropriate measures to
          secure Personal Data shared by Users in compliance with Article 32 of
          the GDPR.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>17.6</strong> When Researchers are not established in the
          European Economic Area, the{" "}
          <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">
            Standard Contractual Clauses{" "}
          </a>{" "}
          of the European Commission apply to the transfer of transacted Data
          Items outside of the EEA, if the country in which Researchers are
          established is not covered by an existing adequacy decision adopted by
          the European Commission. Where LIBERDAT acts as a data processor, the
          Standard Contractual Clauses apply.
        </p>

        <h2>18. Questionnaires</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          Researchers agree that any questionnaires configured through the
          Platform can be used by LIBERDAT for purposes including, but not
          limited to, providing such questionnaires to other Researchers on the
          Platform for use as or in their own questionnaires. This does not
          include the answers provided by Individuals. Researchers guarantee
          that any questionnaires submitted do not infringe on any third-party
          rights and that any necessary approval from third parties has been
          obtained in order to grant the right to LIBERDAT to use the
          questionnaires.
        </p>

        <h2>19. Confidentiality</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>19.1</strong> Researchers are not permitted to distribute,
          disclose or disseminate Confidential Information, except to
          colleagues, peers, or employees who have a need to know the
          Confidential Information for the purposes of this Agreement and who
          shall be bound by confidentiality obligations at least as stringent as
          the one provided for in this Agreement.{" "}
        </p>

        <p>
          <strong>19.2</strong>The obligations of confidentiality do not apply
          to information required to be disclosed by operation of law or by
          court or administrative order. Users will provide LIBERDAT with prior
          written notice of any such requirement and will cooperate with
          LIBERDAT in the disclosure of the information.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>19.3</strong>3 Users agree to treat the Platform as
          Confidential Information for an unlimited duration.
        </p>

        <h2>20. Warranty & liability</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>20.1</strong> LIBERDAT is not liable for any direct or
          indirect damages incurred by Users of the Platform.
        </p>

        <p>
          <strong>20.2</strong>Users acknowledge that the Platform only
          facilitates data exchange between Researchers and Individuals and does
          not provide medical advice. Users acknowledge that the Platform may
          not be used as a substitute for medical advice, diagnosis, prevention,
          monitoring or treatment of any health condition or problem.{" "}
        </p>

        <p>
          <strong>20.3</strong> Researchers acknowledge and agree that they and
          their organisation are solely responsible for any interpretation of
          data bought via the Platform. Reseachers acknowledge and agree that
          this license does not constitute ethical approval of any kind for
          academic or clinical use and that Reseachers are solely responsible
          for obtaining prior ethical approval for use of the Platform within
          the terms of this Agreement.
        </p>

        <p>
          <strong>20.4</strong>Users acknowledge that LIBERDAT is not
          responsible for the accuracy or veracity of the credentials or
          qualifications of either Individuals or Reseachers and their
          affiliated institution. Users agree that they are solely responsible
          for transacting with other Users on the Platform.
        </p>

        <p>
          <strong>20.5</strong>Users acknowledge that the Platform is made
          available for free. The Platform is provided in its present form and
          LIBERDAT makes no representations and extends no warranties of any
          kind, either expressed or implied with respect to the Platform. There
          are no expressed or implied warranties of merchantability or fitness
          for a particular purpose or warranties that the use of the Platform
          will not infringe any patent, copyright, trademark, or other
          proprietary rights. Users will indemnify and hold LIBERDAT harmless
          from all losses, damages, expenses, costs and other liabilities in
          connection with use of the Platform and data.
        </p>

        <p>
          <strong>20.6</strong>LIBERDAT is not liable for any failure in
          performance arising out of causes beyond its control or for any direct
          or indirect damages caused to Users or third parties arising out of
          use of the Platform or the data, unless caused by the wilful
          misconduct of LIBERDAT.{" "}
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>20.7</strong>LIBERDAT’s liability will not be limited to the
          extent such limitation is not permitted by law or to the extent that
          damages are caused by wilful misconduct of LIBERDAT.
        </p>

        <h2>21. Duration</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>21.1</strong> This Agreement is effective from the date Users
          register for the Platform and remains in force until either account
          deletion by Users or LIBERDAT terminates this Agreement in case of a
          violation of these Terms & Conditions.
        </p>

        <p>
          <strong>21.2</strong>Neither Party will on termination of this
          Agreement be relieved of obligations accrued prior to the date of
          termination and neither will termination affect any rights of a Party
          accrued prior to the date of termination. The obligations of the
          parties contained in paragraph 13, 16, 17, 18, 19 will survive the end
          of the Agreement.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>21.3</strong>Upon termination of this Agreement Users are
          mandated to cease using the LIBERDAT Services. Furthermore, Users are
          obliged to state in writing to LIBERDAT that all Transferred Data has
          been destroyed.
        </p>
        <h2>22. Miscellaneous</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>22.1</strong>Any notice authorized or required to be given to
          LIBERDAT under this Agreement shall be in writing to: <br />
          Liberdat B.V. <br />
          Boxbergerweg 140-F6 <br />
          7413 EL, Deventer <br />
          The Netherlands <br />
          <a href="mailto:info@liberdat.io">info@liberdat.io</a>
        </p>

        <p>
          <strong>22.2</strong> LIBERDAT may assign, transfer or sublicense this
          Agreement or parts thereof to third parties without prior User
          consent.
        </p>

        <p>
          <strong>22.3</strong> Non-compliance by a Party under this Agreement
          will not be considered a breach of this Agreement if caused by
          occurrences beyond the reasonable control of the Party affected.
        </p>

        <p>
          <strong>22.4</strong> Users are encouraged to provide LIBERDAT with
          feedback on the Platform. LIBERDAT is permitted to use feedback for
          amending changes to the Platform.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>22.5</strong> These Terms & Conditions supersede all prior
          oral or written agreements and understandings between the Parties.
        </p>

        <h2>23. Changes</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "32px" }}>
          The Terms & Conditions are subject to changes over time. The changes
          will not be retroactive, and the most current version of the Terms &
          Conditions, which can be found{" "}
          <Link
            to="/termsandconditions"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            here
          </Link>
          , will govern our relationship with Users. LIBERDAT notifies Users of
          any upcoming changes 30 days in advance via the Platform. By
          continuing use of the LIBERDAT services after 30 days, Users agree to
          be bound by the revised Terms & Conditions.
        </p>

        <h2>24. Disputes</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>24.1</strong> In the event of disputes in the interpretation
          of this Agreement, the Parties will first undertake to settle
          differences amicably.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>24.2</strong> This Agreement is governed by the laws of The
          Netherlands, to the exclusion of any conflict-of-laws rules which
          would cause the laws of another jurisdiction to apply. Any dispute
          related to this Agreement will be adjudicated exclusively by the Dutch
          courts.
        </p>

        <h1 style={{ textAlign: "center", margin: "45px" }}>Appendix 1.</h1>
        <hr style={{ marginBottom: "18px" }} />
        <h1 style={{ textAlign: "center", margin: "45px" }}>
          DATA PROCESSING AGREEMENT
        </h1>
        <h2>1. General</h2>
        <hr style={{ marginBottom: "18px" }} />

        <p>
          <strong>1.1</strong>Regarding the processing of personal data by the
          Processor on behalf of the Controller, the Reseacher is the
          “controller” and LIBERDAT is the “processor” according to the meaning
          of{" "}
          <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&qid=1691404126036">
            EU Regulation 2016/679 of 27 April 2016
          </a>{" "}
          on the protection of individuals with regard to the processing of
          personal data and on the free movement of such data (“GDPR”).
        </p>

        <p>
          <strong>1.2</strong> The definition of “Data Subject”, “Member State”,
          “Personal Data”, “Personal Data Breach”, “Processing”, “Processor”,
          and “Supervisory Authority” are as mentioned in the GDPR.
        </p>

        <p>
          <strong>1.3</strong> The processing concerns the types of personal
          data as described in the subappendix of this Data Processing
          Agreement.
        </p>

        <p>
          <strong>1.4</strong> The processing concerns data subjects as
          described in the subappendix of this Data Processing Agreement.
        </p>

        <p>
          <strong>1.5</strong> The processing will be carried out for the
          purpose(s) as described in the subappendix of this Data Processing
          Agreement, or the legal or judicial obligations of the Processor or
          the Controller, and for the duration of the processing of personal
          data on the basis of this agreement or for as long as is necessary for
          the fulfilment of the legal or judicial obligations of the Processor.
          The Processor informs the Controller in advance, if this takes place
          with the aim of fulfilling the legal or judicial obligations of the
          Processor or the Controller unless forbidden by law.
        </p>

        <p>
          <strong>1.6</strong> The Processor processes personal data on the
          Controller's instructions, including with regard to transfer of
          personal data to a third country or an international organisation,
          unless required by a provision of Union or Member State law to which
          the Processor is subject; in that case, the Processor informs the
          Controller of the legal obligation prior to processing unless
          prohibited by law.
        </p>

        <p>
          <strong>1.7</strong> Relocation of the LIBERDAT Services to a third
          country requires prior consent of the Controller and may take place if
          the requirements of Articles 44 of the GDPR are satisfied.
        </p>

        <h2>2. Subprocessors </h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>2.1</strong> The Controller grants the Processor rights to
          engage other subprocessors.
        </p>

        <p>
          <strong>2.2</strong> The Controller can object to the appointment or
          replacement of other processors. In such case, either Party may
          terminate this Agreement, without judicial intervention and without
          compensation, with effect from the date on which the appointment or
          replacement takes effect.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>2.3</strong> If a subprocessor fails to fulfill its data
          protection obligations, the Processor remains liable.
        </p>

        <h2>3. Confidentiality</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>3.1</strong> The Processor treats personal data strictly
          confidential and does not disclose it to third parties without prior
          Controller consent unless required by legal or judicial obligation.
        </p>

        <p>
          <strong>3.2</strong> The Processor discloses Controller personal data
          to its employees, contractors, directors, agents and representatives
          who are directly involved in the execution of this agreement.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>3.3</strong> The Processor ensures that individuals,
          subcontractors, and third parties authorised for Controller personal
          data processing, per the request of the processor, are obliged to
          maintain confidentiality under the GDPR, other data protection
          legislation, and this agreement.
        </p>

        <h2>4. Security</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>4.1</strong> The Processor will ensure that it provides
          sufficient guarantees to implement appropriate technical and
          organisational measures to ensure that the processing complies with
          the requirements of the GDPR and that the data subject's rights are
          protected.
        </p>

        <p>
          <strong>4.2</strong> The Processor will take all measures required
          under the GDPR Article 32.
        </p>

        <p>
          <strong>4.3</strong> The data security measures may be correspondingly
          adapted to technical and organizational developments as long as they
          do not fall below the threshold levels stipulated herein. The
          Processor will implement any changes required to maintain information
          security without undue delay and taking into account the requirements
          of GDPR Article 32.
        </p>

        <p>
          <strong>4.4</strong> At the Controller's request, the Processor will
          inform the Controller in writing of these measures as well as the
          measures taken by its sub-processors.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>4.5</strong> The Processor will conform to the standards of
          approved codes of conduct and certification mechanisms as applicable
          within the industry. At the first request of the Controller, the
          Processor will provide proof thereof.
        </p>

        <h2>5. Compliance</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>5.1</strong> The Processor will make available to the
          Controller all information necessary to demonstrate compliance with
          the GDPR Article 28 and will allow audits including inspections, by
          the Controller or any other auditor authorized by the Controller and
          cooperate with them in accordance with Article 7.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>5.2</strong> The Processor guarantees that there are no
          obligations arising from applicable legislation that make it
          impossible to comply with the obligations of this agreement.{" "}
        </p>

        <h2>6. Information & assistance</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>6.1</strong> The Processor will, taking into account the
          nature of the processing, assist the Controller with appropriate
          technical and organizational measures, to the extent reasonably
          possible, to fulfil the obligation of the Controller to comply with
          requests for the exercise of the data subject's rights laid down in
          Chapter III GDPR.
        </p>

        <p>
          <strong>6.2</strong> The Processor will assist the Controller in
          complying with the obligations of the GDPR Articles 32 to 36, to the
          extent reasonably possible, taking into account the nature of the
          processing and the information available to the Processor.
        </p>

        <p>
          <strong>6.3</strong> The Controller will inform the Processor in
          writing of the name and contact details of its data protection officer
          or the Controller's employee(s) responsible for data protection.
        </p>

        <p style={{ marginBottom: "32px" }}>
          <strong>6.4</strong> The Processor will inform the Controller
          immediately if, in its opinion, an instruction violates the GDPR or
          any other Union or Member State law provision on data protection. In
          this case, the Processor has the option of suspending the
          implementation of the instruction concerned until it is confirmed or
          amended by the Controller.
        </p>

        <h2>7. Audit</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "22px" }}>
          The Processor will inform the Controller without delay if it considers
          that an order violates the GDPR or other data protection legislation
          of the EU or a Member State. An audit can be carried out if the
          Processor has been notified by registered letter at least 3 weeks in
          advance, no more than 2 times per contract year, and on all days
          (between 9 a.m. and 6 p.m.) except Saturdays, Sundays, days which are
          a bank holiday in the country where the Processor is established, and
          days on which the Processor is collectively closed due to holidays.
          Audits are carried out at the expense of the Controller.
        </p>

        <h2>8. Deletion or return</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p style={{ marginBottom: "22px" }}>
          The Processor, at the choice of the Controller, ensures that, at the
          end of the service relating to the processing, it deletes or returns
          all Personal Data and deletes existing copies, unless a provision of
          Union or Member State law provides for the retention of such Personal
          Data.
        </p>

        <h2>9. Data breaches</h2>
        <hr style={{ marginBottom: "18px" }} />
        <p>
          <strong>9.1</strong> In the event of a personal data breach within the
          meaning of the GDPR Article 4.12 with respect to personal data of the
          Controller processed by the Processor or its sub-processors, the
          Processor will without undue delay inform the Controller by telephone
          and by e-mail of the breach, and the Processor will provide all
          information necessary to enable the Controller to fulfil its
          obligations under the GDPR Articles 33 and 34.
        </p>

        <p>
          <strong>9.2</strong> The Processor will immediately take all measures
          necessary to limit and remedy the breach and will assist the
          Controller, at its first request, and supervisory authorities in
          investigating the breach. These measures are taken in consultation
          with the Controller unless in cases of extreme urgency which require
          the immediate intervention of the Processor.
        </p>

        <p>
          <strong>9.3</strong> Unless required by law or expressly instructed in
          writing by the Controller, the Processor will not pass on any
          information regarding a personal data breach to any third party.
        </p>

        <p style={{ marginBottom: "22px" }}>
          <strong>9.4</strong> The Processor and its sub-processor will appoint
          among their staff a single point of contact who will be responsible
          for all communication between the Processor, the sub-processor and the
          Controller in the event of an incident which has led or may lead to an
          accidental or non-authorized destruction or loss, or a non-authorized
          access, alteration or transmission of the personal data processed on
          behalf of the Controller.
        </p>

        <h2 style={{ textAlign: "center", margin: "45px" }}>Subappendix</h2>
        <hr style={{ marginBottom: "18px" }} />

        <p style={{ marginBottom: "7px" }}>
          <strong>Personal Data:</strong>
        </p>
        <br></br>
        <ul>
          <li>Identification Data</li>
          <ul style={{ listStyleType: "disc" }}>
            <li>Username and password;</li>
            <li>
              Institution name, department name, and corresponding institutional
              e-mail address (only Reseachers);
            </li>
            <li>Bank account details (processed by Stripe Inc.);</li>
            <li>IP address (for troubleshooting purposes only);</li>
          </ul>
          <li>User Data</li>
          <ul style={{ listStyleType: "disc" }}>
            <li>Buy orders;</li>
            <li>
              Profile data (provided optionally by users such as
              sociodemographic and clinical variables);
            </li>
          </ul>
          <li>Transacted data</li>
          <ul style={{ listStyleType: "disc" }}>
            <li>Data from Apple Health and Google Fit;</li>
            <li>Questionnaire data.</li>
          </ul>
        </ul>
        <p style={{ marginBottom: "7px" }}>
          <strong>Data subjects:</strong>
        </p>
        <br></br>
        <ul style={{ listStyleType: "disc" }}>
          <li>Users who register on the Platform as a Seller.</li>
        </ul>
        <p style={{ marginBottom: "7px" }}>
          <strong>
            LIBERDAT performs the below-mentioned tasks on behalf of Researchers
          </strong>
        </p>
        <br></br>
        <ul style={{ listStyleType: "disc" }}>
          <li>Collection of consent from sellers to process Personal Data;</li>
          <li>Standard analytics provided by the Platform;</li>
          <li>Temporary storage of transacted Data;</li>
          <li>Rendering Transacted Data downloadable for Researchers;</li>
        </ul>

        <h1 style={{ textAlign: "center", margin: "45px" }}>Appendix 2.</h1>
        <h1 style={{ textAlign: "center", margin: "45px" }}>
          {" "}
          STANDARD CONTRACTUAL CLAUSES
        </h1>
        <hr style={{ marginBottom: "18px" }} />

        <p>
          {" "}
          LIBERDAT appeals to Module 4 of the Standard Contractual Clauses
          (SCCs) for the international transfer of Transacted Data. The SCCs
          refer to Module 4 of the Commission Implementing Decision (EU)
          2021/914 of 4 June 2021 on standard contractual clauses for the
          transfer of personal data to third countries pursuant to Regulation
          (EU) 2016/679 of the European Parliament and of the Council.
        </p>

        <p>
          <strong>Regarding the SCCs:</strong>
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            LIBERDAT is the data exporter and its activities comprise the
            transfer of transacted Data Items as data processor to Researchers
            as data controller;
          </li>
          <li>
            Researchers are data importers and their activities comprise
            processing transacted Data Items as data controllers;
          </li>
          <li>
            Controller and processor do not enter into a docking clause (Clause
            7(a)-(c));
          </li>
          <li>
            No agreement has been made on an additional redress mechanism for
            data subjects (Clause 11(a)) between the Parties;
          </li>
          <li>Dutch law applies.</li>
        </ul>

        <p>
          <strong>Regarding Annex I.B:</strong>
        </p>
        <ul style={{ listStyleType: "disc" }}>
          <li>
            Data subjects are referred to in the Data Processing Agreement;
          </li>
          <li>
            Personal Data is referred to in the Data Processing Agreement where
            it is sorted into categories;
          </li>
          <li>
            Depending on the amount of Agreements the controller and processor
            engage in, the frequency of the transfer can be either one-off (one
            Agreement) or continuous (multiple Agreements);
          </li>
          <li>The processing is described in the Terms & Conditions;</li>
          <li>
            The purpose of the transfer consists of the tasks as stated in the
            Data Processing Agreement;
          </li>
          <li>
            The retention period is set by the data controller who has bought
            the data;
          </li>
          <li>There is no transfer of personal data to subprocessors.</li>
        </ul>
      </div>

      <div className="cls" onClick={handleCloseModal}>
        <IoIosCloseCircle size={28} style={{ cursor: "pointer" }} />
      </div>
    </Modal>
  );
};

export default PrivacyModal;
