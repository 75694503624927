import React, { useEffect } from "react";
import { useLocation, useNavigate} from "react-router-dom";
import { Form } from "react-bootstrap";
import CustomTextField from "../../components/customtextfield/CustomTextField";
import { useFormik } from "formik";
import * as yup from "yup";
import "./style.scss";
import CustomButton from "../../components/custombutton/CustomButton";
import { useGetMyBidsQuery, useUpdateBidMutation } from "../../store/api";
import toast from "react-hot-toast";


const UpdateBids = () => {
  const [updateBidMutation, {  isSuccess, isError, error }] =
    useUpdateBidMutation();
  const { refetch } = useGetMyBidsQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Updated!");
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } else if (isError) {
      toast.error(error.data.message);
    }
  }, [isSuccess, isError, error, navigate, refetch]);

  const location = useLocation();
  const { bid, redirectToTop } = location.state;
  useEffect(() => {
    if (redirectToTop) {
      window.scrollTo(0, 0);
    }
  }, [redirectToTop]);
  // console.log(bid);

  const initialValues = {
    title: bid.title,
    price: bid.price.amount,
    pickerforPrice: bid.price.currency,
    dataMeasure: bid.dataMeasure,
    source: bid.source,
    interval: bid.duration.interval,
    number: bid.duration.number,
  };

  // console.log("interval: ",  bid.duration.interval);
  // console.log("duration: ",  bid.duration.number);

  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    price: yup.number().required("Price is required"),
  });

  // function handleButtonClick() {

  //  navigate(RouteNames.MyBids.route)
  // }

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async (values) => {
      // console.log("VALUESSSfffgfg::", values);

      const bidData = {
        title: values.title,
        dataMeasure: values.dataMeasure,
        source: values.source,
        duration: {
          interval: values.interval,
          number: values.number,
        },

        price: {
          amount: values.price,
          currency: values.pickerforPrice,
        },
      };
      // console.log("BID DATA=>", bidData);

      const params = {
        bidData,
        id: bid.id,
      };
     

      await updateBidMutation(params);
      try {
      
      } catch (error) {
        toast.error(error)
        // console.error("Error updating bid:", error);
      }
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="container65">
          <h1>Customize Buy</h1>
          <div className="fields">
            <CustomTextField
              label="Title"
              type="text"
              name="title"
              formik={formik}
            />
            <CustomTextField
              label="Price"
              type="number"
              name="price"
              formik={formik}
            />
            <Form.Select
              className="priceform"
              id="pickerforPrice"
              name="pickerforPrice"
              value={formik.values.pickerforPrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="$">$</option>
              <option value="€">€</option>
            </Form.Select>

            <CustomButton
              style={{ paddingBottom: "15px" }}
              label={"Update"}
              color={"#333333"}
              textcolor={"white"}
              type="submit"
              width={"100%"}
              // onClick={handleButtonClick}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default React.memo(UpdateBids);
