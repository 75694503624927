import React, { useState } from 'react';
import { Button, Form, InputGroup, ListGroup, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../routes/RouteNames';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import './style.scss';
import { MdDelete } from 'react-icons/md';

function SurveyComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [surveyTitle, setSurveyTitle] = useState('');
  const [questions, setQuestions] = useState([]); // Initially, no questions
  const [showDropdown, setShowDropdown] = useState(false);

  const { bidId } = location.state;
  const bidRef = bidId;

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', options: [''] }]);
    setShowDropdown(false);
  };
  const getOptionLabel = (index) => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    return `${letters[index]}) `;
  };
  const handleTitleChange = (e) => {
    setSurveyTitle(e.target.value);
  };

  const handleAddQuestionWithoutOptions = () => {
    setQuestions([...questions, { text: '', options: null }]);
    setShowDropdown(false);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleQuestionTextChange = (index, text) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = text;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.push('');
    setQuestions(updatedQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleOptionTextChange = (questionIndex, optionIndex, text) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = text;
    setQuestions(updatedQuestions);
  };

  const surveySchema = Yup.object().shape({
    surveyTitle: Yup.string().required('Survey title is required').max(100, 'Survey title should not exceed 100 characters'),
    questions: Yup.array().of(
      Yup.object().shape({
        text: Yup.string().required('Question text is required'),
        options: Yup.array()
          .nullable()
          .of(Yup.string().required('Option text is required'))
          .min(2, 'At least two options are required'),
      })
    ),
  });

  const validateForm = async () => {
    try {
      await surveySchema.validate({ questions, surveyTitle }, { abortEarly: false });
      return true; // The form is valid
    } catch (errors) {
      const validationErrors = {};
      console.error('Caught Errors:', errors);
      if (errors.inner) {
        errors.inner.forEach((error) => {
          const path = error.path;
          validationErrors[path] = error.message;
        });
      }
      Object.values(validationErrors).forEach((error) => {
        toast.error(error); // Display each error message as a toast notification
      });
      return false;
    }
  };

  const handleCompleteSurvey = async () => {
    const isValid = await validateForm();
    if (isValid) {
      const questionnaire = questions.map((question) => ({
        text: question.text,
        options: question.options,
      }));
      
      navigate(RouteNames.SurveyViewer.route, {
        state: { questionnaire, bidRef, surveyTitle },
      });
    }
  };

  return (
    <div className="Container">
      <h2 style={{ padding: '24px' }}>Make Your Own Survey</h2>
      
      <Form className="survey-form">
        <InputGroup className="mb-3" style={{ width: '50%', margin: 'auto' }}>
          <Form.Control
            className="titleQuestionnaire"
            size='lg'
            
            placeholder="Enter the title of the survey"
            value={surveyTitle}
            onChange={handleTitleChange}
          />
        </InputGroup>

        {questions.length > 0 && (
          <ListGroup>
            {questions.map((question, questionIndex) => (
              <ListGroup.Item key={questionIndex}>
                <h4>{questionIndex+1}.</h4> 
                <InputGroup className="mb-3" >
                <Form.Control
  style={{ height: '39px', fontSize: '18px', border: 'none', borderBottom: '1px solid #bbbbbb',marginRight:'5%' }} 
  className="form-control question-input"
  size="lg"
  placeholder="Enter your question"
  value={question.text}
  onChange={(e) => handleQuestionTextChange(questionIndex, e.target.value)}
  // onFocus={(e) => (e.target.style.borderBottom = '2px solid #5b99a6')} // Change border on focus
  // onBlur={(e) => (e.target.style.borderBottom = '1px solid #bbbbbb')}   // Change border back on blur
/>
                    <MdDelete
                            size={26}
                            className="del-icons"
                            color='#D70040'
                            onClick={() => handleRemoveQuestion(questionIndex)}
                          />
                </InputGroup>

                {question.options ? (
                  question.options.map((option, optionIndex) => (
                    <InputGroup key={optionIndex} className="mb-3" style={{ width: '69%' }}>
                     <h4 className='mx-2'>{getOptionLabel(optionIndex)}</h4>  <Form.Control
                        className="form-control"
                        style={{border: 'none', borderBottom: '1px solid #bbbbbb', height: '39px', fontSize: '15px',marginRight:'4%' }}
                        type="text"
                        placeholder="Enter an option"
                        value={option}
                        onChange={(e) => handleOptionTextChange(questionIndex, optionIndex, e.target.value)}
                      />
                      <MdDelete
                            size={26}
                            className="del-icons"
                            color='#D70040'
                            onClick={() => handleRemoveOption(questionIndex, optionIndex)}
                          />
                    </InputGroup>
                  ))
                ) : (
                  <p style={{marginTop:'-5px'}}>
                    {/* (This will have an open answer). */}
                    </p>
                )}

                {question.options && (
                  <Button
                    variant="primary"
                    style={{ backgroundColor: 'black', border: 'none' }}
                    onClick={() => handleAddOption(questionIndex)}
                  >
                    Add an option
                  </Button>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '17px' }}>
          <Dropdown show={showDropdown}>
            <Dropdown.Toggle
              style={{ backgroundColor: 'black', border: 'none' }}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              Add Question
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleAddQuestion}>Multiple choice</Dropdown.Item>
              <Dropdown.Item onClick={handleAddQuestionWithoutOptions}>Descriptive</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Form>

      <div className="complete">
        <Button
          style={{ width: '45%', backgroundColor: '#5b99a6', border: 'none',height:'47px' }}
          type="button"
          className="completeButton"
          disabled={questions?.length == 0}
          onClick={handleCompleteSurvey}
        >
          Complete
        </Button>
      </div>
    </div>
  );
}

export default SurveyComponent;
