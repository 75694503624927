import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RouteNames from "./routes/RouteNames";

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.auth?.user?.user);
      const twoFA=localStorage.getItem("twoFAliberdat")
  if (!user) {
    // If no user, redirect to login or home
    return <Navigate to={RouteNames.Login.route} replace />;
  }
  if(user && twoFA=='not authenticated'){
    return <Navigate to={RouteNames.Authentication.route} replace />;
  }

  return children;
};

export default ProtectedRoute;
