import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import './style.scss'; 

import CustomButton from "../../components/custombutton/CustomButton";

function SurveyViewer() {
  const location = useLocation();
  const { questions } = location.state;

  // console.log("questions::", questions)

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      selectedOptions: Array(questions.length).fill({ question: null, selectedOption: null }),
    },
    validationSchema: Yup.object().shape({
      selectedOptions: Yup.array()
        .of(
          Yup.object().shape({
            question: Yup.string().required('Please select an option for each question'),
            selectedOption: Yup.string().required('Please select an option for each question'),
          })
        )
        .required('Please select an option for each question'),
    }),
    onSubmit: (values) => {
   
      // You can now save or process this data as needed
    },
  });

  const handleOptionChange = (questionIndex, optionIndex) => {
    const newSelectedOptions = [...formik.values.selectedOptions];
    newSelectedOptions[questionIndex] = {
      question: questions[questionIndex].text,
      selectedOption: questions[questionIndex].options[optionIndex],
    };
    formik.setFieldValue('selectedOptions', newSelectedOptions);
    // console.log("newSelectedOptions",newSelectedOptions)
  };

 


  const handleSubmitSurvey = () => {

    
   
    if (!formik.isValid) {
      // Display an error toast using react-hot-toast
      toast.error('Please select an option for each question');
    } else {
      // Proceed with form submission if validation passes
      formik.submitForm();
      
    }
  };

  return (
    <div className="survey-container">
      <h2>Your Survey</h2>
      <form onSubmit={formik.handleSubmit}>
        {questions.map((question, questionIndex) => (
          <div key={questionIndex} className="question-container">
            <p style={{ fontWeight: 'bold' }}>{question.text}</p>
            <ul>
              {question.options.map((option, optionIndex) => (
                <li key={optionIndex}>
                  <label>
                    <input
                      type="radio"
                      name={`question_${questionIndex}`}
                      onChange={() => handleOptionChange(questionIndex, optionIndex)}
                      checked={formik.values.selectedOptions[questionIndex]?.selectedOption === option}
                    />
                    &nbsp;{option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
     
        <CustomButton
                type={'button'}
                style={{ paddingBottom: '15px' }}
                label={'Submit Survey'}
                color={'#333333'}
                textcolor={'white'}
                width={'50%'}
                onClick={handleSubmitSurvey}
              />
      </form>
    </div>
  );
}

export default SurveyViewer;