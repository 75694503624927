import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import authSlice from "./reducers/authSlice"; 
import notificationsSlice from './reducers/notificationsSlice';
import { setupListeners } from "@reduxjs/toolkit/query";
import api from "./api";




// Configuration for auth slice's persistent storage
const authPersistConfig = {
  key: "auth",
  storage, // Use localStorage for web
};
const notificationCountPersistConfig = {
  key: 'notiCount',
  storage, // Use localStorage for ReactJS
};
// Create a persisted auth reducer
const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);
const persistedNotificationReducer = persistReducer(notificationCountPersistConfig, notificationsSlice);

// Combine reducers, including the API reducer and persisted auth reducer
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: persistedAuthReducer,
  notiCount: persistedNotificationReducer,
 
});

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});
setupListeners(store.dispatch);
export const persistor = persistStore(store);

export default store;
