import React, { useEffect, useRef, useState } from 'react';
import { Modal, Col, Row, Container } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import CustomTextField from '../../components/customtextfield/CustomTextField';
import CustomButton from '../../components/custombutton/CustomButton';
import DatePicker from 'react-datepicker';
import './customizemodel.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { useAssignBidMutation } from '../../store/api';

function CustomizeModal({
  show,
  handleClose,
  bid,
  username,
  userId,
  selectedUserId,
  refetch,
}) {
  // Get today's date
  const [assignBid, { isSuccess, isError, error }] = useAssignBidMutation(
    selectedUserId?.id
  );
  const datePickerstartRef = useRef(null);
  const datePickerendRef = useRef(null);
  const [priceDisable, setPriceDisable] = useState(false);


  // console.log('userId in Customize Model: ', userId);

  // Set the minimum date for the start date (6 months ago)
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const [startDate, setStartDate] = useState(sixMonthsAgo);

  // Set the maximum date for the end date (today)
  const today = new Date();
  const [endDate, setEndDate] = useState(today);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Successfully assigned');
      refetch(bid._id);
      setTimeout(() => {
        handleClose();
      }, 500);
    } else if (isError) {
      toast.error(error.data.message);
    }
  }, [isSuccess, isError]);

  const handleEndDateClick = () => {
    if (datePickerendRef.current !== null) {
      datePickerendRef.current.setOpen(true);
    }
  };

  const handleStartDateClick = () => {
    if (datePickerstartRef.current !== null) {
      datePickerstartRef.current.setOpen(true);
    }
  };

  const initialValues = {
    price: bid?.price?.amount,
    pickerforPrice: bid?.price?.currency,
    dataMeasure: bid?.dataMeasure,
  };

  const validationSchema = yup.object({
    price: yup.number().required('Price is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: async (values) => {
      // const startDate = values.startDate; // Assuming startDate is part of your values
      // const endDate = values.endDate; // Assuming endDate is part of your values
      // const today = new Date();

      const params = {
        bidData: {
          price: {
            amount: Number(values.price),
            currency: values.pickerforPrice,
          },
          status: "Requested"
        },
        bidId: selectedUserId.id
      }
      if (!(selectedUserId.bidRef?.configure?.steps?.configType === 'Relative' || selectedUserId.bidRef?.configure?.flights?.configType === 'Relative' || selectedUserId.bidRef?.configure?.elevation?.configType === 'Relative' || selectedUserId.bidRef?.configure?.noOfPlacesVisited?.configType === 'Relative' || selectedUserId.bidRef?.configure?.placesVisited?.configType === 'Relative' || selectedUserId?.dataItem?.specifiedValues?.some(i => i.value === 'Applications_Location_Google_Timeline'))) {
        const datesss = { startDate: startDate, endDate: endDate }
        if (selectedUserId?.dataItem?.specifiedValues?.some(i => i?.value === 'Applications_Health_Apple_Steps' || i?.value === 'Applications_Health_Google_Steps')) {
          params.bidData.steps = datesss
        }
        if (selectedUserId?.dataItem?.specifiedValues?.some(i => i?.value === 'Applications_Health_Apple_FlightsClimbed')) {
          params.bidData.flights = datesss
        }
        if (selectedUserId?.dataItem?.specifiedValues?.some(i => i?.value === 'Applications_Health_Google_Elevation')) {
          params.bidData.elevation = datesss
        }
        if (selectedUserId?.dataItem?.specifiedValues?.some(i => i?.value === 'Applications_Location_History_Place visits')) {
          params.bidData.placesVisited = datesss
        }
        if (selectedUserId?.dataItem?.specifiedValues?.some(i => i?.value === 'Applications_Location_History_Number of places visited')) {
          params.bidData.noOfPlacesVisited = datesss
        }
      }
      // console.log("PARAMSSS",params)
      await assignBid(params);
      // // Check if both startDate and endDate are provided (not empty)
      // if (!startDate && !endDate) {
      //   // If both startDate and endDate are empty, simply submit the form without validations
      //   const params = {
      //     bidData: {
      //       price: {
      //         amount: values?.price,
      //         currency: values.pickerforPrice,
      //       },
      //       status: 'Requested',
      //     },
      //     bidId: selectedUserId?.id,
      //   };

      //   await assignBid(params);
      //   // console.log("params", params);

      //   try {
      //     // Handle success or additional actions if needed
      //   } catch (error) {
      //     toast.error('Error updating bid');
      //   }

      //   return; // Exit the onSubmit function early
      // }

      // // Validate if the start date is within the last 6 months
      // const sixMonthsAgo = new Date();
      // sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

      // if (startDate < sixMonthsAgo) {
      //   toast.error('Please select a start date within the last 6 months');
      //   return; // Stop the submission
      // }

      // // Validate if the end date is within the range
      // if (endDate <= startDate || endDate > today) {
      //   toast.error('Please select an end date within the range');
      //   return; // Stop the submission
      // }

      // // If validations pass, continue with the rest of your onSubmit logic
      // const params = {
      //   bidData: {
      //     price: {
      //       amount: values?.price,
      //       currency: values.pickerforPrice,
      //     },
      //     startDate,
      //     endDate,
      //     status: 'Requested',
      //   },
      //   bidId: selectedUserId?.id,
      // };

      // await assignBid(params);
      // // console.log("params", params);

      try {
        // Handle success or additional actions if needed
      } catch (error) {
        toast.error('Error updating bid');
      }
    },
  });
  // console.log("Formik Values:", formik.values);

  // console.log("selectedUserId", selectedUserId?.sellerRef?.lastValidatedBy)

  useEffect(() => {

    if (selectedUserId?.sellerRef?.lastValidatedBy.includes(userId)) {
      formik.values.price = 0;
      setPriceDisable(true);
    }
    else {
      formik.values.price = 100;
      setPriceDisable(false);
    }
  }, [selectedUserId?.sellerRef?.lastValidatedBy])




  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Customize</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <div className="customizecontainer">
            <h1>{selectedUserId?.sellerRef ? selectedUserId?.sellerRef?.username : 'Customize'}</h1>

            <div className="fields w-100">
              <Container>
                <Row className="d-flex flex-row">
                  <Col xs={12}>
                    {/* <Form.Select
                      className="mt-2 mx-0"
                      id="title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Select Type</option>
                      <option value="Lung Cancer">Lung Cancer</option>
                      <option value="Multiple Sclerosis">Multiple Sclerosis</option>
                      <option value="Healthy">Healthy</option>
                    </Form.Select> */}
                  </Col>
                </Row>
                <Row className="d-flex flex-row">
                  <Col xs={7}>
                    <CustomTextField
                      label="Price"
                      type="number"
                      name="price"
                      disabled={priceDisable}
                      formik={formik}
                    />
                  </Col>
                  <Col xs={5}>
                    <div className="formlabel1">Currency</div>
                    <Form.Select
                      className="mt-2 mx-0"
                      id="pickerforPrice"
                      name="pickerforPrice"
                      value={formik.values.pickerforPrice}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="$">$</option>
                      <option value="€">€</option>
                    </Form.Select>
                  </Col>
                </Row>
                {/* <div className="d-flex">
                  <div>
                    <b>Data Measures : </b>
                  </div>
                  <div className="datameasures">
                    {bid?.dataMeasure ? bid.dataMeasure.join(',') : ''}
                  </div>
                </div> */}
              </Container>
              <>
                {!(
                  (bid?.dataItem?.specifiedValues?.some(i=>i?.value?.includes("Predict")) && !bid?.dataItem?.specifiedValues?.some(i=>i?.value?.includes("Applications_Health"))) ||
                  bid?.configure?.steps?.configType == 'Relative' ||
                  bid?.configure?.flights?.configType == 'Relative' ||
                  bid?.configure?.elevation?.configType == 'Relative' ||
                  bid?.configure?.placesVisited?.configType == 'Relative' ||
                  bid?.configure?.noOfPlacesVisited?.configType == 'Relative' ||
                  bid?.dataItem?.specifiedValues?.length === 0)
                  && (
                    <Container>
                      <Row className="d-flex flex-row ">
                        <Col sm={6}>
                          <div
                            className="dateborder"
                            onClick={handleStartDateClick}
                          >
                            <div> Start Date:</div>
                            <div>
                              <DatePicker
                                ref={datePickerstartRef}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="custom-datepicker"
                                maxDate={endDate}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div
                            className="dateborder"
                            onClick={handleEndDateClick}
                          >
                            <div> End Date:</div>
                            <div>
                              <DatePicker
                                ref={datePickerendRef}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="custom-datepicker"
                                maxDate={today}
                                minDate={startDate}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
              </>
              <Container>
                <CustomButton
                  style={{ paddingBottom: '15px' }}
                  label={'Assign'}
                  color={'#333333'}
                  textcolor={'white'}
                  type="submit"
                  width={'100%'}
                />
              </Container>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CustomizeModal;
