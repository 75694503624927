export const dataForGeneralTherapy = [
    {
        label: 'No therapies',
        displayName: 'No therapies',
        multiSelect: true,
        value: 'Therapies_No therapies',
        // parent: 'Therapies',
        data: [],
    },
    {
        label: 'Current',
        multiSelect: false,
        value: 'Therapies_Current',
        // parent: 'Therapies',
        data: [
            {
                label: 'Pharmaceutical',
                multiSelect: false,
                value: 'Therapies_Current_Pharmaceutical',
                parent: 'Therapies_Current',
                data: [
                    {
                        label: 'Tablet',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Tablet',
                        parent: 'Therapies_Current_Pharmaceutical',
                        data: [
                            {
                                label: 'Pyridostigmine bromide (Timespan®)',
                                displayName: 'Current - Pyridostigmine bromide (Timespan®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Timespan',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Prednisone (Generic)',
                                displayName: 'Current - Prednisone (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (Prednisone)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Cladribine (Mavenclad®)',
                                displayName: 'Current - cladribine (Mavenclad®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Mavenclad',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Semaglutide (Ozempic®)',
                                displayName: 'Current - Semaglutide (Ozempic®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Ozempic',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Pyridostigmine Bromide (Mestinon®)',
                                displayName: 'Current - Pyridostigmine Bromide (Mestinon®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Mestinon',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Dimethyl Fumarate (Tecfidera®)',
                                displayName: 'Current - Dimethyl Fumarate (Tecfidera®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Tecfidera',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Diroximel Fumarate (Vumerity®)',
                                displayName: 'Current - Diroximel Fumarate (Vumerity®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Vumerity',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Fingolimod (Gilenya®)',
                                displayName: 'Current - Fingolimod (Gilenya®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Gilenya',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Fingolimod (Tascenso ODT®)',
                                displayName: 'Current - Fingolimod (Tascenso ODT®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Tascenso',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Tolbutamide (Generic)',
                                displayName: 'Current - Tolbutamide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (tolbutamide)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Generic)',
                                displayName: 'Current - Metformin (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (Metformin)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glimepiride (Amaryl®)',
                                displayName: 'Current - Glimepiride (Amaryl®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Amaryl',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Monomethyl Fumarate (Bafiertam®)',
                                displayName: 'Current - Monomethyl Fumarate (Bafiertam®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Bafiertam',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ozanimod (Zeposia®)',
                                displayName: 'Current - Ozanimod (Zeposia®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Zeposia',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ponesimod (Ponvory®)',
                                displayName: 'Current - Ponesimod (Ponvory®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Ponvory',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Siponimod (Mayzent®)',
                                displayName: 'Current - Siponimod (Mayzent®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Mayzent',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Teriflunomide (Aubagio®)',
                                displayName: 'Current - Teriflunomide (Aubagio®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Aubagio',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Glimepiride (Generic)',
                                displayName: 'Current - Glimepiride (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (glimepiride)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Glucotrol®)',
                                displayName: 'Current - Glipizide (Glucotrol®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Glucotrol',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Glucotrol XL®)',
                                displayName: 'Current - Glipizide (Glucotrol XL®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Glucotrol XL',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Generic)',
                                displayName: 'Current - Glipizide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (glipizide)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (Micronase®)',
                                displayName: 'Current - Glyburide (Micronase®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Micronase',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (DiaBeta®)',
                                displayName: 'Current - Glyburide (DiaBeta®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_DiaBeta',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (Generic)',
                                displayName: 'Current - Glyburide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide, Micronized (Glynase PresTab®)',
                                displayName:
                                    'Current - Glyburide, Micronized (Glynase PresTab®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Glynase PresTab',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide, Micronized (Generic)',
                                displayName: 'Current - Glyburide, Micronized (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glucophage®)',
                                displayName: 'Current - Metformin (Glucophage®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Glucophage',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glucophage XR®)',
                                displayName: 'Current - Metformin (Glucophage XR®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Glucophage XR',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Fortamet®)',
                                displayName: 'Current - Metformin (Fortamet®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Fortamet',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glumetza®)',
                                displayName: 'Current - Metformin (Glumetza®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Glumetza',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Riomet®)',
                                displayName: 'Current - Metformin (Riomet®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Riomet',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Pioglitazone (Actos®)',
                                displayName: 'Current - Pioglitazone (Actos®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Actos',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Rosiglitazone (Avandia®)',
                                displayName: 'Current - Rosiglitazone (Avandia®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Avandia',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Alogliptin (Nesina®)',
                                displayName: 'Current - Alogliptin (Nesina®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Nesina',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Sitagliptin (Januvia®)',
                                displayName: 'Current - Sitagliptin (Januvia®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Januvia',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Saxagliptin (Onglyza®)',
                                displayName: 'Current - Saxagliptin (Onglyza®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Onglyza',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Linagliptin (Tradjenta®)',
                                displayName: 'Current - Linagliptin (Tradjenta®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Tradjenta',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Canagliflozin (Invokana®)',
                                displayName: 'Current - Canagliflozin (Invokana®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Invokana',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Dapagliflozin (Farxiga®)',
                                displayName: 'Current - Dapagliflozin (Farxiga®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Farxiga',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Empagliflozin (Jardiance®)',
                                displayName: 'Current - Empagliflozin (Jardiance®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Jardiance',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },






                            {
                                label: 'Azathioprine (Imuran®)',
                                displayName: 'Current - Azathioprine (Imuran®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Imuran',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Azathioprine (Generic)',
                                displayName: 'Current - Azathioprine (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (Azathioprine)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Mycofenolaatmofetil (Cellcept®)',
                                displayName: 'Current - Mycofenolaatmofetil (Cellcept®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Tablet_Cellcept',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Mycofenolaatmofetil (Generic)',
                                displayName: 'Current - Mycofenolaatmofetil (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Cyclosporin (Generic)',
                                displayName: 'Current - Cyclosporin (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                                parent: 'Therapies_Current_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },


                        ],
                    },
                    {
                        label: 'Injection',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Injection',
                        parent: 'Therapies_Current_Pharmaceutical',
                        data: [
                            {
                                label: 'Rozanolixizumab-noli (Rystiggo®)',
                                displayName: 'Current - Rozanolixizumab-noli (Rystiggo®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Injection_Rystiggo',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Zilucoplan (Zilbrysq®)',
                                displayName: 'Current - Zilucoplan (Zilbrysq®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Injection_Zilbrysq',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },



                            {
                                label: 'Exenatide (Byetta®)',
                                displayName: 'Current - Exenatide (Byetta®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Byetta',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Liraglutide (Victoza®)',
                                displayName: 'Current - Liraglutide (Victoza®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Victoza',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Albiglutide (Tanzeum®)',
                                displayName: 'Current - Albiglutide (Tanzeum®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Tanzeum',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Dulaglutide (Trulicity®)',
                                displayName: 'Current - Dulaglutide (Trulicity®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Trulicity',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glulisine (Apidra®)',
                                displayName: 'Current - Insulin Glulisine (Apidra®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Apidra',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Humalog®)',
                                displayName: 'Current - Insulin Lispro (Humalog®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Humalog',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Novorapid®)',
                                displayName: 'Current - Insulin Aspart (Novorapid®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Novorapid',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Fiasp®)',
                                displayName: 'Current - Insulin Aspart (Fiasp®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Fiasp',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Insulin Aspart Sanofi®)',
                                displayName: 'Current - Insulin Aspart (Insulin Aspart Sanofi®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Novomix®)',
                                displayName: 'Current - Insulin Aspart (Novomix®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Novomix',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Lyumjev®)',
                                displayName: 'Current - Insulin Lispro (Lyumjev®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Lyumjev',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Insulin Lispro Sanofi®)',
                                displayName: 'Current - Insulin Lispro (Insulin Lispro Sanofi®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Human (Humilin®)',
                                displayName: 'Current - Insulin Human (Humilin®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Humilin',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Human (Actraphane®)',
                                displayName: 'Current - Insulin Human (Actraphane®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Actraphane',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulan Human (Insulatard®)',
                                displayName: 'Current - Insulan Human (Insulatard®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Insulatard',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart/Degludeg (Ryzodeg®)',
                                displayName: 'Current - Insulin Aspart/Degludeg (Ryzodeg®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Ryzodeg',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Lantus®)',
                                displayName: 'Current - Insulin Glargine (Lantus®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Lantus',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Toujeo®)',
                                displayName: 'Current - Insulin Glargine (Toujeo®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Toujeo',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Abasaglar®)',
                                displayName: 'Current - Insulin Glargine (Abasaglar®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Abasaglar',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Detemir (Levemir®)',
                                displayName: 'Current - Insulin Detemir (Levemir®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Levemir',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Degludec (Tresiba®)',
                                displayName: 'Current - Insulin Degludec (Tresiba®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Tresiba',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },




                            {
                                label: 'Corticotropin (Purified Cortrophin Gel®)',
                                displayName:
                                    'Current - Corticotropin (Purified Cortrophin Gel®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Injection_Corticotropin',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Glatiramer Acetate (Copaxone®)',
                                displayName: 'Current - Glatiramer Acetate (Copaxone®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Copaxone',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon ß-1a (Avonex®)',
                                displayName: 'Current - Interferon β-1a (Avonex®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Avonex',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1a (Rebif®)',
                                displayName: 'Current - Interferon β-1a (Rebif®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Rebif',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1b (Betaseron®)',
                                displayName: 'Current - Interferon β-1b (Betaseron®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Betaseron',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1b (Extavia®)',
                                displayName: 'Current - Interferon β-1b (Extavia®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Extavia',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ofatumumab (Kesimpta®)',
                                displayName: 'Current - Ofatumumab (Kesimpta®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Kesimpta',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Peginterferon β‑1a (Plegridy®)',
                                displayName:
                                    'Current - Peginterferon β‑1a (Plegridy®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Injection_Plegridy',
                                parent: 'Therapies_Current_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                        ],
                    },
                    {
                        label: 'Infusion',
                        multiSelect: false,
                        value: 'Therapies_Current_Pharmaceutical_Infusion',
                        parent: 'Therapies_Current_Pharmaceutical',
                        data: [

                            {
                                label: 'Efgartigimod Alpha (Vyvgart®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Vyvgart',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Eculuzimab (Soliris®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Soliris',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Ravulizumab (Ultomiris®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Infusion_Ultomiris',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Nipocalimab®',
                                multiSelect: false,
                                value:
                                    'Therapies_Current_Pharmaceutical_Infusion_Nipocalimab',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },



                            {
                                label: 'Alemtuzumab (Lemtrada®)',
                                displayName: 'Current - Alemtuzumab (Lemtrada®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Lemtrada',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Mitoxantrone (Novantrone®)',
                                displayName: 'Current - Mitoxantrone (Novantrone®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Mitoxantrone',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Natalizumab (Tysabri®)',
                                displayName: 'Current - Natalizumab (Tysabri®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Tysabri',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ocrelizumab (Ocrevus®)',
                                displayName: 'Current - Ocrelizumab (Ocrevus®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Ocrevus',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ublituximab-xiiy (Briumvi®)',
                                displayName: 'Current - Ublituximab-xiiy (Briumvi®)',
                                multiSelect: false,
                                value: 'Therapies_Current_Pharmaceutical_Infusion_Briumvi',
                                parent: 'Therapies_Current_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Surgical',
                multiSelect: false,
                value: 'Therapies_Current_Surgical',
                parent: 'Therapies_Current',
                data: [
                    {
                        displayName: 'Current - Scalpel Incisions',
                        label: 'Scalpel Incisions',
                        multiSelect: false,
                        value: 'Therapies_Current_Surgical_Scalpel',
                        parent: 'Therapies_Current_Surgical',
                        data: [],
                    },
                    {
                        displayName: 'Current - Laparoscopic',
                        label: 'Laparoscopic',
                        multiSelect: false,
                        value: 'Therapies_Current_Surgical_Laparoscopic',
                        parent: 'Therapies_Current_Surgical',
                        data: [],
                    },
                    {
                        displayName: 'Current - Suture Closure',
                        label: 'Suture Closure',
                        multiSelect: false,
                        value: 'Therapies_Current_Surgical_Suture',
                        parent: 'Therapies_Current_Surgical',
                        data: [],
                    },
                ],
            },
            {
                label: 'Rehabilitation',
                multiSelect: false,
                value: 'Therapies_Current_Rehabilitation',
                parent: 'Therapies_Current',
                data: [
                    {
                        displayName: 'Current - Physical Therapy',
                        label: 'Physical Therapy',
                        multiSelect: false,
                        value: 'Therapies_Current_Rehabilitation_Physical',
                        parent: 'Therapies_Current_Rehabilitation',
                        data: [],
                    },
                    {
                        displayName: 'Current - Occupational Therapy',
                        label: 'Occupational Therapy',
                        multiSelect: false,
                        value: 'Therapies_Current_Rehabilitation_Occupational',
                        parent: 'Therapies_Current_Rehabilitation',
                        data: [],
                    },
                    {
                        displayName: 'Current - Speech and Language',
                        label: 'Speech and Language',
                        multiSelect: false,
                        value: 'Therapies_Current_Rehabilitation_Speech',
                        parent: 'Therapies_Current_Rehabilitation',
                        data: [],
                    },
                ],
            },
            {
                label: 'Psychological',
                multiSelect: false,
                value: 'Therapies_Current_Psychological',
                parent: 'Therapies_Current',
                data: [
                    {
                        displayName: 'Current - Cognitive Behavioral Therapy (CBT)',
                        label: 'Cognitive Behavioral Therapy (CBT)',
                        multiSelect: false,
                        value: 'Therapies_Current_Psychological_ (CBT)',
                        parent: 'Therapies_Current_Psychological',
                        data: [],
                    },
                    {
                        displayName: 'Current - Humanistic Therapy',
                        label: 'Humanistic Therapy',
                        multiSelect: false,
                        value: 'Therapies_Current_Psychological_Humanistic',
                        parent: 'Therapies_Current_Psychological',
                        data: [],
                    },
                    {
                        displayName: 'Current - Psychodrama',
                        label: 'Psychodrama',
                        multiSelect: false,
                        value: 'Therapies_Current_Psychological_Psychodrama',
                        parent: 'Therapies_Current_Psychological',
                        data: [],
                    },
                ],
            },
        ],
    },
    {
        label: 'Previous',
        multiSelect: false,
        value: 'Therapies_Previous',
        // parent: 'Therapies',
        data: [
            {
                label: 'Pharmaceutical',
                multiSelect: false,
                value: 'Therapies_Previous_Pharmaceutical',
                parent: 'Therapies_Previous',
                data: [
                    {
                        label: 'Tablet',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Tablet',
                        parent: 'Therapies_Previous_Pharmaceutical',
                        data: [
                            {
                                label: 'Pyridostigmine bromide (Timespan®)',
                                displayName: 'Previous - Pyridostigmine bromide (Timespan®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Timespan',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Prednisone (Generic)',
                                displayName: 'Previous - Prednisone (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (Prednisone)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Cladribine (Mavenclad®)',
                                displayName: 'Previous - cladribine (Mavenclad®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Mavenclad',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Semaglutide (Ozempic®)',
                                displayName: 'Previous - Semaglutide (Ozempic®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Ozempic',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Pyridostigmine Bromide (Mestinon®)',
                                displayName: 'Previous - Pyridostigmine Bromide (Mestinon®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Mestinon',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Dimethyl Fumarate (Tecfidera®)',
                                displayName: 'Previous - Dimethyl Fumarate (Tecfidera®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Tecfidera',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Diroximel Fumarate (Vumerity®)',
                                displayName: 'Previous - Diroximel Fumarate (Vumerity®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Vumerity',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Fingolimod (Gilenya®)',
                                displayName: 'Previous - Fingolimod (Gilenya®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Gilenya',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Fingolimod (Tascenso ODT®)',
                                displayName: 'Previous - Fingolimod (Tascenso ODT®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Tascenso',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Tolbutamide (Generic)',
                                displayName: 'Previous - Tolbutamide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (tolbutamide)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Generic)',
                                displayName: 'Previous - Metformin (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (Metformin)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glimepiride (Amaryl®)',
                                displayName: 'Previous - Glimepiride (Amaryl®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Amaryl',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Monomethyl Fumarate (Bafiertam®)',
                                displayName: 'Previous - Monomethyl Fumarate (Bafiertam®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Bafiertam',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ozanimod (Zeposia®)',
                                displayName: 'Previous - Ozanimod (Zeposia®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Zeposia',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ponesimod (Ponvory®)',
                                displayName: 'Previous - Ponesimod (Ponvory®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Ponvory',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Siponimod (Mayzent®)',
                                displayName: 'Previous - Siponimod (Mayzent®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Mayzent',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Teriflunomide (Aubagio®)',
                                displayName: 'Previous - Teriflunomide (Aubagio®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Aubagio',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Glimepiride (Generic)',
                                displayName: 'Previous - Glimepiride (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (glimepiride)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Glucotrol®)',
                                displayName: 'Previous - Glipizide (Glucotrol®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Glucotrol XL®)',
                                displayName: 'Previous - Glipizide (Glucotrol XL®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucotrol XL',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glipizide (Generic)',
                                displayName: 'Previous - Glipizide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (glipizide)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (Micronase®)',
                                displayName: 'Previous - Glyburide (Micronase®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Micronase',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (DiaBeta®)',
                                displayName: 'Previous - Glyburide (DiaBeta®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_DiaBeta',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide (Generic)',
                                displayName: 'Previous - Glyburide (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide, Micronized (Glynase PresTab®)',
                                displayName:
                                    'Previous - Glyburide, Micronized (Glynase PresTab®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Glynase PresTab',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Glyburide, Micronized (Generic)',
                                displayName: 'Previous - Glyburide, Micronized (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (glyburide, micronized)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glucophage®)',
                                displayName: 'Previous - Metformin (Glucophage®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucophage',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glucophage XR®)',
                                displayName: 'Previous - Metformin (Glucophage XR®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Glucophage XR',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Fortamet®)',
                                displayName: 'Previous - Metformin (Fortamet®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Fortamet',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Glumetza®)',
                                displayName: 'Previous - Metformin (Glumetza®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Glumetza',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Metformin (Riomet®)',
                                displayName: 'Previous - Metformin (Riomet®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Riomet',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Pioglitazone (Actos®)',
                                displayName: 'Previous - Pioglitazone (Actos®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Actos',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Rosiglitazone (Avandia®)',
                                displayName: 'Previous - Rosiglitazone (Avandia®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Avandia',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Alogliptin (Nesina®)',
                                displayName: 'Previous - Alogliptin (Nesina®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Nesina',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Sitagliptin (Januvia®)',
                                displayName: 'Previous - Sitagliptin (Januvia®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Januvia',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Saxagliptin (Onglyza®)',
                                displayName: 'Previous - Saxagliptin (Onglyza®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Onglyza',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Linagliptin (Tradjenta®)',
                                displayName: 'Previous - Linagliptin (Tradjenta®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Tradjenta',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Canagliflozin (Invokana®)',
                                displayName: 'Previous - Canagliflozin (Invokana®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Invokana',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Dapagliflozin (Farxiga®)',
                                displayName: 'Previous - Dapagliflozin (Farxiga®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Farxiga',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Empagliflozin (Jardiance®)',
                                displayName: 'Previous - Empagliflozin (Jardiance®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Jardiance',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },






                            {
                                label: 'Azathioprine (Imuran®)',
                                displayName: 'Previous - Azathioprine (Imuran®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Imuran',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Azathioprine (Generic)',
                                displayName: 'Previous - Azathioprine (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (Azathioprine)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Mycofenolaatmofetil (Cellcept®)',
                                displayName: 'Previous - Mycofenolaatmofetil (Cellcept®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Tablet_Cellcept',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Mycofenolaatmofetil (Generic)',
                                displayName: 'Previous - Mycofenolaatmofetil (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (Mycofenolaatmofetil)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Cyclosporin (Generic)',
                                displayName: 'Previous - Cyclosporin (Generic)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Tablet_Generic (Cyclosporin)',
                                parent: 'Therapies_Previous_Pharmaceutical_Tablet',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },


                        ],
                    },
                    {
                        label: 'Injection',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Injection',
                        parent: 'Therapies_Previous_Pharmaceutical',
                        data: [
                            {
                                label: 'Rozanolixizumab-noli (Rystiggo®)',
                                displayName: 'Previous - Rozanolixizumab-noli (Rystiggo®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Injection_Rystiggo',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Zilucoplan (Zilbrysq®)',
                                displayName: 'Previous - Zilucoplan (Zilbrysq®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Injection_Zilbrysq',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },



                            {
                                label: 'Exenatide (Byetta®)',
                                displayName: 'Previous - Exenatide (Byetta®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Byetta',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Liraglutide (Victoza®)',
                                displayName: 'Previous - Liraglutide (Victoza®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Victoza',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Albiglutide (Tanzeum®)',
                                displayName: 'Previous - Albiglutide (Tanzeum®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Tanzeum',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Dulaglutide (Trulicity®)',
                                displayName: 'Previous - Dulaglutide (Trulicity®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Trulicity',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glulisine (Apidra®)',
                                displayName: 'Previous - Insulin Glulisine (Apidra®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Apidra',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Humalog®)',
                                displayName: 'Previous - Insulin Lispro (Humalog®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Humalog',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Novorapid®)',
                                displayName: 'Previous - Insulin Aspart (Novorapid®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Novorapid',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Fiasp®)',
                                displayName: 'Previous - Insulin Aspart (Fiasp®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Fiasp',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Insulin Aspart Sanofi®)',
                                displayName: 'Previous - Insulin Aspart (Insulin Aspart Sanofi®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Insulin_Aspart_Sanofi',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart (Novomix®)',
                                displayName: 'Previous - Insulin Aspart (Novomix®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Novomix',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Lyumjev®)',
                                displayName: 'Previous - Insulin Lispro (Lyumjev®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Lyumjev',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Lispro (Insulin Lispro Sanofi®)',
                                displayName: 'Previous - Insulin Lispro (Insulin Lispro Sanofi®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Insulin_Lispro_Sanofi',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Human (Humilin®)',
                                displayName: 'Previous - Insulin Human (Humilin®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Humilin',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Human (Actraphane®)',
                                displayName: 'Previous - Insulin Human (Actraphane®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Actraphane',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulan Human (Insulatard®)',
                                displayName: 'Previous - Insulan Human (Insulatard®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Insulatard',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Aspart/Degludeg (Ryzodeg®)',
                                displayName: 'Previous - Insulin Aspart/Degludeg (Ryzodeg®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Ryzodeg',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Lantus®)',
                                displayName: 'Previous - Insulin Glargine (Lantus®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Lantus',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Toujeo®)',
                                displayName: 'Previous - Insulin Glargine (Toujeo®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Toujeo',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Glargine (Abasaglar®)',
                                displayName: 'Previous - Insulin Glargine (Abasaglar®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Abasaglar',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Detemir (Levemir®)',
                                displayName: 'Previous - Insulin Detemir (Levemir®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Levemir',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },
                            {
                                label: 'Insulin Degludec (Tresiba®)',
                                displayName: 'Previous - Insulin Degludec (Tresiba®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Tresiba',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Type 2 Diabetes",
                                data: [],
                            },






                            {
                                label: 'Corticotropin (Purified Cortrophin Gel®)',
                                displayName:
                                    'Previous - Corticotropin (Purified Cortrophin Gel®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Injection_Corticotropin',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Glatiramer Acetate (Copaxone®)',
                                displayName: 'Previous - Glatiramer Acetate (Copaxone®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Copaxone',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon ß-1a (Avonex®)',
                                displayName: 'Previous - Interferon β-1a (Avonex®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Avonex',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1a (Rebif®)',
                                displayName: 'Previous - Interferon β-1a (Rebif®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Rebif',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1b (Betaseron®)',
                                displayName: 'Previous - Interferon β-1b (Betaseron®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Betaseron',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Interferon β-1b (Extavia®)',
                                displayName: 'Previous - Interferon β-1b (Extavia®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Extavia',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ofatumumab (Kesimpta®)',
                                displayName: 'Previous - Ofatumumab (Kesimpta®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Kesimpta',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Peginterferon β‑1a (Plegridy®)',
                                displayName:
                                    'Previous - Peginterferon β‑1a (Plegridy®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Injection_Plegridy',
                                parent: 'Therapies_Previous_Pharmaceutical_Injection',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                        ],
                    },
                    {
                        label: 'Infusion',
                        multiSelect: false,
                        value: 'Therapies_Previous_Pharmaceutical_Infusion',
                        parent: 'Therapies_Previous_Pharmaceutical',
                        data: [

                            {
                                label: 'Efgartigimod Alpha (Vyvgart®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Vyvgart',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Eculuzimab (Soliris®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Soliris',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Ravulizumab (Ultomiris®)',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Infusion_Ultomiris',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },
                            {
                                label: 'Nipocalimab®',
                                multiSelect: false,
                                value:
                                    'Therapies_Previous_Pharmaceutical_Infusion_Nipocalimab',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Myasthenia Gravis",
                                data: [],
                            },



                            {
                                label: 'Alemtuzumab (Lemtrada®)',
                                displayName: 'Previous - Alemtuzumab (Lemtrada®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Lemtrada',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Mitoxantrone (Novantrone®) ',
                                displayName: 'Previous - Mitoxantrone (Novantrone®) ',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Mitoxantrone',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Natalizumab (Tysabri®)',
                                displayName: 'Previous - Natalizumab (Tysabri®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Tysabri',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ocrelizumab (Ocrevus®)',
                                displayName: 'Previous - Ocrelizumab (Ocrevus®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Ocrevus',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                            {
                                label: 'Ublituximab-xiiy (Briumvi®)',
                                displayName: 'Previous - Ublituximab-xiiy (Briumvi®)',
                                multiSelect: false,
                                value: 'Therapies_Previous_Pharmaceutical_Infusion_Briumvi',
                                parent: 'Therapies_Previous_Pharmaceutical_Infusion',
                                belongsTo: "Multiple Sclerosis",
                                data: [],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Surgical',
                multiSelect: false,
                value: 'Therapies_Previous_Surgical',
                parent: 'Therapies_Previous',
                data: [
                    {
                        displayName: 'Previous - Scalpel Incisions',
                        label: 'Scalpel Incisions',
                        multiSelect: false,
                        value: 'Therapies_Previous_Surgical_Scalpel',
                        parent: 'Therapies_Previous_Surgical',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Laparoscopic',
                        label: 'Laparoscopic',
                        multiSelect: false,
                        value: 'Therapies_Previous_Surgical_Laparoscopic',
                        parent: 'Therapies_Previous_Surgical',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Suture Closure',
                        label: 'Suture Closure',
                        multiSelect: false,
                        value: 'Therapies_Previous_Surgical_Suture',
                        parent: 'Therapies_Previous_Surgical',
                        data: [],
                    },
                ],
            },
            {
                label: 'Rehabilitation',
                multiSelect: false,
                value: 'Therapies_Previous_Rehabilitation',
                parent: 'Therapies_Previous',
                data: [
                    {
                        displayName: 'Previous - Physical Therapy',
                        label: 'Physical Therapy',
                        multiSelect: false,
                        value: 'Therapies_Previous_Rehabilitation_Physical',
                        parent: 'Therapies_Previous_Rehabilitation',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Occupational Therapy',
                        label: 'Occupational Therapy',
                        multiSelect: false,
                        value: 'Therapies_Previous_Rehabilitation_Occupational',
                        parent: 'Therapies_Previous_Rehabilitation',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Speech and Language',
                        label: 'Speech and Language',
                        multiSelect: false,
                        value: 'Therapies_Previous_Rehabilitation_Speech',
                        parent: 'Therapies_Previous_Rehabilitation',
                        data: [],
                    },
                ],
            },
            {
                label: 'Psychological',
                multiSelect: false,
                value: 'Therapies_Previous_Psychological',
                parent: 'Therapies_Previous',
                data: [
                    {
                        displayName: 'Previous - Cognitive Behavioral Therapy (CBT)',
                        label: 'Cognitive Behavioral Therapy (CBT)',
                        multiSelect: false,
                        value: 'Therapies_Previous_Psychological_ (CBT)',
                        parent: 'Therapies_Previous_Psychological',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Humanistic Therapy',
                        label: 'Humanistic Therapy',
                        multiSelect: false,
                        value: 'Therapies_Previous_Psychological_Humanistic',
                        parent: 'Therapies_Previous_Psychological',
                        data: [],
                    },
                    {
                        displayName: 'Previous - Psychodrama',
                        label: 'Psychodrama',
                        multiSelect: false,
                        value: 'Therapies_Previous_Psychological_Psychodrama',
                        parent: 'Therapies_Previous_Psychological',
                        data: [],
                    },
                ],
            },
        ],
    },
];