import React, { useEffect } from "react";
import "./style.scss";
import CustomButton from "../../components/custombutton/CustomButton";
import { useGetRequestQuery } from "../../store/api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const Acceptbidreq = () => {
  const user = useSelector((state) => state.auth?.user?.user?.id);

  const { data, isError, isSuccess, isLoading, error } =
    useGetRequestQuery(user);

  useEffect(() => {
    if (isSuccess) {
      toast.success("fetched requests");
    } else if (isError) {
      toast.error(error.data.message);
    }
  }, [isSuccess, isError]);

  return (
    <div className="requests">
      
      {/* <h1>Requests</h1>
     
      {Array.isArray(data) && data.length > 0 ? (
        data.map((item, index) => (
          <div className="reqcard" key={index}>
            <div className="textdata">{item?.text}</div>
            <div className="buttonsdata">
              <CustomButton
                style={{ paddingBottom: "15px" }}
                label={"Accept"}
                color={"green"}
                textcolor={"white"}
              />
              <CustomButton
                style={{ paddingBottom: "15px" }}
                label={"Decline"}
                color={"red"}
                textcolor={"white"}
              />
            </div>
          </div>
        ))
      ) : (
        <div>No data to display</div>
      )} */}
    </div>
  );
};

export default Acceptbidreq;
