import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './style.scss';
import { Images } from '../../assets/images';

export const Datapage = () => {
  const user = useSelector((state) => state?.auth?.user?.user);
 

  // Assuming user?.createdAt is a valid date string from the database
const createdAtStringFromDB = user?.createdAt;
const createdAtDate = new Date(createdAtStringFromDB);

// Extracting only the date part (YYYY-MM-DD)
const justDate = createdAtDate.toISOString().split('T')[0];




  return (
    <div className="container">
     
     
      <p style={{padding: '162px'}}>
        You have understood, and accepted the Terms & Conditions and the Privacy
        Statement on <b>{justDate}</b>
      </p>
    </div>
  );
};
