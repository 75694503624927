import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { diagnosisForSharer } from "./DiagnosisData";
import "./ProfileInfo.scss";

const AddDiagnosisModal = ({ show, handleClose, addDiagnosis, existingDiagnoses }) => {
    const [selectedCategory, setSelectedCategory] = useState('Current');
    const [newlySelectedDiagnoses, setNewlySelectedDiagnoses] = useState([]); // For newly added diagnoses

    const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);
    const [isCurrentOpen, setIsCurrentOpen] = useState(false);
    const [isPreviousOpen, setIsPreviousOpen] = useState(false);

    console.log('existingDiagnoses:', existingDiagnoses);

    const [step, setStep] = useState(1); // Step 1: Choose Diagnosis, Step 2: Choose Dates
    const [dates, setDates] = useState({});
    const [mmolValues, setMmolValues] = useState({});


    // NEW STATES:
    const [newMmolDates, setNewMmolDates] = useState([]);

    // Function to determine if the diagnosis is already selected
    const isDiagnosisSelected = (diagnosis) => {
        return existingDiagnoses.some(existing => existing.displayName === diagnosis.displayName);
    };



    // Reset step when modal is closed
    const handleModalClose = () => {
        setStep(1);
        setSelectedDiagnoses([]); // Reset selected diagnoses
        setDates({}); // Reset dates
        setMmolValues({}); // Reset mmol/mol values if any
        handleClose();
    };


    // Handle checkbox changes for multiple selection
    const handleDiagnosisChange = (diagnosis) => {
        if (selectedDiagnoses.includes(diagnosis)) {
            setSelectedDiagnoses((prev) => prev.filter((d) => d !== diagnosis));
            setNewlySelectedDiagnoses((prev) => prev.filter((d) => d !== diagnosis)); // Remove from newly selected diagnoses
        } else {
            setSelectedDiagnoses((prev) => [...prev, diagnosis]);

            // Only add to newlySelectedDiagnoses if not already in existingDiagnoses
            if (!existingDiagnoses.some(existing => existing.displayName === diagnosis.displayName)) {
                setNewlySelectedDiagnoses((prev) => [...prev, diagnosis]);
            }
        }
    };

    const handleNext = () => {
        const nonHealthySelected = selectedDiagnoses.some((diagnosis) => diagnosis.bidValue !== "Healthy");

        if (nonHealthySelected) {
            setStep(2);  // Only proceed to Step 2 if there are non-Healthy diagnoses
        } else {
            handleConfirm();  // Directly confirm if only Healthy is selected
        }
    };

    // Add new mmol date
    const addNewMmolDate = () => {
        setNewMmolDates((prev) => [
            ...prev,
            { startDate: "", pointsNumber: "" }, // Add new date with empty values
        ]);
    };

    // Update new mmol date
    const updateNewMmolDates = (index, field, value) => {
        setNewMmolDates((prev) => {
            const updatedDates = [...prev];
            updatedDates[index] = {
                ...updatedDates[index],
                [field]: value,
            };
            return updatedDates;
        });
    };



    const handleConfirm = () => {
        const newDiagnoses = selectedDiagnoses.map((diagnosis) => {
            if (diagnosis.bidValue === "Healthy") {
                return {
                    displayName: diagnosis.displayName,
                    startDate: null,
                    endDate: null,
                    value: diagnosis.value,
                    bidValue: diagnosis.bidValue,
                    analysis: [],
                };
            }

            const startDateValue = dates[diagnosis.value]?.startDate || null;
            const endDateValue = diagnosis.displayName.includes("Current")
                ? true // End date is true for 'Current'
                : dates[diagnosis.value]?.endDate || null; // Otherwise, use end date

            return {
                displayName: diagnosis.displayName,
                startDate: startDateValue,
                endDate: endDateValue,
                value: diagnosis.value,
                bidValue: diagnosis.bidValue,
                analysis: diagnosis.bidValue === "Type 2 Diabetes"
                    ? [{ dates: [...newMmolDates] }]
                    : [],
            };
        });

        addDiagnosis(newDiagnoses);
        handleModalClose();
    };





    return (
        <Modal show={show} onHide={handleModalClose} centered>
            <Modal.Header style={{ border: 'none' }} closeButton>
                <Modal.Title >{step === 1 ? "Diagnoses" : "Time Period"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 ? (
                    <>
                        <Form.Group style={{ marginLeft: '1rem', padding: '0.5rem' }}>
                            <Form.Label onClick={() => setIsCurrentOpen((prev) => !prev)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                {isCurrentOpen ? "▼" : "►"}  Current
                            </Form.Label>
                            {isCurrentOpen && (
                                <div className="diagnosis-dropdown">
                                    {diagnosisForSharer
                                        .find((category) => category.value === "Current_Diagnosis")
                                        ?.data.map((diagnosis) => (
                                            <Form.Check
                                                key={diagnosis.value}
                                                type="checkbox"
                                                label={diagnosis.label}
                                                checked={selectedDiagnoses.includes(diagnosis) || isDiagnosisSelected(diagnosis)}
                                                onChange={() => handleDiagnosisChange(diagnosis)}
                                                disabled={isDiagnosisSelected(diagnosis)}
                                            />
                                        ))}
                                </div>
                            )}
                        </Form.Group>
                        <hr style={{ margin: '10px 0' }} />

                        <Form.Group style={{ marginLeft: '1rem', padding: '0.5rem' }}>
                            <Form.Label onClick={() => setIsPreviousOpen((prev) => !prev)} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                {isPreviousOpen ? "▼" : "►"}  Previous
                            </Form.Label>
                            {isPreviousOpen && (
                                <div className="diagnosis-dropdown">
                                    {diagnosisForSharer
                                        .find((category) => category.value === "Previous_Diagnosis")
                                        ?.data.map((diagnosis) => (
                                            <Form.Check
                                                key={diagnosis.value}
                                                type="checkbox"
                                                label={diagnosis.label}
                                                checked={selectedDiagnoses.includes(diagnosis) || isDiagnosisSelected(diagnosis)}
                                                onChange={() => handleDiagnosisChange(diagnosis)}
                                                disabled={isDiagnosisSelected(diagnosis)}
                                            />
                                        ))}
                                </div>
                            )}
                        </Form.Group>


                    </>
                ) : (
                    <>
                        {selectedDiagnoses.map((diagnosis) => (
                            <div key={diagnosis.value} className="diagnosis-section" style={{ marginTop: '1rem' }}>
                                <h5>{diagnosis.displayName}</h5>
                                <Form.Group>
                                    <Form.Label>From (Start Date)</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dates[diagnosis.value]?.startDate || ''}
                                        onChange={(e) =>
                                            setDates((prev) => ({
                                                ...prev,
                                                [diagnosis.value]: { ...prev[diagnosis.value], startDate: e.target.value },
                                            }))
                                        }
                                    />
                                </Form.Group>
                                <Form.Group style={{ marginTop: '1rem' }}>
                                    <Form.Label>To (End Date)</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={dates[diagnosis.value]?.endDate || ''}
                                        onChange={(e) =>
                                            setDates((prev) => ({
                                                ...prev,
                                                [diagnosis.value]: { ...prev[diagnosis.value], endDate: e.target.value },
                                            }))
                                        }
                                        disabled={diagnosis.displayName.includes('Current')} // Disable End Date picker for "Current"
                                    />
                                </Form.Group>


                                {diagnosis.bidValue === 'Type 2 Diabetes' && (
                                    <div className="analysis-section">
                                        <span style={{ fontWeight: 'bold', display: 'flex' }}>HbA1c</span>
                                        {newMmolDates.map((newDate, index) => (
                                            <>
                                                <div key={`new-${index}`} className="date-range" >
                                                    <Form.Group>
                                                        <Form.Label>New Analysis Date {index + 1}</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={newDate.startDate}
                                                            onChange={(e) => updateNewMmolDates(index, "startDate", e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label style={{ color: '#5b99a6', marginTop: '0.3rem', fontWeight: 'bold' }}>mmol/mol</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            value={newDate.pointsNumber}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                if (value.length <= 3) {
                                                                    updateNewMmolDates(index, "pointsNumber", value);
                                                                }
                                                            }}
                                                            maxLength="3"
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </>
                                        ))}
                                        <span className="custom-button-text" onClick={addNewMmolDate} style={{ color: '#5b99a6', cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold', textAlign: 'center' }}>Add Another Date +</span>
                                    </div>
                                )}

                            </div>
                        ))}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer style={{ border: 'none', margin: '1.4rem' }}>
                {step === 1 ? (
                    <span
                        className="confirmBtn"
                        onClick={() => newlySelectedDiagnoses.length > 0 && handleNext()} // Only proceed if there are newly added diagnoses
                        style={{
                            color: newlySelectedDiagnoses.length === 0 ? '#fff' : '#fff',
                            backgroundColor: newlySelectedDiagnoses.length === 0 ? '#ccc' : '#5b99a6',
                            cursor: newlySelectedDiagnoses.length === 0 ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Next
                    </span>

                ) : (
                    <span
                        className="confirmBtn"
                        onClick={() => {
                            if (
                                newlySelectedDiagnoses.length > 0 &&
                                !selectedDiagnoses.some((diagnosis) => {
                                    const isPrevious = diagnosis.displayName.includes("Previous");
                                    const hasStartDate = !!dates[diagnosis.value]?.startDate;
                                    const hasEndDate = isPrevious ? !!dates[diagnosis.value]?.endDate : true;
                                    const isType2Diabetes = diagnosis.bidValue === "Type 2 Diabetes";
                                    const hasMmolValues = isType2Diabetes
                                        ? newMmolDates.every((newDate) => newDate.startDate && newDate.pointsNumber)
                                        : true;

                                    return !hasStartDate || !hasEndDate || !hasMmolValues;
                                })
                            ) {
                                handleConfirm(); // Only trigger the confirm logic if all conditions are met
                            }
                        }}
                        disabled={
                            newlySelectedDiagnoses.length === 0 || // Disable if no new diagnoses are added
                            selectedDiagnoses.some((diagnosis) => {
                                const isPrevious = diagnosis.displayName.includes("Previous");
                                const hasStartDate = !!dates[diagnosis.value]?.startDate;
                                const hasEndDate = isPrevious ? !!dates[diagnosis.value]?.endDate : true;

                                // If 'Type 2 Diabetes', check if mmol/mol values are set.
                                const isType2Diabetes = diagnosis.bidValue === "Type 2 Diabetes";
                                const hasMmolValues = isType2Diabetes
                                    ? newMmolDates.every(
                                        (newDate) => newDate.startDate && newDate.pointsNumber
                                    )
                                    : true;

                                // Disable if dates or mmol/mol values are missing
                                return !hasStartDate || !hasEndDate || !hasMmolValues;
                            })
                        }
                        style={{
                            color: newlySelectedDiagnoses.length === 0 ? '#fff' : '#fff',
                            backgroundColor: newlySelectedDiagnoses.length === 0 || selectedDiagnoses.some((diagnosis) => {
                                const isPrevious = diagnosis.displayName.includes("Previous");
                                const hasStartDate = !!dates[diagnosis.value]?.startDate;
                                const hasEndDate = isPrevious ? !!dates[diagnosis.value]?.endDate : true;
                                return !hasStartDate || !hasEndDate;
                            }) ? '#ccc' : '#5b99a6',
                            cursor: newlySelectedDiagnoses.length === 0 || selectedDiagnoses.some((diagnosis) => {
                                const isPrevious = diagnosis.displayName.includes("Previous");
                                const hasStartDate = !!dates[diagnosis.value]?.startDate;
                                const hasEndDate = isPrevious ? !!dates[diagnosis.value]?.endDate : true;
                                return !hasStartDate || !hasEndDate;
                            }) ? 'not-allowed' : 'pointer',
                        }}
                    >
                        Confirm
                    </span>

                )}
            </Modal.Footer>

        </Modal >


    );
};

export default AddDiagnosisModal;
