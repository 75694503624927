import React, { useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/custombutton/CustomButton";
import { Images } from "../../assets/images";
import VerificationModal from "./VerificationModal";
import OTPModal from "./OTPModal";
import { useSelector } from "react-redux";
import "./Authentication.scss";
import PINConfirmModal from "./PINConfirmModal";

export default function Authentication() {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [pinModalShow, setPinModalShow] = useState(false);

    const [verificationType, setVerificationType] = useState(null);
    const [otpType, setOtpType] = useState(null);
    const [pinType, setPinType] = useState(null);
    const user = useSelector((state) => state?.auth?.user?.user);
    const userId = useSelector((state) => state?.auth?.user?.user?.id);



    const handleClick = () => {
        navigate(-1); // Navigate to the previous page
    };

    const handleOpenModal = (type) => {
        setVerificationType(type);
        if(user?.pin && type=="PIN"){
            setPinType(null)
            setPinModalShow(true); 
        }
        else{
            
            setModalShow(true);
        }
       
    };

    const handleVerificationSuccess = (otpType) => {
        setOtpType(otpType); 
        setModalShow(false); 
        setOtpModalShow(true); 
    };
    const handlePinSuccess = (pin) => {
        console.log("PIIIIN",pin)
        setPinType(pin);  // Set the OTP type
        setModalShow(false);  // Close Verification Modal
        setPinModalShow(true);  // Open OTP Modal
    };

    return (
        <div className="authentication-container">
            <div className="authentication-logo-section">
                <div className="authentication-back-arrow" onClick={handleClick}>
                    <IoMdArrowRoundBack
                        size={20}
                        style={{ color: "#5B99A6", cursor: "pointer" }}
                    />
                </div>

                <img src={Images.logo_} alt="LiberDat" className="authentication-logo-image" />
            </div>


            <div className="authentication-box">
                <h3 className="authentication-title">Authenticate Your Account</h3>

                <CustomButton
                    label={"Verify Email"}
                    color={"#333333"}
                    width={"50%"}
                    textcolor={"white"}
                    backgroundColor={"#5b99a6"}
                    onClick={() => handleOpenModal("Email")}
                />

                <CustomButton
                    label={"Verify Phone"}
                    color={"#333333"}
                    width={"50%"}
                    textcolor={"white"}
                    backgroundColor={"#5b99a6"}
                    onClick={() => handleOpenModal("SMS")}
                />
 <CustomButton
                    label={"Verify via Pin"}
                    color={"#333333"}
                    width={"50%"}
                    textcolor={"white"}
                    backgroundColor={"#5b99a6"}
                    onClick={() => handleOpenModal("PIN")}
                />

                <VerificationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    verificationType={verificationType}
                    userId={userId}
                    setOtpModalOpen={setOtpModalShow}
                    setOtpType={handleVerificationSuccess}
                    handlePinSuccess={handlePinSuccess}

                />

                {/* OTP Modal */}
                <OTPModal
                    show={otpModalShow}
                    onHide={() => setOtpModalShow(false)}
                    userId={userId}
                    otpType={otpType}
                />
                <PINConfirmModal
                    show={pinModalShow}
                    onHide={() => setPinModalShow(false)}
                    userId={userId}
                    otpType={pinType}
                />
            </div>

            <p>
                <img src={Images.liberdaticon} alt="LiberDat" className="lowerimage" />
            </p>
        </div>
    );
}
