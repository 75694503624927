import React from "react";
import "./style.scss";

const About = () => {
  return (
    <div className="container22">
<header>
    <h2  style={{ textAlign: "center", marginBottom: "4vh" }} >Welcome to LIBERDAT</h2>
    <hr style={{marginBottom: '18px'}}/>
    <p>A GDPR-compliant platform to manage and share your data to improve your life and that of others. Our mission is to place individuals at the centre of the data economy for personalized, equitable, and sustainable solutions. We adhere to our core principles of user anonymity, autonomy, and security.</p>
  </header>
  <section>
    <p>Founded to revolutionize data perception and utilization, we believe in the transformative power of data to improve lives, drive innovation, and address societal challenges. Our platform acts as a bridge, connecting those who generate valuable data with those who can leverage it to push the boundaries of knowledge and solve the most pressing challenges of our time.</p>

    <p>At LIBERDAT, we prioritize ethical practices and transparency in every data exchange. Beyond being a platform, we are a movement advocating for responsible data use at individual and societal levels.</p>
  </section>

  <section>
    <p>Our commitment extends to fostering a vibrant community where researchers, scientists, businesses, and individuals collaborate, innovate, and share knowledge. LIBERDAT is more than a data exchange tool; we facilitate interdisciplinary partnerships by connecting dots that were previously scattered.</p>
  </section>

  <section>
    <p>Join us in unlocking the boundless potential of data, ushering in a new era of knowledge, discovery, and societal impact.</p>
  </section>
  <footer>

<br></br> 
  <b>The LIBERDAT Team</b>
</footer>



      
    </div>
  );
};

export default React.memo(About);
