import React from "react";
import { useNavigate } from "react-router-dom";
import "./bidliststyles.scss";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const BidsListItem = ({ users, selectedUserType }) => {
  const navigate = useNavigate();
  const userId = useSelector(state => state.auth?.user?.user?.id);

  const handleRowClick = (user) => {
    navigate('/user-prediction', { state: { user } });
  };

  // console.log("Users: ", users);
  console.log("Selected User Type: ", selectedUserType);

  const buyersExist = users?.some(user => user.role === 'buyer');
  const sellersExist = users?.some(user => user.role === 'seller');

  return (
    <div className="bids-list">
      <table>
        {buyersExist && selectedUserType === 'Requester' && (
          <>
            {/* Section for Buyers */}
            <thead>
              <tr>
                <th>Usernames</th>
                <th>Country</th>
                <th>Institution</th>
                <th>Department</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users?.filter(user => user.role === 'buyer').map((user) => (
                <tr key={user.id}>
                  <td>{user?.username || ""}</td>
                  <td>{user?.general?.country || "-"}</td>
                  <td>{user?.institution?.name || '-'}</td>
                  <td>{user?.institution?.department || '-'}</td>
                  <td>{user?.role === 'buyer' ? 'Requester' : '-'}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}

        {sellersExist && selectedUserType === 'Sharer' && (
          <>
            {/* Section for Sellers */}
            <thead>
              <tr>
                <th>Usernames</th>
                <th>Country</th>
                <th>Postal Code</th>
                <th style={{ textAlign: 'center' }}>Diagnosis</th>
                <th>Institution</th>
                <th>Department</th>
                <th>Birth Year</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users?.filter(user => user.role === 'seller').map((user) => (
                <tr key={user.id} style={{ cursor: 'pointer'}} onClick={user?.lastValidatedBy?.includes(userId)?() => handleRowClick(user):()=>{
                  toast('Only validating requester can open', {
                    icon: '⚠️',
                  });
                }}>
                  <td style={{
               filter:user?.lastValidatedBy?.includes(userId)?'none':'blur(3px)' }}>{user?.username || ""}</td>
                  <td>{user?.general?.country || "-"}</td>
                  <td>{user?.general?.postalCode || "-"}</td>
                  <td>
                    {user?.clinical?.diagnosis && user.clinical.diagnosis.length > 0
                      ? user.clinical.diagnosis.map((d) => d.bidValue).join(', ')
                      : "-"}
                  </td>
                  <td>{user?.clinical?.institution || '-'}</td>
                  <td>{user?.clinical?.department || '-'}</td>
                  <td>{user?.general?.birthYear || "-"}</td>
                  <td>{user?.role === 'seller' ? 'Sharer' : "-"}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default BidsListItem;
