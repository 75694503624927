import React, { useRef, useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../components/custombutton/CustomButton";
import { useVerifyByEmailAddressMutation, useVerifyByNumberMutation } from "../../store/api";
import toast from "react-hot-toast";
import "./Authentication.scss";
import { useSelector } from "react-redux";

const VerificationModal = ({ show, onHide, verificationType, userId, setOtpModalOpen, setOtpType,handlePinSuccess }) => {
    const [verifyByEmailAddress] = useVerifyByEmailAddressMutation();
    const [verifyByNumber] = useVerifyByNumberMutation();
    const user = useSelector((state) => state?.auth?.user?.user)
    const [pin, setPin] = useState(["", "", "", ""]);
    const inputRefs = useRef([]);
    // const [verifyOtp, { isSuccess, isError, isLoading }] = useVerifyOTPMutation();

    // Handle input change and auto-move to next
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {  // Only allow digits
            const newOtp = [...pin];
            newOtp[index] = value;  // Set value in the correct index
            setPin(newOtp);

            // Automatically focus on the next input
            if (index < 3) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Handle backspace to move focus to previous input
   // Handle backspace to remove digit and move focus to previous input
const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
        const newOtp = [...pin];
        
        if (pin[index] === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        } else {
            // Remove the current digit
            newOtp[index] = "";
            setPin(newOtp);
        }
    }
};



    // Formik for email verification
    const formikEmail = useFormik({
        initialValues: {
            email: user?.email||'',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required("Email is required")
                .email("Invalid email address"),
        }),
        onSubmit: async (values) => {
            try {
                const bodyToSend = {
                    id: userId,  
                    to: values.email,  
                };
                const result = await verifyByEmailAddress(bodyToSend);
                if (result?.data?.success === true) {
                    toast.success("Email verification code sent!");
                    setOtpType("Email");
                    onHide();
                    setOtpModalOpen(true);
                } else if (result?.data?.success === false) {
                    toast.error("Failed to send email verification!");
                }
            } catch (error) {
                toast.error("Email verification failed!");
            }
        },
    });

    // Formik for phone number verification
    const formikPhone = useFormik({
        initialValues: {
            phoneNumber: user?.phone||"",
            countryCode: "+31",  // Default country code
        },
        validationSchema: Yup.object().shape({
            phoneNumber: Yup.string()
                .required("Phone number is required")
                .when('countryCode', (countryCode, schema) => {
                    if (countryCode === '+92') {
                        return schema.matches(
                            /^[1-9][0-9]{9}$/,  // 10 digits for Pakistan without leading 0
                            "Please enter a valid 10-digit phone number without leading 0 for Pakistan"
                        );
                    } else if (countryCode === '+31') {
                        return schema.matches(
                            /^[1-9][0-9]{8}$/,  // 9 digits for Netherlands without leading 0
                            "Please enter a valid 9-digit phone number without leading 0 for Netherlands"
                        );
                    }
                    return schema;  // Default schema if no country code is matched
                }),
            countryCode: Yup.string().required("Please select a country code"),
        }),




        onSubmit: async (values) => {
            try {
                const bodyToSend = {
                    phoneNumber: user?.phone?user?.phone:`${values.countryCode}${values.phoneNumber}`,
                    userId,
                };
                const result = await verifyByNumber(bodyToSend);
                console.log("NumberResult::", result?.success === true);
                if (result?.data?.success === true) {
                    toast.success("Phone verification code sent!");
                    setOtpType("SMS");
                    onHide();
                    setOtpModalOpen(true);
                } else if (result?.data?.success === false) {
                    toast.error("Failed to send phone verification!");
                }
            } catch (error) {
                toast.error("Phone verification failed!");
            }
        },
    });


    const handleSubmit =  () => {
        const otpCode = pin.join("");  // Join OTP digits into a single string

        if (otpCode.length === 4) {
            try {
                console.log("OTP CODE",otpCode)
// toast.success(otpCode)
handlePinSuccess(otpCode)
// onHide();
              
            } catch (error) {
                toast.error("PIN verification failed!");
            }
        } else {
            toast.error("Please enter a valid 4-digit PIN.");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered className="verification-modal">
            <Modal.Header closeButton>
                <Modal.Title>{verificationType === "PIN"?"Enter a":"Verify"} {verificationType === "Email" ? "Email" :verificationType === "PIN"?"PIN": "Phone Number"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Email Verification Form */}
                {verificationType === "Email" && (
                    <Form onSubmit={formikEmail.handleSubmit} className="verification-form">
                        <Form.Group controlId="verificationEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={formikEmail.handleChange}
                                value={formikEmail.values.email}
                                disabled={user?.email}
                                isInvalid={formikEmail.touched.email && !!formikEmail.errors.email}
                                autoComplete="off"
                                className="verification-input"
                            />
                            <Form.Control.Feedback type="invalid" className="error-message">
                                {formikEmail.errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* CustomButton for Email Verification */}
                        <CustomButton
                            label={"Verify Email"}
                            color={"#fff"}
                            textcolor={"#ffffff"}
                            backgroundColor={"#5b99a6"}
                            type="submit"
                            width="100%"
                            isLoading={formikEmail.isSubmitting}
                            disabled={!formikEmail.isValid || formikEmail.isSubmitting}
                        />
                    </Form>
                )}

                {/* Phone Verification Form */}
                {verificationType === "SMS" && (
                    <Form onSubmit={formikPhone.handleSubmit} className="verification-form">
                        {!user?.phone &&(
                            <Form.Group controlId="countryCode" style={{ marginBottom: '15px' }}>
                            <Form.Label>Country Code</Form.Label>
                            <Form.Control
                                as="select"
                                name="countryCode"
                                onChange={formikPhone.handleChange}
                                value={formikPhone.values.countryCode}
                            >
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+31">+31 (Netherlands)</option>
                            </Form.Control>
                        </Form.Group>
                        )}
                        

                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="phoneNumber"
                                placeholder="Enter your phone number"
                                onChange={formikPhone.handleChange}
                                value={formikPhone.values.phoneNumber}
                                isInvalid={formikPhone.touched.phoneNumber && !!formikPhone.errors.phoneNumber}
                                disabled={user?.phone}
                                autoComplete="off"
                                className="verification-input"
                            />
                            <Form.Control.Feedback type="invalid" className="error-message">
                                {formikPhone.errors.phoneNumber}
                            </Form.Control.Feedback>
                        </Form.Group>


                        {/* CustomButton for Phone Verification */}
                        <CustomButton
                            label={"Verify Phone"}
                            color={"#fff"}
                            textcolor={"#ffffff"}
                            backgroundColor={"#5b99a6"}
                            type="submit"
                            width="100%"
                            isLoading={formikPhone.isSubmitting}
                            disabled={!formikPhone.isValid || formikPhone.isSubmitting}
                        />
                    </Form>
                     )}
                
                 {verificationType === "PIN" && (
                //    <Form className="otp-form" >
                    <div className="otp-form" >
                    <p>Set up a pin that will be asked everytime you login to your account</p>
                   <Row className="otp-inputs" style={{width:'75%',margin:'auto'}}>
                       {pin?.map((data, index) => (
                           <Col key={index}  className="otp-input-wrapper ">
                               <Form.Control
                                   type="text"
                                   maxLength="1"  // Only allow one digit per input
                                   value={data}
                                   onChange={(e) => handleChange(e, index)}
                                   onKeyDown={(e) => handleKeyDown(e, index)}
                                   ref={(el) => inputRefs.current[index] = el}  // Store the input reference
                                   className="otp-input"
                               />
                           </Col>
                       ))}
                   </Row>
                   <CustomButton
                       label="Add"
                       color={"#fff"}
                       textcolor={"#ffffff"}
                       backgroundColor={"#5B99A6"}
                       width="100%"
                    //    isLoading={isLoading}
                       onClick={handleSubmit}
                   />
                    {/* </Form> */}
                   </div>
              
                )}
            </Modal.Body>
        </Modal>
    );
};

export default VerificationModal;
