import React, { useEffect } from "react";
import RouteNames from "../../routes/RouteNames";
import { useFormik } from "formik";
import Customcard from "../../components/customcard/Customcard";
import { FaBell } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import {
  useSendNotificationMutation,
  useGetNotificationQuery,
  useDeleteNotificationMutation,
} from "../../store/api";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  useSubmitGeneralInfoFormsMutation,
  useGetSellerDataQuery,
  useGetSellerByIdQuery,
} from "../../store/api";
import { clearNotificationCount } from "../../store/reducers/notificationsSlice";

export const Notification = React.memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const values = location?.state?.value;
  const user = useSelector((state) => state.auth?.user?.user);
  const userRole = useSelector((state) => state.auth?.user?.user?.role);
  // const {
  //   data: sellerData,
  //   isLoading: sellerDataLoading,
  //   isError: sellerDataIsError,
  // } = useGetSellerByIdQuery(senderId);
  // console.log("SellerData", sellerData);
  const handleBidUser = (notification) => {
    const { path, sender, data } = notification; // Extract the path and sender from the notification object

    let route;
    let stateData;

    console.log("Bid User", data);

    switch (path) {
      case "ADDEDUSERS":
        route = RouteNames.AssignUser.route; // Replace with your actual route for ADDEDUSERS
        stateData = { bid: JSON.parse(data) }; // or other specific data you need
        break;

      default:
        route = RouteNames.ProfileInfo.route; // Default route if no matching case
        stateData = { senderId: sender }; // or whatever you were sending before
    }

    navigate(route, {
      state: stateData, // Send only the specific data you want
    });
  };


  // const Navigator = () => {
  //   navigate();

  // };

  const [SendId, { data, isLoading, isError, isSuccess, error }] =
    useSendNotificationMutation();
  // console.log(">>>>", location);
  // const [SendId, {
  //   data: sendNotificationData,
  //   isLoading: sendNotificationIsLoading,
  //   isError: sendNotificationIsError,
  //   isSuccess: sendNotificationIsSuccess,
  //   error: sendNotificationError
  // }] = useSendNotificationMutation();
  const {
    data: Notification,
    isLoading: getAllNotificatuionsLoading,
    isError: getAllNotificatuionsIsError,
    isSuccess: getAllNotificatuionsSuccess,
    error: getAllNotificatuionserror,
    refetch: refetchNotifications,
  } = useGetNotificationQuery();

  console.log("Notification", Notification);
  const [
    deleteNotification,
    {
      isLoading: deleteLoading,
      isError: deleteError,
      isSuccess: deleteSuccess,
      error: deleteMutationError,
    },
  ] = useDeleteNotificationMutation();

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Successfully Deleted!");
    } else if (deleteError) {
      toast.error("Error deleting notification.");
    }
  }, [deleteSuccess, deleteError]);

  const handleDelete = async (notificationId) => {
    try {
      await deleteNotification(notificationId).unwrap();
      refetchNotifications();
    } catch (error) {
      console.log("Error deleting notification", error);
    }
    // await deleteNotification(notificationId).unwrap();
    // // refetchNotifications();
  };

  const handleRead = (notificationId) => {
    SendId(notificationId);
    refetchNotifications();
  };

  useEffect(() => {
    if (getAllNotificatuionsSuccess) {
      console.log("data", Notification);
    } else if (getAllNotificatuionsIsError) {
      console.log("error", getAllNotificatuionserror);
    }
  }, [getAllNotificatuionsSuccess, getAllNotificatuionsIsError, deleteSuccess]);

  const formatTime = (createdAt) => {
    const dateObj = new Date(createdAt);
    const time = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const date = dateObj.toLocaleDateString();
    return `${time}, ${date}`;
  };
  return (
    <div className="notificationContainer">
      <h2>Notifications</h2>
      {getAllNotificatuionsLoading ? (
        <Skeleton
          width={"70vw"}
          height={"10vh"}
          count={Notification?.length}
          style={{ marginBottom: "20px" }}
        />
      ) : Notification?.length ? (
        Notification.map((notification) => (
          <div
            key={notification.id}
            className={`notification ${notification?.read ? "read" : ""}`}
            onClick={() => {
              // navigate(RouteNames.ProfileInfo.route, {
              //   state: { senderId: notification?.sender },
              // });
              dispatch(clearNotificationCount(0));

              handleBidUser(notification);
              handleRead(notification._id);
            }}
          >
            {/* <button onClick={goToProfileInfo}></button> */}
            <div className="notificationIcon">
              <FaBell />
            </div>
            <div className="notificationMessage">
              <div className="notificationNumber">{notification?.title}</div>
              {notification?.message}
              <div className="time">{formatTime(notification?.createdAt)}</div>
            </div>
            <div
              className="notificationDelete"
              onClick={() => handleDelete(notification._id)}
            >
              <MdDelete />
            </div>
          </div>
        ))
      ) : (
        <p>No Notifications</p>
      )}
    </div>
  );
});

export default React.memo(Notification);
