export const Events = {
  USER_SIGNUP: 'User Signup',
  USER_LOGIN: 'User Login',
  USER_LOGOUT: 'User Logout',
  USER_LOGIN_ERROR: 'User Login Error',
  USER_SIGNUP_ERROR: 'User Signup Error',
  APP_OPENED: 'User Opened App',
  APP_CLOSED: 'User Closed App',
  REQUEST_RECEIVED:"User Received Request",
  USER_ONBOARDING_DONE:"User Onboarding Done",
  USER_ONBOARDING_ERROR:"User Onboarding Error",
  USER_ONBOARDING_VALUES_ADDED:"User Onboarding Values Added",
  USER_ONBOARDING_ERROR:"User Onboarding Error",
  USER_UPDATED_DATA:"User Updated Data",
  USER_UPDATED_DATA_FOR_PREDICTION:"User Updated Data For Prediction",
  USER_UPDATED_DATA_FOR_REQUEST:"User Updated Data For Request",
  USER_UPDATED_DATA_ERROR:"User Updated Data Error",
  USER_ACCEPT_REQUEST:"User Accept Request",
  USER_ACCEPT_REQUEST_ERROR:"User Accept Request Error",
  USER_DONATE_REQUEST:"User Donate Request",
  USER_REJECT_REQUEST:"User Reject Request",
  NOTIFICATION_RECEIVED:"Notification Received",
  USER_REJECT_REQUEST_ERROR:"User Reject Request Error",
  USER_CREATE_REQUEST:"User Create Request",
  USER_CREATE_REQUEST_ERROR:"User Create Request Error",
  USER_VALIDATED_DATA:"User Validated Data",
  USER_VALIDATED_DATA_ERROR:"User Validated Data Error",
  USER_REQUEST_FOR_UPDATE:"User Requested for Data Update",
  USER_ADDED_TO_REQUEST:"User added to Request",
  USER_CLICKED_AUTOMATCH :"User clicked Automatch",
  USER_CLICKED_AUTOMATCH_ERROR :"User clicked Automatch Error",
  USER_MANUAL_ADDIGNED:"User Manual Assigned",
  USER_MANUAL_ADDIGNED_ERROR:"User Manual Assigned Error",
  // Add other events here as needed
};
