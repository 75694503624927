import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../store/reducers/authSlice';
import './style.scss'

const LogoutModal = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleConfirm = () => {
        dispatch(clearUser()); 
        onClose();
        navigate('/'); 
    };

    if (!visible) return null; // Don't render if modal is not visible

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Session Expired</h2>
                <p>Your session has expired. Please log in again to continue.</p>
                <button className="modal-button" onClick={handleConfirm}>Logout</button>
            </div>
        </div>
    );
};

export default LogoutModal;
