import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './style.scss';
import { useEffect } from 'react';

const CustomTextField = ({ name, label, placeholder, type, formik, value, disabled }) => {

  return (
    <Form.Group controlId={`formTextField${label}`}>
      <Form.Label style={{ fontWeight: 'bold' }} className="formlabel1">{label}</Form.Label>
      <Form.Control
        style={{ backgroundColor: '#EEEEEE' }}
        className='formcontrol1'
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        disabled={disabled}


      />
      {formik.touched[name] && formik.errors[name] && (
        <div className="error">{formik.errors[name]}</div>
      )}
    </Form.Group>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  formik: PropTypes.object.isRequired, // Pass formik object
};

CustomTextField.defaultProps = {
  placeholder: '',
  type: 'text',
};

export default React.memo(CustomTextField);
