import React, { useEffect, useState, useCallback } from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";

import CustomButton from "../../components/custombutton/CustomButton";
import { loadStripe } from "@stripe/stripe-js";
import AddBalanceModal from "./AddBalance";
import { Elements } from "@stripe/react-stripe-js";
import { useGetBalanceForWalletQuery, useGetPaymentSheetForWalletMutation } from "../../store/api";
import { useSelector } from "react-redux";
import { StripeWalletPayment } from "../../components/custommodel/StripeWalletPayment";

const WalletPage = () => {
  const [showModal, setShowModal] = useState(false);
  const userId = useSelector(state => state.auth?.user?.user?.id);
  const [loadingStripe,setLoadingStripe]=useState(false)
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const stripePromise = loadStripe('pk_test_51NZyGNHp2vpsqcNCm4hUf09orJabuIC4jR2ofD5QdOvpL4YigxUi3Qr2pZdc61eWB50yWJfjA5UxQwy61Ey4El4p00bVPreNtp');
  const [clientSecret, setClientSecret] = useState(null);
  const[getPaymentSheet,{isLoading,isError}]=useGetPaymentSheetForWalletMutation()
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentDoc, setPaymentDoc] = useState(null);
  const [captureDoc, setCaptureDoc] = useState(null);
  const{data:balanceData,isLoading:isLoadingBalance,isError:isErrorBalance,isSuccess:isSuccessBalance,refetch:refetchBalance}=useGetBalanceForWalletQuery(userId)

  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };
  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const proceedToPayment = useCallback(
    async (ammount) => {
     setLoadingStripe(true)
     const ps={
      userId,
      amount:ammount,
    }
    const paymentSh=await getPaymentSheet(ps)
    console.log("PAYMENT SHEEE",paymentSh)
      if(paymentSh?.data){
        setClientSecret(paymentSh?.data?.paymentIntent);
        setPaymentDoc(paymentSh?.data?.paymentIntentId);
        const capture = {
          paymentIntentId:paymentSh?.data?.paymentIntentId,
          customerId:paymentSh?.data?.customer
        };
        setCaptureDoc(capture);
        openPaymentModal();
    }
     },
     [userId,loadingStripe,clientSecret,paymentDoc,setCaptureDoc],
   );

  return (
   
    <>
  
     
      <main id="main">
        <section className="wallet_sec1">
          <Container>
            <h1>Wallet</h1>
            <Card className="balance-card">
              <Card.Body
                className="balance-card-body"
                style={{ height: "100px" }}
              >
                <div className="bal">
                  <h3> Balance</h3>
                  <p>{isSuccessBalance?balanceData?.balance?.balance/100:'0.00'} €</p>
                </div>
                <div>
                  <a href="#">
                    <i className="fas fa-chevron-right"></i>
                  </a>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </section>
        <div className="adddetails">
          <Container>
            <CustomButton
              label={"Add balance in your wallet"}
              color={"#333333"}
              textcolor={"white"}
              // backgroundColor={"#333333"}
              height={"50px"}
              width="100%"
              // margin={'auto'}
              onClick={handleShow}  // Open the modal on click
            />
          </Container>
        </div>

        
        {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
            <div className="adddetails">
             
            <StripeWalletPayment
            show={showPaymentModal}
            handleClose={closePaymentModal}
            paymentDoc={captureDoc}
            userId={userId}
         refetch={refetchBalance}
          />

            </div>
            </Elements>)}
            <AddBalanceModal show={showModal} handleClose={handleClose} proceedToPayment={proceedToPayment}/>
      </main>
      
    </>
   
  );
};

export default React.memo(WalletPage);
