
import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../components/custombutton/CustomButton";
import toast from "react-hot-toast";
import CustomTextField from "../../components/customtextfield/CustomTextField";

const AddBalanceModal = ({ show, handleClose,proceedToPayment }) => {
  // Form validation schema
  const validationSchema = Yup.object({
    price: Yup.number()
      .min(11, "Price must be greater than 10")
      .required("Price is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: { price: '' },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Handle the successful form submission (e.g., initiate the payment)
       
        proceedToPayment(values.price);
        handleClose(); // Close the modal after submission
      } catch (error) {
        toast.error('Error adding balance to wallet');
      }
    },
  });

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Balance to Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="price">
          <CustomTextField
                      label="Price"
                      type="number"
                      name="price"
                      formik={formik}
                    />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-between">
      <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <CustomButton
          label={"Add Balance"}
          color={"#333333"}
          textcolor={"white"}
          onClick={formik.handleSubmit}
          width="30%"
        />
        
      </Modal.Footer>
    </Modal>
  );
};

export default AddBalanceModal;
