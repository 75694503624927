import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
// import { useSelector } from "react-redux";
import './customizemodel.scss';
import { useAssignBidMutation, useSendPaymentStripeIdMutation } from "../../store/api";
export const StripePayment = React.memo(({show,handleClose,selectedBid,paymentDoc,refetch}) => {
  const [
    assignBid,
    {
      data: dataassign,
      isSuccess: dataisSuccess,
      isError: dataisError,
      error: dataerror,
      // isLoading,
    },
  ] = useAssignBidMutation();
  useEffect(() => {
    if (dataisSuccess) {
      refetch(selectedBid?.bidRef?.id);
    } else if (dataisError) {
      toast.error('error while assigning');
    }
  }, [dataisSuccess, dataisError]);
  const [
    sendStripeId,
    {
      data: datastripeid,
      isSuccess: isSuccessStripeId,
      isError: isErrorStripeId,
      error: errorStripeId,
      isLoading: loadingStripeId,
    },
  ] = useSendPaymentStripeIdMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const stripe = useStripe();
  const elements = useElements();



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    
    const  {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}?bidId=${selectedBid.id}`,
      },
      redirect: 'if_required',
    });
    if (error) {
      console.log("ERRRORORR",error);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }
    else{
      setTimeout(() => {
        handleClose();
      }, 500);
      const {data} = await sendStripeId(paymentDoc?.stripePaymentId);
  
      const {type} = data;
          toast.success('Payment Successful');
          const params = {
            bidData: {
              price: {
                amount: selectedBid.price.amount,
                currency: selectedBid.price.currency,
              },
              
              status: 'Accepted',
              paymentMethod: type,
            },
            bidId: selectedBid.id,
          };
          await assignBid(params);
      
    }

    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="md" style={{marginTop:'10vh'}}>
      <Modal.Header closeButton>
        <Modal.Title>Payment to {selectedBid?.sellerRef?.username}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
    {/* <div><h3>{selectedBid?.sellerRef?.username}</h3> */}
          <form id="payment-form" onSubmit={handleSubmit} style={{ height: 'auto' }}>
            <LinkAuthenticationElement id="link-authentication-element" />
            <PaymentElement id="payment-element" />
            <button disabled={isLoading || !stripe || !elements} id="submit" style={{ marginTop: '20px',width:'100%',borderRadius:'7px' }}>
              <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
              </span>
            </button>
            {message && <div id="payment-message" style={{color:'red',textAlign:'center'}}>{message}</div>}
          </form>
     
    {/* </div> */}
    </Modal.Body>
    </Modal>
  );
});
